<template>
  <div>{{label}}</div>
</template>

<script>
export default {
  name: 'question-label',
  props: ['label'],
};
</script>
