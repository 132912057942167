<template>
  <div>
    <p v-if="showNest" class="sublabel">{{ $t('step1TstatDisclaimer') }}</p>
    <question-select
      v-bind="$attrs"
      v-on="$listeners"
      id="tstatSelector-question"
      :options="options"
      :value="value"
      :name="name"
      :data-vv-name="name"
      v-validate="validation"
      @input="handleInput"
    />
    <small v-if="errorText" class="tstat-warning form-text text-danger">{{ $t(errorText) }}</small>
  </div>

</template>

<script>
import QuestionSelect from './QuestionSelect.vue';

export default {
  name: 'question-tstat-select',
  props: ['question', 'validation', 'value', 'name'],
  inject: ['program'],
  data() {
    return {
      selected: null,
      options: [
      ],
      showNest: false,
      errorText: null,
    };
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.options.find(({ value }) => value == null)) {
          this.options.shift();
        }
      }
      if (val === 'ecobee') {
        this.$root.$emit('modal.show', 'ecobee');
        this.errorText = null;
      } else if (val === 'nest') {
        this.$root.$emit('modal.show', 'nest');
        this.errorText = null;
      } else if (val === 'other') {
        this.errorText = 'step1TstatError';
      } else {
        this.errorText = null;
      }
    },
  },
  mounted() {
    this.options.push({ value: null, label: 'Select one' });
    const { thermostat } = this.program.devices;
    if (!this.question.excludeEcobee && thermostat.indexOf('ecobee') > -1) {
      this.options.push({ value: 'ecobee', label: 'step1TstatOptions.ecobee' });
    }

    if (!this.question.excludeNest && thermostat.indexOf('nest') > -1) {
      this.options.push({ value: 'nest', label: 'step1TstatOptions.nest' });
      this.showNest = true;
    }

    if (!this.question.excludeSensibo && thermostat.indexOf('sensibo') > -1) {
      this.options.push({ value: 'sensibo', label: 'step1TstatOptions.sensibo' });
    }

    if (!this.question.excludeHoneywell && thermostat.indexOf('honeywell') > -1) {
      this.options.push({ value: 'honeywell', label: 'step1TstatOptions.honeywell' });
    }

    if (!this.question.excludeHoneywellAcc && thermostat.indexOf('honeywellAcc') > -1) {
      this.options.push({ value: 'honeywellAcc', label: 'step1TstatOptions.amazon' });
    }

    if (!this.question.excludeSensi && thermostat.indexOf('sensi') > -1) {
      this.options.push({ value: 'sensi', label: 'step1TstatOptions.sensi' });
    }

    if (!this.question.excludeMysa && thermostat.indexOf('mysa') > -1) {
      this.options.push({ value: 'mysa', label: 'step1TstatOptions.mysa' });
    }

    this.options.push({ value: 'other', label: 'step1TstatOptions.other' });
    if (Object.keys(this.program.devices).filter(key => key !== 'thermostat').length > 0) {
      this.options.push({ value: 'no', label: 'step1TstatOptions.no' });
    }
  },
  methods: {
    handleInput(value) {
      this.selected = value;
      this.$emit('input', value);
    },
  },
  components: { QuestionSelect },
};
</script>

<style scoped>
.tstat-warning {
  float: left;
  margin-top: -18px;
  margin-bottom: 8px;
}
</style>
