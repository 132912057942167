<template>
  <div class="row middle-align-flex footer footer--fixed">
    <div>
      <p><span v-html="footerText"></span></p>
    </div>
  </div>
</template>

<script>

module.exports = {
  name: 'footer-bar',
  props: ['customerConfig', 'selectedLanguage'],
  data() {
    return {
      logo: '',
      providerColor: '',
      footerText: '',
    };
  },
  mounted() {
    const { programId } = this.$route.params;
    const selectedProgram = this.customerConfig.programs.find(program => program.programName === programId) || null;
    this.providerColor = this.customerConfig.color;
    this.footerText = selectedProgram && selectedProgram.byodFooter ? selectedProgram.byodFooter.text : this.customerConfig.byodFooter.text;
  },
  watch: {
    $route(to, from) {
      const { programId } = to.params;
      if (programId !== undefined) {
        const updatedProgram = this.customerConfig.programs.find(program => program.programName === programId) || null;
        this.footerText = updatedProgram && updatedProgram.byodFooter ? updatedProgram.byodFooter.text : this.customerConfig.byodFooter.text;
      } else {
        this.footerText = this.customerConfig.byodFooter.text;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/styles.scss';

.middle-align-flex {
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #fff;
  padding: 15px 5% 5px 5%;
  &--fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
  }
}

.footer a {
  font-size: 1em;
  color: #717273;
}
</style>
