const NS = 'vp';

function name(k) {
  return `${NS}.${k}`;
}

function nameToKey(n) {
  return n.slice(`${NS}.`.length);
}

export default {
  get(key) {
    if (!key) {
      throw new Error('key must be specified');
    }
    const str = localStorage.getItem(name(key));
    return JSON.parse(str);
  },

  set(key, value) {
    if (!key) {
      throw new Error('key must be specified');
    }
    const str = JSON.stringify(value);
    localStorage.setItem(name(key), str);
  },

  remove(key) {
    if (!key) {
      throw new Error('key must be specified');
    }
    localStorage.removeItem(name(key));
  },

  getAll() {
    return Object.keys(localStorage)
      .filter(n => n.indexOf(`${NS}.`) === 0)
      .reduce((out, n) => {
        const key = nameToKey(n);
        const str = localStorage.getItem(n);
        const add = {};
        add[key] = JSON.parse(str);
        return Object.assign({}, out, add);
      }, {});
  },

  clear() {
    Object.keys(localStorage)
      .filter(key => key.indexOf(`${NS}.`) === 0)
      .forEach(key => localStorage.removeItem(key));
  },
};
