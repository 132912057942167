import _ from 'lodash';
import Device from '../Device';

const questions = [{
  model: 'wallbox',
  type: 'oauth',
  name: 'wallbox',
  label: 'step3OauthDeviceWallbox',
  showNext: false,
  required: true,
  advanceOnEnter: true,
  advanceOnValue: true,
  included: true,
  update: values => ({
    additionalFields: {
      type: 'wallbox',
      tags: JSON.stringify(_.get(values, 'tags') || {}),
      deviceTags: JSON.stringify(_.get(values, 'deviceTags') || {}),
    },
  }),
}];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Wallbox = new Device('wallbox', { mfg: 'wallbox', questions, addDevice });

export default Wallbox;
