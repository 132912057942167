<template>
  <div class="container">
    <step
      ref="step1"
      :number="1"
      :title="step1.title"
      :introduction="step1.introduction"
      :questions="step1.questions"
      :active="activeStep === 1"
      :locked="completedStep >= 1"
      :error="stepErrors.step1"
      :nextStepText="this.$i18n.locale === 'es' ? 'Paso Siguiente' : 'Next Step'"
      v-model="userValues.step1"
      v-if="!addOnly"
      @next="step1Done"
    />
    <step
      ref="step2"
      :number="2"
      :title="step2.title"
      :introduction="step2.introduction"
      :questions="step2.questions"
      :active="activeStep === 2"
      :locked="completedStep >= 2"
      :error="stepErrors.step2"
      :nextStepText="localizedText"
      v-if="!addOnly && completedStep >= 1"
      v-model="userValues.step2"
      @next="step2Done"
    />
    <step
      ref="step3"
      :number="3"
      :title="step3.title"
      :introduction="step3.introduction"
      :questions="step3.questions"
      :active="activeStep === 3"
      :locked="completedStep >= 3"
      :showNextStep="hasAddDevice"
      :nextStepText="this.$i18n.locale === 'es' ? 'Agregar un dispositivo' : 'Add Device'"
      v-if="addOnly || completedStep >= 2"
      v-model="userValues.step3"
      @next="step3Done"
      @input="handleInput"
      @reset="handleReset"
    />
    <modal-ecobee
      ref="ecobee-modal"
      v-model="showEcobeeModal"
      :opts="modalOpts"
      :customerConfig="customerConfig"
    />
    <modal-nest
      ref="nest-modal"
      v-model="showNestModal"
      :opts="modalOpts"
      :customerConfig="customerConfig"
    />
    <modal-checklist
      ref="checklist-modal"
      v-model="showChecklistModal"
      :parentRefs="$refs"
      :customerConfig="customerConfig"
      :checklistViewed="checklistViewed"
    />
    <modal-enel-x
      ref="enelx-modal"
      v-model="showEnelXModal"
      :opts="modalOpts"
      :customerConfig="customerConfig"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import Step from '../Step';
import {
  ModalEcobee, ModalNest, ModalChecklist, ModalEnelX,
} from '../Modal';
import vp from '../../services/api/vp';
import Device from '../Device';
import getSteps from './get-steps';

const emptyStep = { questions: [] };

function alert(...args) {
  window.alert(...args); // eslint-disable-line no-alert
}

export default {
  name: 'signup',
  props: ['customerConfig', 'program', 'customer', 'initialValues', 'theme'],
  provide() {
    return {
      account: this.currentAccount,
      customer: this.customer,
      program: this.program,
      theme: this.theme,
      initialValues: this.initialValues,
    };
  },

  mounted() {
    if (this.program.deviceTypes.length === 1) {
      [this.userValues.step3.deviceType] = this.program.deviceTypes;
    }
    this.isMounted = true;
    if (this.$route.name === 'programSignupAddDevice' || this.$route.name === 'programShortAddDevice') {
      this.completeStep(2);
      this.activateStep(3);
    } else {
      const currentStep = Number(window.sessionStorage.getItem('currentStep')) || 1;
      if (currentStep > 1) {
        this.completeStep(currentStep - 1);
      }
      this.activateStep(currentStep);
    }
    this.$emit('change', this.userValues);

    this.modalOpts.rhrUrl = (
      this.program.programSettings.find(({ key }) => key === 'rhr')
      || this.customer.commonSettings.find(({ key }) => key === 'rhr')
      || { url: null }
    ).url;

    this.modalOpts.enelXUrl = (
      this.program.programSettings.find(({ key }) => key === 'enelX')
      || this.customer.commonSettings.find(({ key }) => key === 'enelX')
      || { url: null }
    ).url;

    this.$root.$on('modal.show', (item) => {
      this.showEcobeeModal = false;
      this.showNestModal = false;
      this.showChecklistModal = false;
      this.showEnelXModal = false;
      setTimeout(() => {
        const { fullPath } = this.$route;
        const label = `modal_${item}`;
        this.$gtag.pageview(`${fullPath}/step/${this.activeStep}/modal/${item}`);
      }, 100);
      if (item === 'ecobee') {
        this.showEcobeeModal = true;
      }
      if (item === 'nest') {
        this.showNestModal = true;
      }
      if (item === 'checklist' && !this.checklistViewed) {
        this.showChecklistModal = true;
        this.checklistViewed = true;
      }
      if (item === 'enelx') {
        this.showEnelXModal = true;
      }
    });
  },
  watch: {
    userValues: {
      handler(newUserValues) {
        this.$emit('change', newUserValues);
      },
      deep: true,
    },
    showChecklistModal() {
      if (!this.showChecklistModal) {
        const question = this.$refs.step1.$refs.questions.find(q => q.name === 'First Name');
        const input = question.$refs.question;
        input.focus();
      }
    },
  },
  data() {
    return {
      isMounted: false,
      currentAccount: {},
      config: null,
      activeStep: 0,
      completedStep: 0,
      steps: getSteps(this.customer, this.program),
      userValues: {
        step1: {},
        step2: {},
        step3: {},
      },
      stepErrors: {
        step1: null,
        step2: null,
        step3: null,
      },
      showEcobeeModal: false,
      showNestModal: false,
      showChecklistModal: false,
      showEnelXModal: false,
      modalOpts: {},
      checklistViewed: false,
    };
  },
  methods: {
    handleInput(...args) {
      this.$emit('input', ...args);
    },
    handleReset() {
      const cleanedArr = ['device', 'deviceType'].reduce((obj, key) => ({ ...obj, [key]: this.userValues.step3[key] }), {});
      this.userValues.step3 = cleanedArr;
    },
    activateStep(n) {
      this.activeStep = n;
    },
    completeStep(n) {
      if (this.completedStep < n) {
        this.completedStep = n;
      }
    },
    setStepError(stepIndex, errorMessage) {
      const key = `step${stepIndex}`;
      this.stepErrors[key] = errorMessage;
    },
    formatError(err) {
      const { status } = err.response;
      return `${this.$i18n.t('byodErrorStatus')} ${status}. ${this.$i18n.t('byodErrorContactSupport')}`;
    },
    completeAndAdvanceStep() {
      const stepToComplete = this.activeStep;
      const stepToAdvanceTo = stepToComplete + 1;
      this.completeStep(stepToComplete);
      this.activateStep(stepToAdvanceTo);
      window.sessionStorage.clear();
    },
    async step1NextClick() {
      this.setStepError(1, null);
      const {
        address,
        address2,
        city,
        state,
        zipcode,
      } = _.get(this.userValues.step1, 'address');
      const partialSignupData = {
        address,
        address2,
        city,
        state: state.toUpperCase(),
        zipcode,
        phone: _.get(this.userValues.step1, 'phone'),
        country: this.customer.localization || 'US',
        accountNumber: _.get(this.userValues.step1, 'accountNumber'),
        tags: _.get(this.userValues.step1, 'tags') || {},
      };

      if (this.program.programName.includes('-es')) {
        partialSignupData.tags['language preference'] = 'es';
      }
      if (this.program.programName.includes('-en')) {
        partialSignupData.tags['language preference'] = 'en';
      }

      try {
        await vp.updatePartialSignup(partialSignupData);
        this.completeAndAdvanceStep();
      } catch (err) {
        this.setStepError(1, this.formatError(err));
      }
    },
    async step2NextClick() {
      const address = _.get(this.userValues.step1, 'address');
      const accountData = {
        termId: this.program.termId,
        programId: this.program.programId || null,
        tags: _.get(this.userValues.step2, 'tags') || {},
      };

      if (this.evseTouRateSettingId && !Number.isNaN(this.evseTouRateSettingId)) {
        accountData.tags.evseTouRateSetting = this.evseTouRateSettingId;
      }
      try {
        const accountResponse = await vp.createHouse(accountData);

        if (this.hasDevices) {
          this.completeAndAdvanceStep();
        } else {
          this.$router.push('/success');
        }
      } catch (err) {
        this.setStepError(2, this.formatError(err));
        if (err.toString() === 'Error: Request failed with status code 500') {
          this.$router.push({ path: '/error', query: { e: 'utility-email' } });
        }
      }
    },
    async step3NextClick() {
      const values = this.userValues.step3;
      try {
        const key = values.device;
        const device = Device.get(key);
        const addDeviceResponse = await device.addDevice(
          values,
          this.context,
          this,
        );
        this.completeStep(3);

        this.$router.push('/success');
      } catch (err) {
        this.setStepError(3, this.formatError(err));
        this.$router.push('/error');
      }
    },
  },
  computed: {
    addOnly() {
      return this.$route.name === 'programSignupAddDevice';
    },
    hasDevices() {
      return (this.program.deviceTypes || []).length > 0;
    },
    hasAddDevice() {
      const values = this.userValues.step3;
      const { device = null } = values;
      if (device) {
        const d = Device.get(device);
        return d.hasAddDevice();
      }
      return false;
    },
    evseTouRateSettingId() {
      const evseTouRateSettingEnabled = (this.program.programSettings || []).find(({ key }) => key === 'enableEvseTouRateSetting');
      if (evseTouRateSettingEnabled && evseTouRateSettingEnabled.value && (this.program.deviceTypes || []).includes('evse')) {
        return parseInt(evseTouRateSettingEnabled.settingId, 10);
      }
      return null;
    },
    selectedConfig() {
      if (!this.config || !this.config.id) return 'none selected';
      return this.config.name || this.config.id;
    },
    context() {
      return {
        account: this.currentAccount,
        customer: this.customer,
        program: this.program,
      };
    },
    lastDeviceValues() {
      if (!this.userValues.step3.length) return null;
      return this.userValues.step3[this.userValues.step3.length - 1];
    },
    step1Start() {
      return 1;
    },
    step2Start() {
      return this.isMounted ? this.$refs.step1.lastQuestionNumber + 1 : 0;
    },
    step3Start() {
      return 0;
    },
    step1() {
      return this.steps && this.steps[0] ? this.steps[0] : emptyStep;
    },
    step2() {
      return this.steps && this.steps[1] ? this.steps[1] : emptyStep;
    },
    step3() {
      return this.steps && this.steps[2] ? this.steps[2] : emptyStep;
    },
    step1Questions() {
      return this.step1.questions || [];
    },
    step2Questions() {
      return this.step2.questions || [];
    },
    step3Questions() {
      return this.step3.questions || [];
    },
    step1Done() {
      return this.isTest ? this.testOnDone : this.step1NextClick;
    },
    step2Done() {
      return this.isTest ? this.testOnDone : this.step2NextClick;
    },
    step3Done() {
      return this.isTest ? this.testOnDone : this.step3NextClick;
    },
    localizedText() {
      if (this.$i18n.locale === 'es' && this.hasDevices) {
        return 'Paso Siguiente';
      }
      if (this.$i18n.locale === 'es' && !this.hasDevices) {
        return 'Enviar';
      }
      if (this.$i18n.locale === 'en' && this.hasDevices) {
        return 'Next Step';
      }
      return 'Submit';
    },
  },
  components: {
    Step,
    ModalEcobee,
    ModalNest,
    ModalChecklist,
    ModalEnelX,
  },
};
</script>

<style lang="scss">
.container {
  margin-bottom: 450px;
}
</style>
