<template>
  <div class="address-details__city">
    <label for="address-city" v-html="localized.placeholder" />
    <input
    id="address-city"
    class="form-control"
    type="text"
    :style="{ color: theme.primaryColor }"
    :name="name"
    :readonly="readonly"
    :value="displayValue"
    @focus="handleFocus"
    @blur="handleBlur"
    @keyup.enter="handleEnterPress"
    @input="handleInput"
    ref="input"
    v-validate="validation"
    :aria-label="localized.placeholder"
    :data-vv-name="name"
    :placeholder="localized.placeholder"
  />
  <small v-if="fieldError" class="form-text text-danger--custom">{{fieldError}}</small>
  </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-address-city',
  props: {
    active: Boolean,
    fieldError: String,
    name: String,
    region: Object,
    readonly: Boolean,
    value: String,
    validation: String,
  },
  data() {
    return { city: '' };
  },
  inject: {
    theme: { default: defaultTheme },
  },
  mounted() {
    if (this.active) {
      this.focus();
    }

    this.$root.$on('address.city', (value) => {
      this.$emit('input', value);
    });

    this.$root.$on('address.show', (show) => {
      this.show = show;
    });
  },
  watch: {
    /**
     * If this question becomes active, focus the input
     */
    active(newActive, oldActive) {
      if (newActive && !oldActive) this.focus();
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  computed: {
    displayValue() {
      return this.value;
    },
    localized() {
      if (this.region.localization === 'AU') return { placeholder: 'Suburb' };
      if (this.$i18n.locale === 'es') return { placeholder: 'Ciudad' };
      return { placeholder: 'City' };
    },
  },
};
</script>

<style scoped>
.address-details__city {
  float: left;
  width: 100%;
}

label {
 color:#4F4F4F;
 font-size:16px !important;
 margin-bottom:-10px !important;
 margin-left:10px;
 user-select: none;
}
</style>
