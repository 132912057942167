<template>
  <div>
    <div v-show="showCaptcha" class="g-recaptcha"></div>
    <div v-show="verifiedHuman" class="is-human">
      <i
        v-bind:style="{ color: theme.primaryColor }"
        class="fa fa-check"
      ></i>
      {{ $t('step1Verification') }}
    </div>
  </div>
</template>

<script>
import vp from '../../services/api/vp';
import defaultTheme from '../../styles/defaultTheme';
import storage from '../../services/storage';

const HEADER_HEIGHT = 0; // Height of header/menu fixed if exists
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isScrolledIntoView = (elem) => {
  const elemRect = elem.getBoundingClientRect();
  return (elemRect.top - HEADER_HEIGHT >= 0 && elemRect.bottom <= window.innerHeight);
};

export default {
  name: 'question-captcha',
  props: ['value', 'stepValues', 'done', 'region'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      grecaptchaPosition: null,
      showCaptcha: true,
      verifiedHuman: false,
      program: '',
    };
  },
  mounted() {
    this.program = this.$route.params.programId;
    this.showCaptcha = true;
    this.verifiedHuman = false;
    const captchaScript = document.getElementById('g-captcha-script');
    if (!captchaScript) {
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.id = 'g-captcha-script';
      script.async = true;
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=onReCaptchaLoad';

      head.appendChild(script);
    }

    // for iOS scrolling bug with grecaptcha.
    if (isIOS) {
      const recaptchaElements = document.querySelectorAll('.g-recaptcha');

      window.addEventListener('scroll', () => {
        Array.prototype.forEach.call(recaptchaElements, (element) => {
          if (isScrolledIntoView(element)) {
            this.grecaptchaPosition = document.documentElement.scrollTop || document.body.scrollTop;
          }
        });
      }, false);
    }

    window.onReCaptchaLoad = () => {
      const el = document.querySelector('.g-recaptcha');
      this.renderCaptcha(el);
    };
  },
  methods: {
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    captchaHasRendered() {
      const el = document.querySelector('.g-recaptcha iframe');
      return Boolean(el);
    },
    setCaptchaLang(recaptchaContainer, lang = 'en') {
      // Snippet from https://ourcodeworld.com/articles/read/1373/how-to-set-the-google-recaptcha-language-from-the-start-and-dynamically-with-javascript
      // 1. Search for the ReCaptcha iframe
      const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');

      // 2. Retrieve the current language
      const currentLang = iframeGoogleCaptcha.getAttribute('src').match(/hl=(.*?)&/).pop();

      // 3. Verify if the language that you want to set is different to the current one
      if (currentLang !== lang) {
        // 4. If it is, change it
        iframeGoogleCaptcha.setAttribute(
          'src',
          iframeGoogleCaptcha.getAttribute('src').replace(
            /hl=(.*?)&/,
            `hl=${lang}&`,
          ),
        );
      }
    },
    deleteCaptcha() {
      // const el = document.querySelector('.g-recaptcha');
      // let child = el.lastElementChild;
      // while (child) {
      //   el.removeChild(child);
      //   child = el.lastElementChild;
      // }
    },
    renderCaptcha(el) {
      if (this.captchaHasRendered()) {
        return;
      }

      this.captchaWidgetId = grecaptcha.render(el, {
        sitekey: window.GRECAPTCHA_SITE_KEY,
        theme: 'light',
        callback: async (response, err) => {
          this.$emit('focus');
          this.$emit('input', response);

          if (isIOS && this.grecaptchaPosition !== undefined) {
            window.scrollTo(0, this.grecaptchaPosition);
          }

          const {
            email,
            firstName,
            lastName,
            phone: rawPhone,
          } = this.stepValues;
          let phone = rawPhone ? rawPhone.replace(/[^0-9]/g, '') : '';
          let countryCode = '1';
          if (this.region.localization === 'AU') {
            phone = phone.slice(1);
            countryCode = '61';
          }

          const language = storage.get('selectedLanguage') || 'en';

          try {
            await vp.initializeProcess({
              email,
              firstName,
              lastName,
              phone,
              countryCode,
              program: this.program,
              capchaResponse: response,
              providerId: window.providerId,
              language,
            });

            this.deleteCaptcha();
            this.showCaptcha = false;
            this.verifiedHuman = true;

            this.$emit('done');
          } catch (ex) {
            this.$router.push({ path: '/error' });
          }
        },
      });
      this.setCaptchaLang(el, this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss" scoped>

.is-human {
  background-color: #f9f9f9;
  max-width: 304px;
  max-height: 78px;
  font-size: 16px;
  border-radius: 4px;

  i.fa {
    padding: 32px 16px;
  }
}

</style>
