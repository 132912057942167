import Device from '../Device';
import vp from '../../../services/api/vp';
import { hwh } from './Common';

const questions = hwh.concat([{
  type: 'text',
  label: 'step3AquantaPairingCode',
  active: true,
  model: 'pairingCode',
  name: 'pairingCode',
  validation: 'required',
}]);

const addDevice = async (values, context) => {
  const { account } = context;
  const deviceData = {
    ...values,
    mfg: 'aquanta',
    deviceType: 'hwh',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Aquanta = new Device('aquanta', { mfg: 'aquanta', questions, addDevice });

export default Aquanta;
