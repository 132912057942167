/* eslint no-unused-vars: 0 */
import _ from 'lodash';
import i18n from '../../i18n';
import labelUpdate from '../../lib/calculatedVariableReplacement';

import step1Questions from './questions-step1';
import step2Questions from './questions-step2';
import step3Questions from './questions-step3';

function updateQuestions(step, defaultList, customer, program) {
  const { programQuestions = [] } = program;
  // merge the common questions with the program questions;
  const { commonQuestions = [] } = customer;
  const toUpdateList = (programQuestions || []).map((q) => {
    const common = (commonQuestions || []).find(({ model }) => model === q.model);
    if (common) {
      return Object.assign({}, common, q);
    }
    return q;
  })
    .concat((commonQuestions || []).filter(({ model }) => programQuestions.find(q => q.model === model) == null));
    // merge the ones from above with the default questions
  const questions = defaultList.map((item) => {
    const toUpdate = toUpdateList.find(({ model }) => model === item.model);
    return Object.assign({}, item, toUpdate);
  })
  // override captcha question with commonQuestions settings, for use when skipping step 1
    .map((item) => {
      const captcha = (commonQuestions || []).find(({ model }) => model === 'captcha');
      if (item.type === 'captcha' && captcha) {
        const { included, required } = captcha;
        return { ...item, included, required };
      }
      return item;
    })
    .concat(toUpdateList
      .filter(question => question.step && question.step === step)
      .map((question) => {
        const { conditions = null, advanceOnEnter = true, model = null } = question;
        if (model.includes('tags.')) {
          // eslint-disable-next-line no-param-reassign
          question.ariaLive = 'polite';
        }
        let { included = false } = question;
        if (conditions) {
          included = (values, step2) => {
            const includeIt = conditions
              .reduce((prev, item) => {
                const shouldShow = item.values.includes(_.get(values, item.model));
                return prev && shouldShow;
              }, true);
            return includeIt;
          };
        }
        return Object.assign({}, question, { included, advanceOnEnter });
      }))
    // support select type questions with parent-child conditional options
    .map((question) => {
      const { conditionalOptions = null } = question;
      let { included = false, options = null } = question;
      if (conditionalOptions) {
        const { parentModel = '', optionsByParentValue = null } = conditionalOptions;
        options = (values) => {
          const parentValue = _.get(values, parentModel);
          if (!(parentValue in optionsByParentValue)) {
            console.log('There was an error in updating question options. A required input value is missing');
            return [];
          }
          const newOptions = optionsByParentValue[parentValue];
          return newOptions;
        };
        included = (values) => !!_.get(values, parentModel);
        return Object.assign({}, question, { options, included });
      }
      return question;
    })
    .map((question) => {
      let { label = '', included = false } = question;
      const { calculateErrorMessage = '', calculate: { expression, format = false } = {} } = question;
      if (expression && expression.length < 3) {
        included = false;
      } else if (expression && expression.length >= 3) {
        const labelString = label;
        label = (values) => {
          const rpnString = expression.map((el) => {
            if (typeof el !== 'object') {
              return String(el);
            }
            const [match = ''] = _.get(values, el.model, '').match(/(\d+(\.\d+)?)/g) || [];
            return match;
          }).join(' ');
          const calculatedVar = labelUpdate.calculateRpnExpression(rpnString);
          if ((!!calculatedVar || calculatedVar === 0) && (!Number.isNaN(calculatedVar) && Number.isFinite(calculatedVar))) {
            return labelUpdate.replaceTemplateVariable(labelString, 'calculate', format ? i18n.n(calculatedVar, format, i18n.locale) : calculatedVar);
          }
          console.log(`There was an error calculating variable for label. The calculator returned ${calculatedVar}.`);
          return calculateErrorMessage;
        };
      }
      return Object.assign({}, question, { label, included });
    })
    .map((question) => {
      const { key = null } = question;
      if (key) {
        return Object.assign({}, question, { model: key });
      }
      return question;
    })
    .map((question) => {
      const { validRegex, required = false, validation = '' } = question;
      if (validRegex) {
        return Object.assign({}, question, { validation: { required, regex: validRegex } });
      }
      if (required) {
        if (typeof validation === 'string') {
          const newValidation = validation.indexOf('required') === -1 ? `required|${validation}` : validation;
          return Object.assign({}, question, { validation: newValidation });
        }
        const newValidation = Object.assign({}, validation, { required });
        return Object.assign({}, question, { validation: newValidation });
      }
      return question;
    })
    .reduce((acc, q) => {
      // Checks if question should be added before Device connetion question.
      ((q.step === 3 && q.addBeforeDevice) ? acc.splice(2, 0, q) : acc.push(q)); /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */

      return acc;
    }, []);
  return questions;
}

export default function getSteps(customer, program) {
  const customerName = customer.name || customer.id;
  const { programQuestions = [] } = program;
  const step1Introduction = (program.programSettings.find(({ key }) => key === 'step1Introduction') || customer.commonSettings.find(({ key }) => key === 'step1Introduction') || { text: null }).text;
  const step1Title = (program.programSettings.find(({ key }) => key === 'step1Title') || customer.commonSettings.find(({ key }) => key === 'step1Title') || { text: null }).text;
  const step2Introduction = (program.programSettings.find(({ key }) => key === 'step2Introduction') || customer.commonSettings.find(({ key }) => key === 'step2Introduction') || { text: null }).text;
  const step2Title = (program.programSettings.find(({ key }) => key === 'step2Title') || customer.commonSettings.find(({ key }) => key === 'step2Title') || { text: null }).text;
  const step3Introduction = (program.programSettings.find(({ key }) => key === 'step3Introduction') || customer.commonSettings.find(({ key }) => key === 'step3Introduction') || { text: null }).text;
  const step3Title = (program.programSettings.find(({ key }) => key === 'step3Title') || customer.commonSettings.find(({ key }) => key === 'step3Title') || { text: null }).text;
  const steps = [
    // Step 1
    {
      title: step1Title != null ? step1Title : i18n.t('step1Headline'),
      introduction: step1Introduction != null ? step1Introduction : i18n.t('step1Introduction', { utilityName: customerName }),
      questions: updateQuestions(1, step1Questions(program), customer, program),
    },
    // Step 2
    {
      title: step2Title != null ? step2Title : i18n.t('step2Headline'),
      introduction: step2Introduction != null ? step2Introduction : i18n.t('step2Introduction'),
      questions: updateQuestions(2, step2Questions(), customer, program),
    },
    // Step 3
    {
      title: step3Title != null ? step3Title : i18n.t('step3Headline'),
      introduction: step3Introduction != null ? step3Introduction : i18n.t('step3Introduction'),
      questions: updateQuestions(3, step3Questions(customer, program), customer, program),
    },
  ];

  return steps;
}
