<template>
  <Modal v-model="passThroughValue" :title="this.$i18n.t('modalNest.headline')" :customerConfig="customerConfig" :showCloseButton="showCloseButton" class="nest-modal">
    <div tabindex="-1">
      <p>
        {{ $t('modalNest.introduction') }}
      </p>
      <div class="row nest-select">
        <div class="col">
          <div class="nest-button">
            <button
              v-bind:style="{ backgroundColor: theme.primaryColor }"
              ref="oppenRhrUrl"
              v-on:click="saveAndRedirect();"
            >
            {{ $t('modalNest.button') }}
            </button>
          </div>
          <p class="text-center">
            {{ $t('modalNest.supportEmail') }}
            <a
              v-bind:style="{ color: theme.primaryColor }"
              v-bind:href="'mailto:' + supportEmail"
            >
              {{ supportEmail }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'modal-nest',
  props: ['value', 'opts', 'customerConfig'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      title: 'Nest Thermostat User',
      passThroughValue: false,
      supportEmail: 'support@virtual-peaker.com',
      showCloseButton: true,
    };
  },
  mounted() {
    this.passThroughValue = this.value;
    this.supportEmail = this.customerConfig.supportEmail;
    this.providerColor = this.customerConfig.color;
    this.passThroughValue = this.value;
  },
  watch: {
    value() {
      this.passThroughValue = this.value;
    },
    passThroughValue() {
      this.$emit('input', this.passThroughValue);
    },
  },
  methods: {
    saveAndRedirect() {
      window.open(this.opts.rhrUrl, '_blank');
      this.$router.push({ path: '/success', params: { type: 'nest' } });
    },
  },
  components: { Modal },
};

</script>

<style lang="scss" scoped>

.nest-modal {
  p.footer{
    background: none;
    text-align: center;

    @media (max-width: 576px) {
      margin-left: 24px;
    }
  }

  .row.nest-select {
    width: auto;
    text-align: center;

    .nest-button {
      margin: 0 16px;
    }

    button{
      @media (max-width: 576px) {
        margin: 16px 0;
        width: 100%;
      }
    }

    @media (max-width: 576px) {
      .col {
        width: 100%;
        margin: 16px;
      }
    }
  }
}
</style>
