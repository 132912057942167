import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const modules = {};

export default new Vuex.Store({
  modules,
  strict: debug,
  state: {},
  actions: {},
  mutations: {},
  getters: {},
});
