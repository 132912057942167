<template>
  <div>
    <div :aria-live=ariaLive ref="stepHeader" class="row justify-content-center">
      <div class="col-lg-8">
        <div :style="{color: theme.primaryColor}" class="stepSubHeader" >
          {{ $t('byodStep') }} {{number}}
        </div>
        <h2>{{title}}</h2>
        <div :style="{marginBottom: '1rem'}" v-html="parsedIntroduction" />
      </div>
    </div>

    <div class="row justify-content-center">
      <slot />
    </div>

    <template v-if="showNextStep">
      <div
        v-show="!allQuestionsValid"
        class="row justify-content-center"
      >
        <div class="col-lg-7">
          <span class="form-text text-danger"><small>{{ $t('mustCompleteStep') }}</small></span>
        </div>
      </div>
      <div
        v-show="onLast && allQuestionsValid && active && !locked"
        class="row justify-content-center"
      >
        <div class="col-lg-7">
          <button
            :id="`button_next-step_step-${number}`"
            :style="{backgroundColor: theme.primaryColor}"
            v-on:click="$emit('next'), disable($event)"
            class="pull-right question-button"
          >
            {{ nextStepText || $t('nextStepText') }}
            <i class="fa fa-arrow-right"></i>
          </button>

        </div>
      </div>
    </template>
    <div class="row justify-content-center">
      <div class="next-question-block col-lg-8">
        <div class="question-number"></div>
        <div class="question">
          <span v-show="error" class="alert alert-danger">{{ error }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'step-wrapper',
  props: [
    'active',
    'number',
    'title',
    'introduction',
    'locked',
    'showNextStep',
    'nextStepText',
    'onNextClick',
    'allQuestionsValid',
    'error',
    'onLast',
    'retry',
  ],
  inject: {
    theme: {
      default: defaultTheme,
    },
  },
  computed: {
    parsedIntroduction() {
      return marked(this.introduction);
    },
    ariaLive() {
      return this.number > 1 ? 'polite' : null;
    },
  },
  methods: {
    disable(event) {
      if (this.retry) event.target.setAttribute('disabled', '');
    },
  },
};
</script>

<style lang="scss">
.stepSubHeader {
    text-align: left;
    border-bottom: solid 2px;
    width: 150px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.alert {
  display: inline-block;
}
</style>
