export const defaultCustomer = {
  id: '1',
  name: null,
  shortName: null,
  supportEmail: 'support@virtual-peaker.com',
  supportPhone: '(555) 555-1234',
  logo: 'https://home-dev.virtualpeaker.io/images/logo/Logo-DarkBlue-large.png',
  logoEmail: 'https://home-dev.virtualpeaker.io/images/logo/Logo-DarkBlue-email.png',
  logoLight: 'https://home-dev.virtualpeaker.io/images/logo/Logo-LightBlue-large.png',
  logoLightSmall: 'https://home-dev.virtualpeaker.io/images/logo/Logo-LightBlue-large-icon.png',
  color: '#F58220',
  commonUrls: {
    byodHelp: '/',
    byodMain: '/',
  },
  commonQuestions: [],
  homeAppUrls: {
    local: 'http://localhost:8081',
    production: 'https://home.virtualpeaker.io',
    development: 'https://home-dev.virtualpeaker.io',
  },
};

export const defaultProgram = {
  programName: 'byod',
  programLongName: '',
  deviceTypes: ['battery', 'thermostat', 'hwh', 'evse', 'rac', 'circuitBreaker'],
  devices: {
    battery: ['sonnen', 'sunverge'],
    thermostat: ['ecobee', 'honeywell'],
    hwh: ['ge', 'aquanta'],
    evse: ['chargepoint'],
    rac: ['ge'],
    circuitBreaker: ['eaton'],
  },
  termId: 1,
  programUrls: {
    byodRequirements: '/',
  },
  signupFormIntroText: '',
  programQuestions: [],
};

export const defaultAccount = {
  firstName: null,
  lastName: null,
  houseId: null,
  refreshToken: null,
};
