import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [
  {
    type: 'modal',
    name: 'modal',
    label: 'step3NestLabel',
    model: 'modalShow',
    modalName: 'nest',
    show: true,
    active: true,
    showNext: false,
    required: true,
  },
];

const Nest = new Device('nest', { mfg: 'nest', questions, addDevice: null });

export default Nest;
