<template>
  <main>
    <button
      :style="{backgroundColor: theme.primaryColor}"
      @click="showModal"
      class="question-button"
    >
      {{ $t('modalAoSmithPairing.startPairingButton') }}
    </button>
    <Modal v-model="passThroughValue" :title="title" :showCloseButton="showCloseButton" class="gravity-modal">
      <div tabindex="-1">
        <p class="intro">
          {{ $t('modalAoSmithPairing.modalIntroduction') }}
        </p>
        <div id="pairingSteps">
          <div id="pairingStepsTitle">{{ $t('modalAoSmithPairing.stepsTitle') }}</div>
          <div id="pairingStepsContainer">
            <ul id="pairingStepsChecklist">

              <li> {{ $t('modalAoSmithPairing.step1') }}</li>
              <li> {{ $t(`modalAoSmithPairing.${this.subBrand}.step2`) }}</li>
              <li> {{ $t(`modalAoSmithPairing.${this.subBrand}.step3`) }}</li>
              <li> {{ $t('modalAoSmithPairing.step4') }}</li>
              <li> {{ $t('modalAoSmithPairing.step5') }}</li>
              <li> {{ $t('modalAoSmithPairing.step6') }}</li>
              <li> {{ $t('modalAoSmithPairing.step7') }}</li>
              <li> {{ $t('modalAoSmithPairing.step8') }}</li>
              <li> {{ $t(`modalAoSmithPairing.${this.subBrand}.step9`) }}</li>
              <li> {{ $t('modalAoSmithPairing.step10') }}</li>
            </ul>
            <span id="pairingDetailedInstructions"><a target="_blank" href="https://support.virtual-peaker.com/knowledge/how-to-pair-your-ao-smith-device">{{ $t('modalAoSmithPairing.detailedInstructions') }}</a> </span>
          </div>
        </div>
        <hr />
        <section class="row completion-instructions">
          <div class="col-md col-6">
            <p
              id="pairing-code__code"
              class="pairing-code__code"
              ref="gravityPairingCode"
              v-bind:style="{ color: theme.primaryColor }"
            >
              {{ pairingCode }}
            </p>
          </div>
          <div class="col-md col-6">
            <button
              ref="copyToClipboard"
              v-bind:style="{
                color: theme.primaryColor,
                borderColor: theme.primaryColor
              }"
              class="pairing-code__button"
              id="copy-pairing-code"
              v-clipboard:copy="pairingCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onCopyError"
            >
              {{ pairingBtnTxt }}
            </button>
          </div>
          <div v-if="!isCreated" class="col-md col-12 gravity__bounce">
            <button
              ref="openConnectionsPage"
              v-bind:style="{ backgroundColor: theme.primaryColor }"
              class="question-button"
              id="gravity-connect_btn"
              v-on:click="redirect()"
            >
              {{ connectBtnTxt }}
            </button>
          </div>
          <div v-if="isCreated" class="col-md col-12 gravity__bounce">
            <button
              ref="openConnectionsPage"
              v-bind:style="{ backgroundColor: theme.primaryColor }"
              class="question-button"
              @click="handleSubmit"
            >
              {{ submitBtnTxt }}
            </button>
          </div>
        </section>
      </div>
    </Modal>
    <Modal
      v-model="isCancelled"
      :title="titleCancel"
      :showCloseButton="showCloseButton"
      class="gravity-modal cancel-modal"
      @input="resumeDevice"
    >
      <div tabindex="-1">
        <p>
          {{ $t('modalAoSmithPairing.pairingCancel') }}
        </p>
        <section class="row completion-instructions">
          <div class="col-5 col-md-4">
            <button
              v-bind:style="{
                color: theme.primaryColor,
                borderColor: theme.primaryColor,
              }"
              class="pairing-code__button"
              @click="resumeDevice"
            >
              {{ $t('modalAoSmithPairing.cancel') }}
            </button>
          </div>
          <div class="col-5 col-md-4">
            <button
              class="question-button gravity-cancel"
              @click="cancelDevice"
            >
              {{ cancelBtnText }}
            </button>
          </div>
        </section>
      </div>
    </Modal>
  </main>
</template>

<style lang="scss">
@import '../../styles/mixins.scss';
div[type='pairing-code'] {
  // hiding default backsplash
  & > div > span,
  & > div > label {
    @include Mobile-Only {
      font-size: 16px;
      line-height: 24px;
    }
    font-size: 24px;
    line-height: 36px;
  }
}
main[data-vv-name="pairingCode"] {
  margin-top: 2rem;
  & > .question-button {
    min-width: 200px;
    min-height: 40px;
  }
}

.gravity-modal {
  #modal-title,
  .modal-body p {
    text-align: left;

    @include Mobile-Only {
      text-align: center;
    }
  }

  #modal-title-mobile {
    text-align: center;
  }

  .modal-body > p:first-of-type {
    margin-bottom: 1rem;
    @include Mobile-Only {
      margin-top: 0.5rem;
    }
  }

  h5#modal-title {
    color: #016699;
    padding: 0 0 0 2rem;
  }

  h5#modal-title-mobile {
    padding-top: 3rem;
  }

  div.modal-dialog {
    @include Not-Mobile {
      width: 100vw;
    }
  }
  .modal-body {
    height: 100%;
    @include Mobile-Only {
      padding-top: 0;
    }
    @include Not-Mobile {
      margin: 1rem 2rem;
    }

    & > p:first-of-type {
      @include Not-Mobile {
        margin-top: 0;
      }
    }

    button {
      font-size: 13px;
      &:active {
        box-shadow: none; // Remove general btn onClick border
        line-height: inherit;
      }
    }
  }
  .modal-header {
    align-items: center;
    @include Mobile-Only {
      padding-top: 2rem;
      padding-right: 1rem;
    }
  }

  p.gravity-modal-footer,
  p.gravity-modal-footer a {
    padding-bottom: 1rem;
    font-size: 14px;
    line-height: 20px;
    @include Mobile-Only {
      font-size: 11px;
      line-height: 16px;
    }
  }

  hr {
    margin: 0 auto;
    border: 1px solid $GREY;

    @include Mobile-Only {
      width: 80%;
    }
    @include Tablet {
      margin: 0.5rem auto;
    }
     &:first-of-type {
        margin-bottom: 2rem;
        @include Mobile-Only--no-height {
          margin-bottom: 1rem;
        }
        @include Tablet {
          margin-bottom: 1rem;
        }
      }
      &:last-of-type {
        margin-top: 2rem;
        @include Mobile-Only--no-height {
          margin-top: 1rem;
        }
        @include Tablet {
          margin-top: 1rem;
        }
      }
  }

  p {
    margin: 1rem auto;
    line-height: 24px;
    @include Mobile-Only {
      max-width: 80%;
      line-height: 20px;
      &,
      a {
        font-size: 14px;
      }
    }

    @include Not-Mobile {
      margin: 0.5rem auto;
    }

    @include Mobile-Only--small {
      margin: 0.25rem auto;
    }
  }
  .gravity-cancel {
    background-color: #ce3c31;
  }
  #pairing-code__code {
    font-size: 32px;
    line-height: 40px;
    background: $WHITE;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 6px;
    height: 100%;
    margin: 0;
    padding-top: $GAP;
    text-align: center;
    max-width: 100%;
    @include Mobile-Only {
      padding-top: 0;
      font-size: 24px;
    }
  }

  .pairing-code__button {
    background: $WHITE;
    border: 2px solid #016699;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .pairing-code__button,
  .question-button {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    @include Mobile-Only {
      padding: 10px;
    }
  }

  .completion-instructions {
    text-align: center;
    margin: auto;

    @include Mobile-Only {
      width: 80%;
    }

    @include Desktop--tall {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    div {
      @include Mobile-Only--small {
        padding: 2px 0;
      }
      @include Mobile-Only {
        padding-right: 0;
      }
      &:first-child {
          padding-left: 0;
          padding-right: .75rem;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: .75rem;
          @include Tablet {
            padding-right: .75rem;
          }
        }

       &:nth-child(3) {
          padding-top: 1.5rem;
          padding-right: 0;
          @include Tablet {
            padding-top: 0;
            padding-left: .75rem;
          }
        }

    }

    .col button {
      @include Mobile-Only {
        margin: 8px 0;
        width: 100%;
      }
    }
  }
}
.cancel-modal {
  .modal-body {
    @include Mobile-Only {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .completion-instructions {
    padding-top: 1rem;
    margin: 1rem auto;
    justify-content: space-around;
    @include Not-Mobile {
      justify-content: center;
    }

    & > div {
      @include Mobile-Only {
        padding: 0.75rem 0;
      }
    }

    button {
      height: 40px;
      padding: 0;
    }
  }
}

#pairingSteps {
 background-color:#fff;
 border-radius:10px;
 box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
 font-size:16px;
 line-height:24px;
 padding:8px;
}

#pairingStepsTitle {
 background-color:#016699;
 border-radius:6px;
 color:#fff;
 font-size:14px;
 font-weight:700;
 height:30px;
 padding-top:2px;
 text-align:center;
 text-transform:uppercase;
 width:100%;
}

#pairingStepsChecklist {
 list-style-type:inherit;
 margin-left:28px;
 margin-top:18px;
}

#pairingStepsChecklist li {
 color:#4f4f4f;
 display:list-item;
 font-size:16px;
}

#pairingDetailedInstructions {
 font-size:16px;
 margin-left:14px;
}

#pairingDetailedInstructions a {
 color:#016699;
 text-decoration:underline;
}
</style>

<script>
import Modal from '../Modal/Modal.vue';
import defaultTheme from '../../styles/defaultTheme';
import vp from '../../services/api/vp';

export default {
  name: 'question-ao-smith-pairing-code',
  props: {
    question: Object,
    stepValues: Object,
    parentRefs: Object,
  },
  inject: {
    account: 'account',
    customer: 'customer',
    program: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    return {
      codeFetched: false,
      isMobile: false,
      isCreated: false,
      isCancelled: false,
      passThroughValue: false,
      cancelBtnText: '',
      connectionsUrl: '',
      connectBtnTxt: 'Open Connections Page',
      pairingCode: '',
      pairingCodeStatus: 'initial',
      partnerToken: '00000',
      submitButtonClicked: false,
      supportEmail: 'support@virtual-peaker.com',
      title: '',
      titleCancel: '',
      showCloseButton: true,
      deviceData: {},
    };
  },
  watch: {
    isReady: {
      immediate: true,
      handler(current, prev) {
        if (!prev && current) {
          this.fetchPairingCode();
        }
      },
    },
    passThroughValue() {
      if (!this.passThroughValue && this.isCreated) {
        this.isCancelled = true;
      }
    },
    isCancelled: {
      handler(current, prev) {
        if (current) this.toggleText();

        if (prev && Object.keys(this.deviceData).length && !current && !this.passThroughValue) {
          this.resumeDevice();
        }
      },
    },
  },
  computed: {
    houseId() {
      return this.account ? this.account.houseId : null;
    },
    pairingBtnTxt() {
      if (this.pairingCodeStatus === 'initial') {
        if (this.isMobile) {
          return this.$i18n.t('modalAoSmithPairing.pairingCodeButtonMobile');
        }
        return this.$i18n.t('modalAoSmithPairing.pairingCodeButtonText');
      }
      if (this.pairingCodeStatus === 'copied') {
        if (this.isMobile) {
          return this.$i18n.t('modalAoSmithPairing.pairingCodeConfirmedMobile');
        }
        return this.$i18n.t('modalAoSmithPairing.pairingCodeConfirmedText');
      }
      if (this.pairingCodeStatus === 'error') {
        return this.$i18n.t('modalAoSmithPairing.pairingCodeFailureText');
      }
      return '';
    },
    programName() {
      return this.program ? this.program.programName : null;
    },
    providerId() {
      return this.customer ? this.customer.id : null;
    },
    mfg() {
      return this.question.mfg;
    },
    subBrand() {
      return this.question.subBrand;
    },
    missingFields() {
      const requiredFields = ['mfg', 'programName', 'providerId'];
      const missing = requiredFields.filter(fld => !this[fld]);
      return missing;
    },
    isReady() {
      return !this.missingFields.length;
    },
    submitBtnTxt() {
      if (!this.submitButtonClicked) {
        return this.$i18n.t('modalAoSmithPairing.connectButtonText');
      } return this.$i18n.t('modalAoSmithPairing.submitButton');
    },
  },
  mounted() {
    this.toggleAddDeviceBtnDisplay('none');
    this.isMobile = window.matchMedia('(max-width: 576px)').matches;
    this.supportEmail = this.customer.supportEmail;
    this.toggleText();
  },
  updated() {
    [...document.querySelectorAll('button.close')].map(btn => btn.addEventListener('click', this.closeModal));
  },
  destroyed() {
    this.toggleAddDeviceBtnDisplay('');
    [...document.querySelectorAll('button.close')].map(btn => btn.removeEventListener('click', this.closeModal));
  },
  components: {
    Modal,
  },
  methods: {
    toggleAddDeviceBtnDisplay(set) {
      const submitBtns = [...document.getElementsByClassName('pull-right question-button')];
      const [addDeviceBtn] = submitBtns.filter(el => el.innerText.includes('Add Device'));
      if (addDeviceBtn !== undefined) {
        addDeviceBtn.style.display = set;
      }
    },
    async fetchPairingCode() {
      if (!this.isReady) return;
      const data = await vp.getPairingCode({
        mfg: this.mfg,
      });
      this.pairingCode = data.value;
      this.codeFetched = true;
      this.partnerToken = data.partnerToken;
    },
    async createDevice() {
      this.deviceData = {
        ...this.stepValues,
        pairingCode: this.pairingCode,
        mfg: this.mfg,
        houseId: this.houseId,
        program: this.programName,
        providerId: this.providerId,
        deviceSettings: [],
      };

      if (this.subBrand) {
        this.deviceData.deviceSettings.push({ key: 'sub-brand', value: this.subBrand });
      }

      try {
        const newDevice = await vp.addDevice(this.deviceData);
        this.deviceId = newDevice.id;
        this.deviceData.deviceId = this.deviceId;
        this.$emit('input', this.pairingCode);
        this.isCreated = true;
        this.toggleText();
      } catch (err) {
        if (this.$router) this.$router.push('/error');
      }
    },
    async redirect() {
      if (this.connectionsUrl) {
        window.open(this.connectionsUrl, '_blank');
      }
      return null;
    },
    async cancelDevice() {
      try {
        /* remove device from database */
        const data = vp.removeDevice(this.deviceData);
        /* remove device from component */
        this.deviceData = {};
        /* revert back to select device type */
        this.isCreated = false;
        this.connectionsUrl = '';
        this.isCancelled = false;
      } catch (error) {
        if (this.$router) this.$router.push('/error');
      }
      return null;
    },
    resumeDevice() {
      this.isCancelled = false;
      this.showModal();
    },
    onCopy() {
      this.pairingCodeStatus = 'copied';
    },
    onCopyError() {
      this.pairingCodeStatus = 'error';
    },
    toggleText() {
      if (this.isMobile) {
        this.cancelBtnText = this.$i18n.t('modalAoSmithPairing.cancelButtonTextMobile');
        this.connectBtnTxt = this.$i18n.t('modalAoSmithPairing.connectButtonTextMobile');
        this.title = this.$i18n.t(`modalAoSmithPairing.${this.subBrand}.modalHeading`);
        this.titleCancel = this.$i18n.t('modalAoSmithPairing.titleCancelTextMobile');
      } else {
        this.cancelBtnText = this.$i18n.t('modalAoSmithPairing.cancelButtonText');
        this.connectBtnTxt = this.$i18n.t('modalAoSmithPairing.connectButtonText');
        this.title = this.$i18n.t(`modalAoSmithPairing.${this.subBrand}.modalHeading`);
        this.titleCancel = this.$i18n.t('modalAoSmithPairing.titleCancelText');
      }
    },
    showModal() {
      this.toggleText();
      this.passThroughValue = true;
      this.createDevice();
    },
    closeModal() {
      this.passThroughValue = false;
      this.toggleText();
    },
    handleSubmit() {
      // first click opens the AO Smith Login Portal
      if (!this.submitButtonClicked) {
        window.open(window.AOSMITH_PORTAL_URL, '_blank');
        this.submitButtonClicked = true;
      } else {
        this.$router.push({ path: '/success', params: { type: 'aosmith' } });
      }
    },
  },
};
</script>
