import _ from 'lodash';
import Device from '../Device';

const questions = [{
  model: 'mysa',
  type: 'oauth',
  name: 'mysa',
  label: 'step3OauthDeviceMysa',
  active: true,
  showNext: false,
  included: true,
  update: values => ({
    additionalFields: {
      type: 'mysa',
      tags: JSON.stringify(_.get(values, 'tags') || {}),
    },
  }),
}];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Mysa = new Device('mysa', { mfg: 'mysa', questions, addDevice });

export default Mysa;
