import _ from 'lodash';
import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [
  {
    type: 'device-terms',
    label: 'enphaseTerms',
    model: 'acceptEnphaseConditions',
    validation: 'required:true',
    included: true,
    required: true,
    show: true,
  },
  {
    model: 'enphase',
    name: 'enphase',
    type: 'oauth',
    label: 'step3OauthDeviceEnphase',
    showNext: false,
    included: true,
    required: true,
    update: values => ({
      additionalFields: {
        type: 'enphase',
        tags: JSON.stringify(_.get(values, 'tags') || {}),
      },
    }),
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Enphase = new Device('enphase', { mfg: 'enphase', questions, addDevice });

export default Enphase;
