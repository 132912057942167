<template>
  <div class="question-modal">
    <button
        v-bind:style="{backgroundColor: theme.primaryColor}"
        v-on:click="showModal"
        class="question-button"
    >
    {{ $t('showModal') }}
    </button>
  </div>
</template>

<script>

import defaultTheme from '../../styles/defaultTheme';

const ACTIVATE_DEBOUNCE = 1 * 1000;

export default {
  name: 'question-modal',
  props: ['question', 'active'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      lastActive: 0,
    };
  },
  methods: {
    showModal() {
      this.$root.$emit('modal.show', this.question.modalName);
    },
    activate() {
      this.$root.$emit('modal.show', this.question.modalName);
    },
  },
  watch: {
    active(value) {
      if (value && (Date.now() - this.lastActive) > ACTIVATE_DEBOUNCE) {
        this.activate();
      }
      this.lastActive = value ? Date.now() : this.lastActive;
    },
  },
  mounted() {
    this.$emit('input', null);
    this.activate();
  },
};
</script>

<style scoped>
 .question-button {
   margin-top: 35px;
 }
</style>
