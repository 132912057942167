<template>
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-12 col-md-8 col-lg-8">
          <h4>Let's add your Tesla Virtual Key</h4>
          <p class="text-center">In order to participate in the demand response program you need to add a key to your Tesla app. Please make sure that you have set up <a href="https://www.tesla.com/ownersmanual/models/en_us/GUID-E004FAB7-1C71-448F-9492-CACF301304D2.html" target="_blank">“Phone as a key”</a> prior to adding.</p>
          <div class="row justify-content-center">
            <a
              class="col-lg-4"
              :href="virtualKeyUrl || 'https://www.tesla.com/_ak/smartcar.com'"
              target="_blank"
              @click="addKeyClicked = true"
            >
              <button
                :style="{backgroundColor: theme.primaryColor}"
                class="question-button"
              >
                ADD KEY
              </button>
            </a>
          </div>
          <div v-if="addKeyClicked" class="continue-container">
            <p class="row justify-content-center">I have added my virtual key.</p>
            <div class="row justify-content-center">
              <router-link
                class="col-lg-3"
                :to="{ name: 'newDeviceSuccess' }"
              >
                <button
                  :style="{color: providerColor, borderColor: providerColor}"
                  class="final-button outline-button question-button"
                >
                  CONFIRM
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import defaultTheme from '../styles/defaultTheme';

export default {
  name: 'NewTesla',
  props: ['customerConfig'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      providerColor: '',
      addKeyClicked: false,
    };
  },
  computed: {
    virtualKeyUrl() {
      return this.$route.query.virtualKeyUrl;
    },
  },
  mounted() {
    this.providerColor = this.customerConfig.color;
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/styles.scss';
h4 {
  margin-top: 150px;
}
a {
  color: #016699;
}
p {
  margin:top
}
.continue-container {
  margin-top: 50px;
}
button.question-button {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
}
</style>
