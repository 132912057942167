export default {

  getErrorMessage(code, i18n) {
    let msg = '';
    const { locale } = i18n;
    switch (code) {
      case 'new-device-generic':
        msg = i18n.messages[locale].byodErrorPageTextGeneric;
        break;
      case 'oauth-denied':
        msg = i18n.messages[locale].byodErrorPageTextOauthDenied;
        break;
      case 'oauth-dupe':
        msg = i18n.messages[locale].byodErrorPageTextOauthDupe;
        break;
      case 'oauth-timeout':
        msg = i18n.messages[locale].byodErrorPageTextOauthTimeout;
        break;
      case 'oauth-nodevices':
        msg = i18n.messages[locale].byodErrorPageTextOauthNoDevices;
        break;
      case 'utility-email':
        msg = i18n.messages[locale].byodErrorPageTextUtilityEmail;
        break;
      default:
        msg = i18n.messages[locale].byodErrorPageTextGeneric;
        break;
    }
    return msg;
  },
};
