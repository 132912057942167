/* global gtag, fbq, saq */

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import AsyncComputed from 'vue-async-computed';
import VueClipboard from 'vue-clipboard2';
import VueGtag from 'vue-gtag';
import VueComponentLibrary from '@virtual-peaker/vue-component-library';
import defaultEnglishValidationMessages from 'vee-validate/dist/locale/en';
import defaultSpanishValidationMessages from 'vee-validate/dist/locale/es';
import i18n from './i18n';
import App from './App.vue';
import Signup from './components/Signup';
import Success from './components/Success.vue';
import Err from './components/Err.vue';
import Navbar from './components/Navbar.vue';
import Terms from './components/Terms.vue';
import NewTesla from './components/NewTesla.vue';
import VpValidator from './lib/validation';
import * as configHelper from './lib/configHelper';
import defaultTheme from './styles/defaultTheme';
import vp from './services/api/vp';
import storage from './services/storage';
import store from './store';
import { OverrideDefaultValidationMessageFactory } from './lib/DefaultValidationMessageOverride';

const CustConfig = window.cConfig;
const { providerId } = CustConfig;
const customer = configHelper.getCustomer(CustConfig);
const CustProgram = configHelper.getProgram(CustConfig);
const invalidMessages = configHelper.getInvalidMessages(customer, CustProgram);
const theme = { ...defaultTheme };
const commonProps = {
  customerConfig: CustConfig,
  program: CustProgram,
  customer,
  theme,
};

vp.initiallize({ providerId, apiHost: window.VP_API_HOST });

Vue.use(VueRouter);

const overrideDefaultEnglishValidationMessages = OverrideDefaultValidationMessageFactory('en');
const overrideDefaultSpanishValidationMessages = OverrideDefaultValidationMessageFactory('es');

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    en: overrideDefaultEnglishValidationMessages(defaultEnglishValidationMessages),
    es: overrideDefaultSpanishValidationMessages(defaultSpanishValidationMessages),
  },
  events: 'input|blur',
});
Vue.use(AsyncComputed);
Vue.use(VueTheMask);
Vue.use(VueClipboard);
Vue.use(VueComponentLibrary, { store });
VpValidator.init(invalidMessages, CustConfig);

const routes = [
  {
    path: '/',
    redirect: () => {
      const program = configHelper.getProgram(CustConfig);
      return `/signup/${program.programName}`;
    },
    component: Signup,
    props: { ...commonProps },
  },
  {
    name: 'signup',
    path: '/signup',
    redirect: () => {
      const program = configHelper.getProgram(CustConfig);
      return `/signup/${program.programName}`;
    },
    component: Signup,
    props: { ...commonProps },
  },
  {
    name: 'programSignup',
    path: '/signup/:programId/',
    component: Signup,
    beforeEnter: (to, from, next) => {
      const selectedProgram = storage.get('selectedProgram') || null;
      if (from.path.indexOf('/error') !== -1 && !selectedProgram) {
        window.location.reload();
      }
      storage.remove('selectedProgram');
      storage.remove('selecedLanguage');
      const { programId } = to.params;
      const program = configHelper.getProgram(CustConfig, programId);
      if (Object.keys(to.query).length > 0) {
        let devices = [];
        if (to.query.devices) {
          devices = to.query.devices.split(',');
        }
        commonProps.initialValues = Object.assign({}, to.query, { devices });
      }

      storage.set('programId', program.programName);
      storage.set('selectedLanguage', i18n.locale);
      if (programId === program.programName) {
        document.title = program.programTitle || program.programLongName || 'Bring Your Own Device';
        commonProps.program = program;
        return next();
      }
      return next(`/signup/${program.programName}`);
    },
    props: route => ({ ...commonProps }),
  },
  {
    name: 'programSignupAddDevice',
    path: '/signup/:programId/add',
    component: Signup,
    beforeEnter: (to, from, next) => {
      if (from.path.indexOf('/error') !== -1) {
        window.location.reload();
      }

      const { programId } = to.params;

      if (!vp.isTokenActive()) {
        return next('/error');
      }

      const program = configHelper.getProgram(CustConfig, programId);

      storage.set('programId', program.programName);
      if (programId === program.programName) {
        document.title = program.programTitle || program.programLongName || 'Bring Your Own Device';
        commonProps.program = program;
        return next();
      }
      return next(`/signup/${program.programName}/add`);
    },
    props: route => ({ ...commonProps }),
  },
  {
    name: 'programShortAddDevice',
    path: '/signup/:programId/short',
    component: Signup,
    meta: {
      initialValues: true,
    },
    beforeEnter: async (to, from, next) => {
      try {
        if (from.path.indexOf('/error') !== -1) {
          window.location.reload();
        }

        const { programId } = to.params;
        const { houseId = null, token = null } = to.query;

        if (!token || !houseId) {
          throw new Error();
        }

        vp.storeToken(token);

        if (!vp.isTokenActive()) {
          throw new Error();
        }

        const house = await vp.getHouse(providerId, houseId);
        commonProps.initialValues = house;

        const program = configHelper.getProgram(CustConfig, programId);

        storage.set('programId', program.programName);
        storage.set('selectedLanguage', i18n.locale);
        if (programId === program.programName) {
          document.title = program.programTitle || program.programLongName || 'Bring Your Own Device';
          commonProps.program = program;
          return next();
        }
        return next(`/signup/${program.programName}/short?houseId=${houseId}&token=${token}`);
      } catch (e) {
        return next('/error');
      }
    },
    props: route => ({ ...commonProps }),
  },
  {
    name: 'programNav',
    path: '/signup/:programId',
    component: Navbar,
    props: route => ({ ...commonProps }),
  },
  {
    name: 'terms',
    path: '/signup/:programId/terms',
    component: Terms,
    beforeEnter: (to, from, next) => {
      const { programId } = to.params;

      const program = configHelper.getProgram(CustConfig, programId);

      if (programId === program.programName) {
        document.title = program.programTitle || program.programLongName || 'Bring Your Own Device';
        commonProps.program = program;
        return next();
      }
      return next(`/signup/${program.programName}/terms`);
    },
    props: route => ({ ...commonProps }),
  },
  {
    name: 'success',
    path: '/success',
    component: Success,
    props: route => ({ ...commonProps }),
    beforeEnter: (to, from, next) => {
      const { programId, repeat = false } = storage.getAll();
      storage.remove('repeat');
      commonProps.repeat = repeat;
      commonProps.program = configHelper.getProgram(CustConfig, programId);
      const gtagId = configHelper.getExternalAnalyticsId(CustConfig, 'gtag.id');
      const conversionLabel = configHelper.getExternalAnalyticsId(CustConfig, 'gtag.conversionLabel');
      if (gtagId && conversionLabel) {
        if (gtag && typeof gtag === 'function') {
          gtag('event', 'conversion', { send_to: `${gtagId}/${conversionLabel}` });
        } else {
          console.log('Google analytics is enabled but gtag function is not available for consumption.');
        }
      }
      if (configHelper.getExternalAnalyticsId(CustConfig, 'fb.id')) {
        if (fbq && typeof fbq === 'function') {
          fbq('track', 'Subscribe');
        } else {
          console.log('Facebook pixel is enabled but fbq function is not available for consumption.');
        }
      }
      const saPixelConvId = configHelper.getExternalAnalyticsId(CustConfig, 'sa.convId');
      if (saPixelConvId) {
        if (saq && typeof saq === 'function') {
          saq('conv', saPixelConvId);
        } else {
          console.log('StackAdapt Pixel is enabled but saq function is not available for consumption.');
        }
      }
      return next();
    },
  },
  {
    name: 'newDeviceTesla', // successful oauth return
    path: '/new-device/tesla',
    component: NewTesla,
    props: route => ({ ...commonProps }),
  },
  {
    name: 'newDeviceSuccess', // successful oauth return
    path: '/new-device/success',
    component: Success,
    props: route => ({ ...commonProps }),
    beforeEnter: (to, from, next) => {
      const { programId, repeat = false } = storage.getAll();
      storage.remove('repeat');
      commonProps.repeat = repeat;
      commonProps.program = configHelper.getProgram(CustConfig, programId);
      return next();
    },
  },
  {
    name: 'error',
    path: '/error',
    component: Err,
    beforeEnter: (to, from, next) => {
      const { programId } = storage.getAll();
      commonProps.program = configHelper.getProgram(CustConfig, programId);
      return next();
    },
    props: route => ({ ...commonProps }),
  },
];

const router = new VueRouter({
  routes,
});

// NOTE:
// The linker params were not well documented. The following issue heled with understanding:
// // https://github.com/MatteoGabriele/vue-gtag/issues/61
Vue.use(VueGtag, {
  config: { id: window.GA_ID },
  includes: (CustConfig.gaIncludes || []).map(id => ({ id })),
  enabled: true,
  params: {
    linker: {
      domains: CustConfig.gaLinkerDomains || [],
    },
  },
}, router);
const gtagId = configHelper.getExternalAnalyticsId(CustConfig, 'gtag.id');
if (gtagId) {
  if (gtag && typeof gtag === 'function') {
    gtag('js', new Date());
    gtag('config', gtagId);
    gtag('event', 'page_view', { send_to: gtagId });
  } else {
    console.log('Google analytics is enabled but gtag function is not available for initialization.');
  }
}
const fbPixelId = configHelper.getExternalAnalyticsId(CustConfig, 'fb.id');
if (fbPixelId) {
  if (fbq && typeof fbq === 'function') {
    fbq('init', fbPixelId);
    fbq('track', 'PageView');
  } else {
    console.log('Facebook Pixel is enabled but fbq function is not available for initialization.');
  }
}
const saPixelTsId = configHelper.getExternalAnalyticsId(CustConfig, 'sa.tsId');
if (saPixelTsId) {
  if (saq && typeof saq === 'function') {
    saq('ts', saPixelTsId);
  } else {
    console.log('StackAdapt Pixel is enabled but saq function is not available for initialization.');
  }
}

Vue.config.errorHandler = (err, self, info) => {
  self.$gtag.exception({
    description: err.message,
    fatal: false,
  });
  throw err;
};

new Vue({ // eslint-disable-line no-new
  router,
  i18n,
  el: '#app',
  render: h => h(App),
  mounted() {
    this.$validator.localize('en');
  },
});
