import axios from 'axios';
import storage from '../storage';
import houseDataToInitialValues from '../../lib/houseDataToInitialValues';

const TOKEN_LIFE = 900 * 1000;

export default {

  providerId: null,

  apiHost: null,

  initiallize(opts) {
    const { providerId, apiHost } = opts;
    this.providerId = providerId;
    this.apiHost = apiHost;
  },

  getToken() {
    return storage.get('token');
  },

  isTokenActive() {
    if (!this.getToken()) {
      return false;
    }
    return Date.parse(storage.get('expiration')) > Date.now();
  },

  updateTokenExpiration() {
    storage.set('expiration', (new Date(Date.now() + TOKEN_LIFE)).toISOString());
  },

  storeToken(token) {
    storage.set('token', token);
    this.updateTokenExpiration();
  },

  get(path, opts = { auth: true }) {
    const url = `${this.apiHost}${path}`;
    let headers = {};
    if (opts.auth) {
      headers = { Authorization: `Bearer ${this.getToken()}` };
    }
    return axios.get(
      url,
      { headers },
    )
      .then(({ data }) => {
        if (opts.auth) {
          this.updateTokenExpiration();
        }
        return data;
      })
      .catch(error => Promise.reject(error));
  },

  post(path, submit, opts = { auth: true }) {
    const url = `${this.apiHost}${path}`;
    let headers = {};
    if (opts.auth) {
      headers = { Authorization: `Bearer ${this.getToken()}` };
    }
    return axios.post(
      url,
      submit,
      { headers },
    )
      .then(({ data }) => {
        if (opts.auth) {
          this.updateTokenExpiration();
        }
        return data;
      })
      .catch(error => Promise.reject(error));
  },

  delete(path, submit, opts = { auth: true }) {
    const url = `${window.VP_API_HOST}${path}`;
    let headers = {};
    if (opts.auth) {
      headers = { Authorization: `Bearer ${this.getToken()}` };
    }
    return axios.delete(url, {
      headers,
      data: submit,
    })
      .then(({ data }) => {
        if (opts.auth) {
          this.updateTokenExpiration();
        }
        return data;
      })
      .catch(error => Promise.reject(error));
  },

  initializeProcess(signupData) {
    const url = '/byod';
    const {
      firstName, lastName, email, phone, countryCode, program, providerId, capchaResponse, language,
    } = signupData;
    const partialData = {
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      program,
      capchaResponse,
      providerId: this.providerId,
      language,
    };
    return this.post(url, partialData, { auth: false })
      .then((data) => {
        const { uid, token } = data;
        this.storeToken(token);
        return data;
      });
  },

  updatePartialSignup(signupData) {
    const url = '/byod/partial';
    const {
      address, address2, city, country, phone, countryCode, state, zipcode, accountNumber, tags, ...rest
    } = signupData;
    const partialData = {
      address,
      address2,
      country,
      city,
      phone: (phone || '').split('').filter(char => /\w/.test(char)).join(''),
      countryCode,
      state,
      zipcode,
      accountNumber,
      tags,
    };
    return this.post(url, partialData);
  },

  getPartialSignup() {
    const url = '/byod';
    return this.get(url);
  },

  createHouse(signupData) {
    const url = '/byod/house';
    const { termId, programId, tags } = signupData;
    const partialData = { termId, programId, tags };
    return this.post(url, partialData);
  },

  addDevice(deviceData) {
    const url = '/byod/house/add-device';
    return this.post(url, deviceData)
      .then(data => data);
  },

  removeDevice(deviceData) {
    const url = '/byod/house/delete-device';
    return this.delete(url, deviceData);
  },

  getPairingCode({ mfg }) {
    const url = `/byod/house/pairing-code?mfg=${mfg}`;
    return this.get(url);
  },

  complete() {
    const url = '/byod/house/complete';
    const language = storage.get('selectedLanguage') || 'en';
    return this.post(url, { language });
  },

  getTerms(termId) {
    const url = `https://d1byei05mtwnfj.cloudfront.net/terms/${termId}.md`;
    return axios.get(url)
      .then(({ data }) => data);
  },

  async getDeviceTerms(providerId, mfg) {
    const { value: termsLink } = await this.get(`/byod/provider/${providerId}/device-terms?mfg=${mfg}`);
    return termsLink;
  },
  async getHouse(providerId, houseId) {
    const house = await this.get(`/byod/provider/${providerId}/house/${houseId}`);
    return houseDataToInitialValues(house);
  },
};
