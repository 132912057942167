import Device from '../Device';

const questions = [{
  type: 'franklinwh-pairing-code',
  name: 'franklinWHPairingCode',
  model: 'franklinWHPairingCode',
  label: 'step3FranklinWHPairingCode',
  show: (values, parent) => Boolean(parent.allQuestionsValid),
  active: true,
  mfg: 'franklinwh',
  validation: 'required',
}];

const addDevice = async (values, context) => null;

const franklinwh = new Device('franklinwh', { mfg: 'franklinwh', questions, addDevice });

export default franklinwh;
