import Device from '../Device';

const questions = [
  {
    model: 'smartcar',
    type: 'oauth',
    name: 'smartcar',
    label: 'step3OauthDeviceSmartcar',
    update: values => ({
      additionalFields: {
        type: 'smartcar',
        tags: JSON.stringify(_.get(values, 'tags') || {}),
        deviceTags: JSON.stringify(_.get(values, 'deviceTags') || {}),
      },
    }),
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Smartcar = new Device('smartcar', { mfg: 'smartcar', questions, addDevice });

export default Smartcar;
