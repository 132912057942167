<template>
    <div>
        <a :href="buttonLink" target="_blank">
        <button
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            v-on:click="submit"
            class="question-button">
           {{ buttonText }}
        </button>
        </a>
    </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-button',
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {};
  },
  computed: {
    buttonText() {
      return this.options.buttonText || 'Submit';
    },
    buttonLink() {
      return this.options.buttonLink || '';
    },
  },
  methods: {
    submit() {
      this.$emit('input', true);
    },
  },
};
</script>

  <style scoped>
   .question-button {
     margin-top: 35px;
   }
  </style>
