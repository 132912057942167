<template>
  <div class="col-lg-12" aria-live="assertive">
    <form ref="oauthForm" :action="hostUrl" method="post">
      <input type="hidden" :value="device" name="type" />
      <input type="hidden" :value="token" name="token" />
      <input type="hidden" :value="clientUrl" name="client" />
      <input type="hidden" :value="houseId" name="houseId" />
      <input type="hidden" :value="providerId" name="providerId" />
      <input type="hidden" :value="programName" name="program" />
      <input v-for="field in normalizedAdditionalFields" :key="`field-${field.key}`" type="hidden" :value="field.value" :name="field.key" />
    </form>
    <!-- <button
        v-bind:style="{backgroundColor: theme.primaryColor}"
        v-on:click="submit"
        class="question-button"
    >
      {{buttonText}}
    </button> -->
  </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';
import vp from '../../services/api/vp';

export default {
  name: 'question-oauth',
  props: {
    additionalFields: Object,
    hostUrl: {
      type: String,
      default: `${window.VP_API_HOST}/byod/house/oauth-device`,
    },
    clientUrl: {
      type: String,
      default: `${window.VP_BYOD_HOST}`,
    },
    buttonText: {
      type: String,
      default: 'Submit',
    },
    testMode: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    account: 'account',
    customer: 'customer',
    program: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    return {};
  },
  computed: {
    houseId() {
      return this.account ? this.account.houseId : null;
    },
    token() {
      return vp.getToken();
    },
    programName() {
      return this.program ? this.program.programName : null;
    },
    providerId() {
      return this.customer ? this.customer.id : null;
    },
    device() {
      return this.$attrs.question.additionalFields.type || this.$attrs.value.device;
    },
    normalizedAdditionalFields() {
      return Object.entries(this.$attrs.question.additionalFields || {})
        .filter(([key]) => key !== 'type')
        .map(([key, value]) => ({ key, value }));
    },
  },
  methods: {
    submit() {
      if (this.testMode) {
        let form = {};
        this.$refs.oauthForm.elements.forEach((el) => {
          form = { ...form, [el.name]: el.value };
        });
      } else {
        this.$refs.oauthForm.submit();
      }
    },
  },
  mounted() {
    const { domain, byodPath } = this.customer;
    if (domain) {
      this.clientUrl = `${domain}${byodPath}`.replace(/\/$/, '');
    }
    this.$root.$on('oauth.submit', (values, context) => {
      this.submit();
      this.$root.$emit('oath.redirect');
    });
  },
};
</script>

<style scoped>
 .question-button {
   margin-top: 35px;
 }
</style>
