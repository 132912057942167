import axios from 'axios';

export default function Factory({ localization = 'US' }) {
  const usAutocomplete = (inputAddress, limit) => {
    const urlAddress = encodeURIComponent(inputAddress);
    const url = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${window.SMARTYSTREETS_API_KEY}&search=${urlAddress}&max_results=${limit}&license=${window.SMARTYSTREETS_US_AUTOCOMPLETE_LICENSE || ''}`;
    return axios.get(url)
      .then(({ data }) => data.suggestions.map(item => ({ text: `${item.street_line}${item.secondary ? ' ' : ''}${item.secondary} ${item.city}, ${item.state} ${item.zipcode}`, address: item })))
      .catch(() => Promise.resolve([]));
  };

  const usValidate = (inputAddress) => {
    const urlAddress = encodeURIComponent(inputAddress);
    const url = `https://us-street.api.smartystreets.com/street-address?auth-id=${window.SMARTYSTREETS_API_KEY}&street=${urlAddress}&license=${window.SMARTYSTREETS_US_VALIDATE_LICENSE || ''}`;
    return axios.get(url)
      .then(({ data }) => {
        if (!data.length) return {};
        const { delivery_line_1: address, delivery_line_2: address2, components } = data[0];
        const { city_name: city, state_abbreviation: state, zipcode } = components;
        return {
          address,
          address2,
          city,
          state,
          zipcode,
          country: 'US',
        };
      }).catch(() => Promise.resolve({}));
  };

  const auAutocomplete = (inputAddress, limit) => {
    const urlAddress = encodeURIComponent(inputAddress);
    const url = `https://api.addressfinder.io/api/au/address/autocomplete/?key=${window.ADDRESSFINDER_API_KEY}&q=${urlAddress}&format=json&gnaf=1`;
    return axios.get(url)
      .then(({ data }) => data.completions.map(({ full_address: text }) => ({ text, address: null })))
      .catch(() => Promise.resolve([]));
  };

  const auValidate = (inputAddress) => {
    const urlAddress = encodeURIComponent(inputAddress);
    const url = `https://api.addressfinder.io/api/au/address/verification/?key=${window.ADDRESSFINDER_API_KEY}&q=${urlAddress}&format=json`;
    return axios.get(url)
      .then(({ data }) => {
        if (!data.success || !data.matched) {
          return {};
        }
        const {
          address_line_1: address,
          address_line_2: address2,
          locality_name: city,
          state_territory: state,
          postcode: zipcode,
        } = data.address;
        return {
          address,
          address2,
          city,
          state,
          zipcode,
          country: 'AU',
        };
      }).catch(() => Promise.resolve({}));
  };

  const caAutocomplete = (inputAddress, limit) => {
    const urlAddress = encodeURIComponent(inputAddress);
    const url = `https://international-autocomplete.api.smartystreets.com/v2/lookup?key=${window.SMARTYSTREETS_API_KEY}&country=CAN&search=${urlAddress}&max_results=${limit}&license=${window.SMARTYSTREETS_INTL_AUTOCOMPLETE_LICENSE || ''}`;
    return axios.get(url)
      .then(({ data }) => data.candidates.map(item => ({ text: item.address_text, address: item, entries: item.entries })))
      .catch(() => Promise.resolve([]));
  };

  const caValidate = (addressString, addressObject) => {
    const { address_id: addressId, entries: secondaryEntries } = addressObject;
    const url = `https://international-autocomplete.api.smartystreets.com/v2/lookup/${addressId}?key=${window.SMARTYSTREETS_API_KEY}&country=CAN&license=${window.SMARTYSTREETS_INTL_AUTOCOMPLETE_LICENSE || ''}`;
    return axios.get(url)
      .then(({ data }) => {
        if (!data.candidates || data.candidates.length === 0) {
          return Promise.resolve({});
        }
        if (secondaryEntries > 1) {
          const secondaryOptions = data.candidates.map(item => ({ text: item.address_text, address: item, entries: item.entries }));
          return secondaryOptions;
        }
        const {
          street: address,
          locality: city,
          administrative_area: state,
          postal_code: zipcode,
        } = data.candidates[0];
        return Promise.resolve({
          address,
          address2: null,
          city,
          state,
          zipcode,
          country: 'CA',
        });
      })
      .catch(() => Promise.resolve({}));
  };

  const lib = {
    autocomplete: usAutocomplete,
    validate: usValidate,
  };

  if (localization === 'AU') {
    lib.autocomplete = auAutocomplete;
    lib.validate = auValidate;
  } else if (localization === 'CA') {
    lib.autocomplete = caAutocomplete;
    lib.validate = caValidate;
  }

  return lib;
}
