import { Validator } from 'vee-validate';
import i18n from '../i18n';

const { locale } = i18n;

const regexMessages = {
  phone: i18n.messages[locale].step1PhoneError,
  accountNumber: i18n.messages[locale].step1GenericError,
  tstatSelector: i18n.messages[locale].step3ThermostatError,
};

// const defaultMessages = {
//   email: i18n.messages[locale].step1GenericError,
//   regex: (field) => {
//     const message = regexMessages[field];
//     if (message) {
//       return message;
//     }
//     return `Oops, the ${field} is not correct.`;
//   },
// };

export default {
  init(invalidMessages = {}, config = {}) {
    const {
      localization, postalAllowlist, postalRegexAllowlist, cityAllowlist, stateAllowlist,
    } = config;
    const isAustralia = localization === 'AU';
    const isCanada = localization === 'CA';

    Validator.extend('phoneNumber', {
      getMessage: () => i18n.messages[locale].step1PhoneError,
      validate: (value) => {
        if (isAustralia) return /^\(?(\d{4})\)?[- ]?(\d{3})[- ]?(\d{3})$/.test(value);
        return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
      },
    });

    Validator.extend('phoneNumberLeadingDigits', {
      getMessage: () => 'Invalid mobile number. All mobile numbers must start with 04.',
      validate: (value) => {
        if (!isAustralia) return true;
        return (value[0] === '0' || value[0] === 0) && (value[1] === '4' || value[1] === 4);
      },
    });

    Validator.extend('address', {
      getMessage: () => i18n.messages[locale].step1AddressError,
      validate: value => value ? Boolean(value.length) : false,
    });

    Validator.extend('city', {
      getMessage: () => {
        if (isAustralia) {
          return 'Invalid address, All addresses must include Suburb';
        }
        return i18n.messages[locale].step1AddressCityError;
      },
      validate: value => value ? Boolean(value.length) : false,
    });

    Validator.extend('cityAllowlist', {
      getMessage: () => {
        if (isAustralia) {
          return 'Suburb not eligible';
        }
        return i18n.messages[locale].step1AddressCityEligibleError;
      },
      validate: (value) => {
        if (!cityAllowlist) return true;
        return (value && value.length) ? cityAllowlist.map(city => city.toLowerCase()).includes(value.toLowerCase()) : true;
      },
    });

    Validator.extend('state', {
      getMessage: () => {
        if (isAustralia) {
          return 'Invalid State / Territory length';
        }
        if (isCanada) {
          return 'Invalid Province length';
        }
        return i18n.messages[locale].step1AddressStateLengthError;
      },
      validate: (value) => {
        if (!value) return false;
        if (isAustralia) return [2, 3].some(limit => value.length === limit);
        return value.length === 2;
      },
    });

    Validator.extend('stateAllowlist', {
      getMessage: () => i18n.messages[locale].step1AddressStateEligibleError,
      validate: (value) => {
        if (!stateAllowlist) return true;
        return (value && value.length) ? stateAllowlist.map(state => state.toLowerCase()).includes(value.toLowerCase()) : true;
      },
    });

    Validator.extend('postalCode', {
      getMessage: () => {
        if (isAustralia) {
          return 'Postcode must be 4 numeric digits';
        }
        if (isCanada) {
          return 'Postal code appears to be invalid';
        }
        return i18n.messages[locale].step1AddressZipcodeLengthError;
      },
      validate: (value) => {
        if (!value) return false;
        if (isAustralia) {
          return (/^\d{4}$/).test(value);
        }
        if (isCanada) {
          return (/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/).test(value);
        }
        return (/^\d{5}$/).test(value);
      },
    });

    Validator.extend('postalCodeAllowList', {
      getMessage: () => i18n.messages[locale].step1AddressZipcodeEligibleError,
      validate: (value) => {
        if (!postalAllowlist && !postalRegexAllowlist) return true;
        if (!value) return false;
        if (postalAllowlist) {
          return (value.length >= 4) ? postalAllowlist.includes(value) : true;
        }
        if (postalRegexAllowlist) {
          const re = new RegExp(postalRegexAllowlist, 'g');
          return re.test(value);
        }
        return true;
      },
    });

    Validator.extend('teslaSerial', {
      getMessage: () => i18n.messages[locale].step3TeslaSerialNumberError,
      validate: value => value[0] === 'C' || value[0] === 'T' || value[0] === 'G',
    });

    Object.assign(regexMessages, invalidMessages);
  },
};
