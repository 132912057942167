import _ from 'lodash';
import Device from '../Device';
import vp from '../../../services/api/vp';

const pathToDeviceLogos = require.context('@/assets/devices/honeywell', true);

const questions = [
  {
    type: 'tile-select',
    label: 'step3HoneywellPage.headline',
    tip: {
      title: 'step3HoneywellPage.setupTip.title',
      text: 'step3HoneywellPage.setupTip.text',
    },
    help: {
      text: 'step3HoneywellPage.helpLink',
    },
    model: 'deviceData.model',
    show: true,
    active: true,
    showNext: false,
    required: true,
    advanceOnEnter: true,
    advanceOnValue: true,
    included: true,
    items: [
      {
        value: 'honeywell',
        imgSrc: pathToDeviceLogos('./lyric.png', true),
        alt: 'Honeywell Home',
        name: 'Honeywell Home App',
      }, {
        value: 'honeywellTcc',
        imgSrc: pathToDeviceLogos('./wifi.png', true),
        alt: 'Honeywell Total Connect Comfort',
        name: 'Total Connect Comfort App',
      },
    ],
  },
  {
    model: 'honeywell',
    type: 'oauth',
    name: 'honeywell',
    label: 'step3OauthDeviceHoneywell',
    active: true,
    showNext: false,
    included: true,
    show: (values) => {
      const deviceData = _.get(values, 'deviceData');
      return Boolean(deviceData && (deviceData.model === 'honeywellTcc' || deviceData.model === 'honeywell'));
    },
    update: values => ({
      additionalFields: {
        type: _.get(values, 'deviceData.model') || 'honeywell',
        tags: JSON.stringify(_.get(values, 'tags') || {}),
      },
    }),
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Honeywell = new Device('honeywell', { mfg: 'honeywell', questions, addDevice });

export default Honeywell;
