<template>
  <div class="phone-question">
    <div :class="{'multiRegional': region.isMultiRegional, 'form-control': region.isMultiRegional}">
      <select v-if="region.isMultiRegional" name="localization" v-model="region.localization" class="form-control">
        <option value="US">US</option>
        <option value="AU">AU</option>
      </select>
      <vp-checkbox
        v-model="phoneMessagingConsent"
        class="phoneMessagingConsent"
        :disabled="readonly"
        :styles="{ size: 20, color: theme.primaryColor }"
      >
        <template #label>{{ $t('step1PhoneMessagingConsent', { utilityName: providerName }) }}</template>
      </vp-checkbox>
      <input
        class="form-control"
        type="tel"
        :style="{ color: theme.primaryColor }"
        :name="name"
        :id="id"
        :readonly="readonly"
        v-mask="localized.mask"
        :placeholder="localized.placeholder"
        :value="displayValue"
        :data-vv-name="name"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup.enter="handleEnterPress"
        @input="handleInput"
        ref="input"
        :disabled="disableInput"
      />
      <div v-if="disclaimer" :style="{ visibility: disclaimerVisibility, color: theme.primaryColor }">
        <p v-html="disclaimer"></p>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-phone',
  data() {
    return {
      providerName: this.customer.name,
      providerId: this.customer.id,
      providerColor: this.customer.color,
      phoneMessagingConsent: false,
    };
  },
  inject: {
    theme: { default: defaultTheme },
    customer: 'customer',
  },
  props: {
    name: String,
    id: String,
    region: Object,
    readonly: Boolean,
    value: String,
    minLength: {
      type: Number,
      default: 10,
    },
    validation: String,
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  computed: {
    displayValue() {
      return this.value;
    },
    disclaimerVisibility() {
      // return this.$attrs.question.active ? 'visible' : 'hidden';
      return 'visible'; // Yes, this is hacky. The future of this feature is unclear at the moment.
    },
    disclaimer() {
      if (this.$attrs.question.disclaimer) {
        return marked(this.$attrs.question.disclaimer);
      }
      return null;
    },
    localized() {
      if (this.region.localization === 'AU') return { mask: '#### ### ###', placeholder: '04XX XXX XXX' };
      if (this.$i18n.locale === 'es') return { mask: '(###) ###-####', placeholder: 'Número de teléfono' };
      return { mask: '(###) ###-####', placeholder: 'Phone Number' };
    },
    disableInput() {
      return (this.readonly || !this.phoneMessagingConsent);
    },
  },
  created() {
    this.phoneMessagingConsent = JSON.parse(window.sessionStorage.getItem('phoneMessagingConsent')) || false;
  },
  watch: {
    phoneMessagingConsent(newValue, oldValue) {
      if ((newValue === false) && (oldValue === true)) {
        this.$emit('input', ''); // forcibly remove phone number from memory if consent is withdrawn
      }
    },
    $route(to, from) {
      const multipleLanguages = this.customer.commonSettings.find(({ key }) => key === 'byodMultipleLanguages');
      if (multipleLanguages) {
        window.sessionStorage.setItem('phoneMessagingConsent', JSON.stringify(this.phoneMessagingConsent));
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/mixins.scss';

.phone-question div:nth-child(2) {
  margin-top: 2rem;
}

div.multiRegional{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  padding: 0;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, .1);
  select {
    border: none;
    flex: 15%;
    min-width: 4.25rem;
    border-radius: 6px 0 0 6px;
    border-right: rgba(0, 0, 0, .1) solid 1px;
    @include Mobile-Only--small {
      flex: 100%;
      border-radius: 6px;
      border-right: none;
    }
  }
  input {
    flex: 75%;
    margin: 0;
    box-shadow: none;
    border-radius: 0 6px 6px 0;
    @include Mobile-Only--small {
      flex: 100%;
      border-radius: 0 0 6px 6px;
      border-top:  rgba(0, 0, 0, .1) solid 1px;
    }
  }
  div {
    flex: 100%;
  }
}

.phoneMessagingConsent {
  font-size: 12px;
}

</style>
