<template>
  <div class="question-select">
    <select
      :id="id"
      ref="input"
      :value="value"
      :data-vv-name="name"
      :disabled="readonly"
      :name="name"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="$emit('input', $event.target.value)"
      @keydown="handleKeyPress($event)"
    >
      <option v-for="option in options" :key="option.value" :value="option.value">{{ $t(option.label) || $t(option.value) }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'question-select',
  props: {
    id: String,
    name: String,
    readonly: Boolean,
    value: String,
    options: Array,
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.options.length > 0 && this.options[0].value) {
      this.$emit('input', this.options[0].value);
    }
  },
  watch: {
    options: {
      handler() {
        if (this.$attrs.question?.conditionalOptions) {
          this.$emit('input', this.options[0].value);
        }
      },
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleBlur() {
      this.$emit('blur');
    },
    handleKeyPress(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.$emit('keyPressEnter');
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/styles.scss';

.question-select {
  select {
    font-size: 1.1em;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    margin-top: 15px;
    padding: 0.375rem 17px;;
    background: none;
    border-radius: 6px;

    &focus {
      border: 2px solid #185abc;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

</style>
