import i18n from '../../i18n';

const pathToDeviceLogos = require.context('@/assets/devices', true);
const pathToIcons = require.context('@/assets/icons', true);

export const allDeviceTypes = {
  none: {
    key: 'none',
    style: {},
    display: 'none',
    show: false,
    deviceList: [],
  },
  simulatedDevice: {
    key: 'simulatedDevice',
    style: {},
    display: 'none',
    name: 'Simulator',
    svgSrc: pathToIcons('./SimulatedDevice.svg', true),
    svgId: 'simulator',
    show: false,
    deviceList: [{
      mfg: 'vpbattery',
      show: false,
      iconStyle: 'fa-battery-three-quarters',
      name: 'Sim Battery',
      style: {},
    }, {
      mfg: 'vpevse',
      show: false,
      svgSrc: pathToIcons('./EVCharger.svg', true),
      svgId: 'ev-charger',
      name: 'Sim EVSE',
      style: {},
    }, {
      mfg: 'vptesla',
      show: false,
      iconStyle: 'fa-car',
      name: 'Sim Tesla',
      style: {},
    }, {
      mfg: 'vprivian',
      show: false,
      iconStyle: 'fa-car',
      name: 'Sim Rivian',
      style: {},
    }, {
      mfg: 'vphyundai',
      show: false,
      iconStyle: 'fa-car',
      name: 'Sim Hyundai',
      style: {},
    }],
  },
  hwh: {
    key: 'hwh',
    style: {},
    display: 'none',
    name: i18n.t('step3WaterHeater'),
    iconStyle: 'fa-tint',
    show: false,
    deviceList: [{
      mfg: 'aquanta',
      show: false,
      style: {},
    }, {
      mfg: 'rheem',
      show: false,
      style: {},
    }, {
      mfg: 'ruud',
      show: false,
      style: {},
    }, {
      mfg: 'ge',
      show: false,
      style: {},
    }, {
      mfg: 'eradio',
      show: false,
      style: {},
    }, {
      mfg: 'aosmith',
      show: false,
      style: {},
    }, {
      mfg: 'american',
      show: false,
      style: {},
    }, {
      mfg: 'reliance',
      show: false,
      style: {},
    }, {
      mfg: 'state',
      show: false,
      style: {},
    }, {
      mfg: 'lochinvar',
      show: false,
      style: {},
    }, {
      mfg: 'uscraftmaster',
      show: false,
      style: {},
    }, {
      mfg: 'tricklestar',
      show: false,
      style: {},
    }],
  },
  battery: {
    key: 'battery',
    style: {},
    display: 'none',
    name: i18n.t('step3Battery'),
    iconStyle: 'fa-battery-three-quarters',
    show: false,
    deviceList: [{
      mfg: 'sonnen',
      show: false,
      style: {},
    }, {
      mfg: 'solaredge',
      show: false,
      style: {},
    }, {
      mfg: 'pika',
      show: false,
      style: {},
    }, {
      mfg: 'tesla',
      show: false,
      style: {},
    }, {
      mfg: 'sunverge',
      show: false,
      style: {},
    }, {
      mfg: 'emporia',
      show: false,
      style: {},
    },
    {
      mfg: 'enphase',
      show: false,
      style: {},
    }, {
      mfg: 'eguana',
      show: false,
      style: {},
    }, {
      mfg: 'duracell',
      show: false,
      style: {},
    }, {
      mfg: 'franklinwh',
      show: false,
      style: {},
    }],
  },
  thermostat: {
    key: 'thermostat',
    style: {},
    display: 'none',
    name: i18n.t('step3Thermostat'),
    iconStyle: 'fa-thermometer-full',
    show: false,
    deviceList: [{
      mfg: 'ecobee',
      show: false,
      style: {},
    }, {
      mfg: 'honeywell',
      show: false,
      style: {},
      disclaimer: '©2020 Resideo Technologies, Inc. This product is manufactured by Resideo and its affiliates. The Honeywell Home trademark is used under license from Honeywell International, Inc. The Resideo logo, pictured in the lower right, is the official corporate identity of Resideo, the new home of Honeywell Home. This is used by internal corporate teams only.',
    }, {
      mfg: 'honeywellAcc',
      show: false,
      style: {},
      disclaimer: 'Amazon and all related marks are trademarks of Amazon.com, Inc. or its affiliates.',
    }, {
      mfg: 'nest',
      show: false,
      style: {},
      disclaimer: 'Google Nest is a trademark of Google LLC.',
    },
    {
      mfg: 'sensibo',
      show: false,
      style: {},
    }, {
      mfg: 'flair',
      show: false,
      style: {},
    },
    {
      mfg: 'sensi',
      show: false,
      style: {},
    }, {
      mfg: 'mysa',
      show: false,
      style: {},
    }],
  },
  evse: {
    key: 'evse',
    style: {},
    display: 'none',
    name: i18n.t('step3EvCharger'),
    svgSrc: pathToIcons('./EVCharger.svg', true),
    svgId: 'ev-charger',
    alt: 'EV Charger',
    show: false,
    deviceList: [{
      mfg: 'chargepoint',
      show: false,
      style: {},
    }, {
      mfg: 'flo',
      show: false,
      style: {},
    }, {
      mfg: 'juicenet',
      show: false,
      style: {},
    }, {
      mfg: 'enelx',
      show: false,
      style: {},
    }, {
      mfg: 'emporia',
      show: false,
      style: {},
    }, {
      mfg: 'evocharge',
      show: false,
      style: {},
    }, {
      mfg: 'wallbox',
      show: false,
      style: {},
    }],
  },
  ev: {
    key: 'ev',
    style: {},
    display: 'none',
    name: i18n.t('step3EvDevice'),
    iconStyle: 'fa-car',
    show: false,
    deviceList: [
      {
        mfg: 'teslaev',
        show: false,
        svgSrc: pathToIcons('./EVTesla.svg', true),
        svgId: 'ev-device-tesla',
        alt: 'Tesla',
        style: {},
      },
      {
        mfg: 'smartcar',
        show: false,
        svgSrc: pathToIcons('./EVDevice.svg', true),
        svgId: 'ev-device',
        alt: 'Other Brands',
        name: 'Other Brands',
        style: {},
      }],
  },
  circuitBreaker: {
    key: 'circuitBreaker',
    style: {},
    display: 'none',
    name: i18n.t('step3CircuitBreaker'),
    iconStyle: 'fa-toggle-on',
    show: false,
    deviceList: [{
      mfg: 'eaton',
      show: false,
      style: {},
    }],
  },
  storageHVAC: {
    key: 'storageHVAC',
    style: {},
    display: 'none',
    name: i18n.t('step3StorageHVAC'),
    svgSrc: pathToIcons('./HVACStorage.svg', true),
    svgId: 'hvac-storage',
    alt: 'HVAC Storage',
    show: false,
    deviceList: [{
      mfg: 'stash',
      show: false,
      style: {},
    }],
  },
};

export function getDeviceTypes(deviceTypeList) {
  return (deviceTypeList || []).map(key => allDeviceTypes[key]).filter(Boolean);
}

export function getDeviceFromType(key, supported) {
  const deviceType = allDeviceTypes[key];
  if (!deviceType) return [];

  const { deviceList } = deviceType;
  const enhancedDeviceList = (deviceList || []).map(device => ({
    ...device,
    imgSrc: device.svgSrc ? null : pathToDeviceLogos(`./${device.mfg}.png`, true),
    alt: device.mfg,
  }));

  if (!supported) return enhancedDeviceList;

  const filteredEnhancedDeviceList = enhancedDeviceList.filter(d => supported.includes(d.mfg));

  if (key === 'ev' && filteredEnhancedDeviceList.length === 1) {
    return filteredEnhancedDeviceList.map((el) => el.mfg === 'smartcar' ? { ...el, name: 'Electric Vehicle' } : el);
  }

  return filteredEnhancedDeviceList;
}
