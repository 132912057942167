<template>
  <main>
    <button
      :style="{backgroundColor: theme.primaryColor}"
      @click="showModal"
      class="question-button"
    >
      START PAIRING
    </button>
    <Modal v-model="passThroughValue" :title="title" :showCloseButton="showCloseButton" class="gravity-modal">
      <p>
        Use the steps below as a guide to connect your device.
      </p>
      <Swiper :desc="desc" />
      <hr />
      <section class="row completion-instructions">
        <div class="col-md col-6">
          <p
            id="pairing-code__code"
            class="pairing-code__code"
            ref="gravityPairingCode"
            v-bind:style="{ color: theme.primaryColor }"
          >
            {{ pairingCode }}
          </p>
        </div>
        <div class="col-md col-6">
          <button
            ref="copyToClipboard"
            v-bind:style="{
              color: theme.primaryColor,
              borderColor: theme.primaryColor
            }"
            class="pairing-code__button"
            id="copy-pairing-code"
            v-clipboard:copy="pairingCode"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
          >
            {{ pairingBtnTxt }}
          </button>
        </div>
        <div v-if="!isCreated" class="col-md col-12 gravity__bounce">
          <button
            ref="openConnectionsPage"
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            class="question-button"
            id="gravity-connect_btn"
            v-on:click="redirect()"
          >
            {{ connectBtnTxt }}
          </button>
        </div>
        <div v-if="isCreated" class="col-md col-12 gravity__bounce">
          <button
            ref="openConnectionsPage"
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            class="question-button"
            @click="handleSubmit"
          >
            SUBMIT FORM
          </button>
        </div>
      </section>
      <hr />
      <p class="gravity-modal-footer">
        If you need help, contact us at
        <a
          v-bind:style="{ color: theme.primaryColor }"
          v-bind:href="'mailto:' + supportEmail"
          >{{ supportEmail }}</a
        >
      </p>
    </Modal>
    <Modal
      v-model="isCancelled"
      :title="titleCancel"
      :showCloseButton="showCloseButton"
      class="gravity-modal cancel-modal"
      @input="resumeDevice"
    >
      <p>
        By closing the window, you are ending
        your device connection request. If you do not want to end this
        connection request, please click the "Cancel" button below. If you hit
        "Cancel," we will take you back to the connection
        window to complete the device connection process.
      </p>
      <section class="row completion-instructions">
        <div class="col-5 col-md-4">
          <button
            v-bind:style="{
              color: theme.primaryColor,
              borderColor: theme.primaryColor,
            }"
            class="pairing-code__button"
            @click="resumeDevice"
          >
            CANCEL
          </button>
        </div>
        <div class="col-5 col-md-4">
          <button
            class="question-button gravity-cancel"
            @click="cancelDevice"
          >
            {{ cancelBtnText }}
          </button>
        </div>
      </section>
    </Modal>
  </main>
</template>

<style lang="scss">
@import '../../styles/mixins.scss';
div[type='pairing-code'] {
  // hiding default backsplash
  & > div > span,
  & > div > label {
    @include Mobile-Only {
      font-size: 16px;
      line-height: 24px;
    }
    font-size: 24px;
    line-height: 36px;
  }
}
main[data-vv-name="pairingCode"] {
  margin-top: 2rem;
  & > .question-button {
    min-width: 200px;
    min-height: 40px;
  }
}

.gravity-modal {
  #modal-title,
  .modal-body p {
    text-align: left;

    @include Mobile-Only {
      text-align: center;
    }
  }

  #modal-title-mobile {
    text-align: center;
  }

  .modal-body > p:first-of-type {
    margin-bottom: 1rem;
    @include Mobile-Only {
      margin-top: 0.5rem;
    }
  }

  h5#modal-title {
    padding: 0 0 0 2rem;
  }

  h5#modal-title-mobile {
    padding-top: 3rem;
  }

  div.modal-dialog {
    @include Not-Mobile {
      width: 100vw;
    }
  }
  .modal-body {
    height: 100%;
    @include Mobile-Only {
      padding-top: 0;
    }
    @include Not-Mobile {
      margin: 1rem 2rem;
    }

    & > p:first-of-type {
      @include Not-Mobile {
        margin-top: 0;
      }
    }

    button {
      font-size: 13px;
      &:active {
        box-shadow: none; // Remove general btn onClick border
        line-height: inherit;
      }
    }
  }
  .modal-header {
    align-items: center;
    @include Mobile-Only {
      padding-top: 2rem;
      padding-right: 1rem;
    }
  }

  p.gravity-modal-footer,
  p.gravity-modal-footer a {
    padding-bottom: 1rem;
    font-size: 14px;
    line-height: 20px;
    @include Mobile-Only {
      font-size: 11px;
      line-height: 16px;
    }
  }

  hr {
    margin: 0 auto;
    border: 1px solid $GREY;

    @include Mobile-Only {
      width: 80%;
    }
    @include Tablet {
      margin: 0.5rem auto;
    }
     &:first-of-type {
        margin-bottom: 2rem;
        @include Mobile-Only--no-height {
          margin-bottom: 1rem;
        }
        @include Tablet {
          margin-bottom: 1rem;
        }
      }
      &:last-of-type {
        margin-top: 2rem;
        @include Mobile-Only--no-height {
          margin-top: 1rem;
        }
        @include Tablet {
          margin-top: 1rem;
        }
      }
  }

  p {
    margin: 1rem auto;
    line-height: 24px;
    @include Mobile-Only {
      max-width: 80%;
      line-height: 20px;
      &,
      a {
        font-size: 14px;
      }
    }

    @include Not-Mobile {
      margin: 0.5rem auto;
    }

    @include Mobile-Only--small {
      margin: 0.25rem auto;
    }
  }
  .gravity-cancel {
    background-color: #ce3c31;
  }
  #pairing-code__code {
    font-size: 32px;
    line-height: 40px;
    background: $WHITE;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 6px;
    height: 100%;
    margin: 0;
    padding-top: $GAP;
    text-align: center;
    max-width: 100%;
    @include Mobile-Only {
      padding-top: 0;
      font-size: 24px;
    }
  }

  .pairing-code__button {
    background: $WHITE;
    border: 2px solid #016699;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .pairing-code__button,
  .question-button {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    @include Mobile-Only {
      padding: 10px;
    }
  }

  .completion-instructions {
    text-align: center;
    margin: auto;

    @include Mobile-Only {
      width: 80%;
    }

    @include Desktop--tall {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    div {
      @include Mobile-Only--small {
        padding: 2px 0;
      }
      @include Mobile-Only {
        padding-right: 0;
      }
      &:first-child {
          padding-left: 0;
          padding-right: .75rem;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: .75rem;
          @include Tablet {
            padding-right: .75rem;
          }
        }

       &:nth-child(3) {
          padding-top: 1.5rem;
          padding-right: 0;
          @include Tablet {
            padding-top: 0;
            padding-left: .75rem;
          }
        }

    }

    .col button {
      @include Mobile-Only {
        margin: 8px 0;
        width: 100%;
      }
    }
  }
}
.cancel-modal {
  .modal-body {
    @include Mobile-Only {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .completion-instructions {
    padding-top: 1rem;
    margin: 1rem auto;
    justify-content: space-around;
    @include Not-Mobile {
      justify-content: center;
    }

    & > div {
      @include Mobile-Only {
        padding: 0.75rem 0;
      }
    }

    button {
      height: 40px;
      padding: 0;
    }
  }
}
</style>

<script>
import Modal from '../Modal/Modal.vue';
import Swiper from '../Modal/Swiper.vue';
import defaultTheme from '../../styles/defaultTheme';
import vp from '../../services/api/vp';

export default {
  name: 'question-gravity-pairing-code',
  props: {
    question: Object,
    stepValues: Object,
    parentRefs: Object,
  },
  inject: {
    account: 'account',
    customer: 'customer',
    program: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    return {
      codeFetched: false,
      isMobile: false,
      isCreated: false,
      isCancelled: false,
      passThroughValue: false,
      showCompleteButton: false,
      cancelBtnText: 'END REQUEST',
      connectionsUrl: '',
      connectBtnTxt: 'Open Connections Page',
      desc: [
        'If using a mobile device, download the app and open Connections from the Account page. If using a browser, open the connections page in a new tab (click the "Connections Page" button below). Log in with your credentials, then copy the pairing code below.',
        'If using the app, paste the pairing code in the appropriate field. If using a browser, a popup will appear; paste pairing code in the proper field. Agree to the Terms & Conditions, and then click “Submit Request.”',
        'Once submitted, you are done. Please return to this window and click “Submit Form” to complete this form. When your utility approves your device, you will receive a confirmation email from us.',
      ],
      pairingCode: '00000',
      pairingBtnTxt: 'COPY CODE',
      partnerToken: '00000',
      supportEmail: 'support@virtual-peaker.com',
      title: 'Pair Your Device',
      titleCancel: 'End Device Connection Request',
      showCloseButton: true,
      deviceData: {},
    };
  },
  watch: {
    isReady: {
      immediate: true,
      handler(current, prev) {
        if (!prev && current) {
          this.fetchPairingCodeAndCreateDevice();
        }
      },
    },
    passThroughValue() {
      if (!this.passThroughValue && this.isCreated) {
        this.isCancelled = true;
      }
    },
    isCancelled: {
      handler(current, prev) {
        if (current) this.toggleText();

        if (prev && Object.keys(this.deviceData).length && !current && !this.passThroughValue) {
          this.resumeDevice();
        }
      },
    },
  },
  computed: {
    houseId() {
      return this.account ? this.account.houseId : null;
    },
    programName() {
      return this.program ? this.program.programName : null;
    },
    providerId() {
      return this.customer ? this.customer.id : null;
    },
    mfg() {
      return this.question.mfg;
    },
    missingFields() {
      const requiredFields = ['mfg', 'programName', 'providerId'];
      const missing = requiredFields.filter(fld => !this[fld]);
      return missing;
    },
    isReady() {
      return !this.missingFields.length;
    },
  },
  mounted() {
    this.toggleAddDeviceBtnDisplay('none');
    this.isMobile = window.matchMedia('(max-width: 576px)').matches;
    this.supportEmail = this.customer.supportEmail;
    this.toggleText();
  },
  updated() {
    [...document.querySelectorAll('button.close')].map(btn => btn.addEventListener('click', this.closeModal));
  },
  destroyed() {
    this.toggleAddDeviceBtnDisplay('');
    [...document.querySelectorAll('button.close')].map(btn => btn.removeEventListener('click', this.closeModal));
  },
  components: {
    Modal,
    Swiper,
  },
  methods: {
    toggleAddDeviceBtnDisplay(set) {
      const submitBtns = [...document.getElementsByClassName('pull-right question-button')];
      const [addDeviceBtn] = submitBtns.filter(el => el.innerText.includes('Add Device'));
      addDeviceBtn.style.display = set;
    },
    async fetchPairingCodeAndCreateDevice() {
      if (!this.isReady) return;
      const data = await vp.getPairingCode({
        mfg: this.mfg,
      });
      this.pairingCode = data.value;
      this.codeFetched = true;
      this.partnerToken = data.partnerToken;
      this.deviceData = {
        ...this.stepValues,
        pairingCode: this.pairingCode,
        mfg: this.mfg,
        houseId: this.houseId,
        program: this.programName,
        providerId: this.providerId,
      };
      try {
        const newDevice = await vp.addDevice(this.deviceData);
        this.deviceId = newDevice.id;
        this.deviceData.deviceId = this.deviceId;
        this.$emit('input', this.pairingCode);
        this.isCreated = true;
        this.toggleText();
      } catch (err) {
        if (this.$router) this.$router.push('/error');
      }
    },
    async redirect() {
      if (this.connectionsUrl) {
        window.open(this.connectionsUrl, '_blank');
      }
      return null;
    },
    async cancelDevice() {
      try {
        /* remove device from database */
        const data = vp.removeDevice(this.deviceData);
        /* remove device from component */
        this.deviceData = {};
        /* revert back to select device type */
        this.isCreated = false;
        this.connectionsUrl = '';
        this.isCancelled = false;
      } catch (error) {
        if (this.$router) this.$router.push('/error');
      }
      return null;
    },
    resumeDevice() {
      this.isCancelled = false;
      this.showModal();
    },
    onCopy() {
      this.pairingBtnTxt = this.isMobile ? 'Copied Code' : 'Copied Pairing Code';
    },
    onCopyError() {
      this.pairingBtnTxt = 'Failed to Copy';
    },
    toggleText() {
      if (this.isMobile) {
        this.cancelBtnText = 'END REQUEST';
        this.connectBtnTxt = 'OPEN CONNECTIONS PAGE';
        this.pairingBtnTxt = 'COPY CODE';
        this.title = 'Pair Your Device';
        this.titleCancel = 'End Device <br>Connection Request';
      } else {
        this.cancelBtnText = 'END CONNECTION';
        this.connectBtnTxt = 'CONNECTIONS PAGE';
        this.pairingBtnTxt = 'COPY PAIRING CODE';
        this.title = 'Pair Your Device';
        this.titleCancel = 'End Device Connection Request';
      }
    },
    showModal() {
      this.passThroughValue = true;
    },
    closeModal() {
      this.passThroughValue = false;
      this.toggleText();
    },
    handleSubmit() {
      this.$router.push({ path: '/success', params: { type: 'gravity' } });
    },
  },
};
</script>
