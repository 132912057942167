import Device from '../Device';
import vp from '../../../services/api/vp';
import { battery } from './Common';

const questions = [{
  type: 'text',
  label: 'step3TeslaLabel',
  labelInfo: 'step3TeslaSublabel',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: false,
  model: 'serialNumber',
  name: 'serialNumber',
  validation: 'required|teslaSerial',
}].concat(battery);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'tesla',
    deviceType: 'battery',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Tesla = new Device('tesla', { mfg: 'tesla', questions, addDevice });

export default Tesla;
