import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  model: 'vphyundai',
  type: 'sim',
  name: 'vphyundai',
}];

const addDevice = async (values, context) => {
  const deviceData = {
    ...values,
    mfg: 'vp-sim',
    deviceType: 'ev',
    kind: 'ev',
    type: 'HYUNDAI',
  };
  return vp.addDevice(deviceData);
};

const VPHyundai = new Device('vphyundai', { mfg: 'vp-sim', questions, addDevice });

export default VPHyundai;
