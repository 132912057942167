import Device from '../Device';
import vp from '../../../services/api/vp';
import { battery } from './Common';

const questions = [{
  type: 'text',
  label: 'step3SonnenLabel',
  labelInfo: 'step3SonnenSublabel',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: false,
  model: 'uid',
  validation: 'required',
  name: 'uid',
}].concat(battery);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'sonnen',
    deviceType: 'battery',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Sonnen = new Device('sonnen', { mfg: 'sonnen', questions, addDevice });

export default Sonnen;
