import Device from '../Device';
import vp from '../../../services/api/vp';
import { hwh } from './Common';

const questions = hwh.concat([{
  type: 'ao-smith-pairing-code',
  name: 'aoSmithPairingCode',
  model: 'aoSmithPairingCode',
  label: 'modalAoSmithPairing.uscraftmaster.introduction',
  role: 'status',
  show: (values, parent) => Boolean(parent.allQuestionsValid),
  active: true,
  mfg: 'aosmith',
  subBrand: 'uscraftmaster',
  validation: 'required',
}]);

const addDevice = async (values, context) => null;

const uscraftmaster = new Device('uscraftmaster', { mfg: 'aosmith', questions, addDevice });

export default uscraftmaster;
