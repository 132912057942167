import _ from 'lodash';
import Device from '../Device';

const questions = [
  {
    model: 'teslaev',
    type: 'oauth',
    name: 'teslaev',
    label: 'step3OauthDeviceTesla',
    update: values => ({
      additionalFields: {
        type: 'smartcar_tesla',
        tags: JSON.stringify(_.get(values, 'tags') || {}),
        deviceTags: JSON.stringify(_.get(values, 'deviceTags') || {}),
      },
    }),
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};
const Tesla = new Device('teslaev', { mfg: 'teslaev', questions, addDevice });

export default Tesla;
