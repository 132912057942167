<template>
  <tile-selector :id="`tile-selector_question-${question.number}-device`" :question="updatedQuestion" @input="handleSelect" :value="value" :readonly="readonly" />
</template>

<script>
import { getDeviceFromType } from './deviceTypes';
import TileSelector from './TileSelector.vue';

export default {
  name: 'question-device',
  props: {
    question: Object,
    supportedDevices: Array,
    readonly: Boolean,
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleSelect(key) {
      this.$emit('input', key);
      this.$emit('reset');
    },
  },
  mounted() {
    this.$root.$on('updated.deviceType', () => {
      this.$emit('input', null);
    });
  },
  computed: {
    updatedQuestion() {
      const rawItems = this.question.deviceType ? getDeviceFromType(this.question.deviceType, this.question.supportedDevices) : [];
      const items = rawItems.map(i => ({
        ...i,
        value: i.mfg,
        icon: i.iconStyle,
      }));

      return { ...this.question, items };
    },
  },
  components: { TileSelector },
};
</script>
