<template>
  <tile-selector :id="`tile-selector_question-${question.number}-device-type`" :question="updatedQuestion" @input="handleSelect" :value="value" :readonly="readonly" />
</template>

<script>
import { getDeviceTypes } from './deviceTypes';
import TileSelector from './TileSelector.vue';

export default {
  name: 'question-device-type',
  props: {
    question: Object,
    value: String,
    readonly: Boolean,
    supportedDeviceTypes: Array,
  },
  methods: {
    handleSelect(key) {
      this.$root.$emit('updated.deviceType', key);
      this.$emit('input', key);
    },
  },
  computed: {
    updatedQuestion() {
      const rawItems = getDeviceTypes(this.question.deviceTypeKeys);
      const items = rawItems.map(i => ({
        ...i,
        value: i.key,
        icon: i.iconStyle,
      })).filter(item => this.question.supportedDeviceTypes.includes(item.key));
      const question = { ...this.question, items };
      return question;
    },
  },
  components: { TileSelector },
};
</script>
