<template>
  <div role="status" class="container">
    <div class="row justify-content-center text-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div v-bind:style="{color: providerColor}" class="success-circle">
          <i class="fa fa-exclamation-triangle"></i>
        </div>
        <h4>{{ $t('byodErrorPageHeadline')}}</h4>
        <p class="text-center">
          {{this.errorMessage}}
        </p>
        <h5 v-if="this.supportPhone">{{ $t('byodErrorPageTelephone') }} {{this.supportPhone}}</h5>
        <h5 v-if="this.supportEmail">{{ $t('byodErrorPageEmail') }} {{this.supportEmail}}</h5>
        <button
          v-bind:style="{color: providerColor, borderColor: providerColor}"
          class="final-button outline-button question-button"
          v-on:click="buttonClick()"
        >
          {{ $t('byodErrorPageRetryButton') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import errorLib from '../lib/error';

export default {
  name: 'err',
  props: ['customerConfig', 'program'],
  data() {
    return {
      supportEmail: '',
      supportPhone: '',
      providerColor: '',
      errorMessage: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.errorMessage = errorLib.getErrorMessage(this.$route.query.e, this.$i18n);
  },
  mounted() {
    this.supportEmail = this.program.supportEmail || this.customerConfig.supportEmail;
    this.supportPhone = this.customerConfig.supportPhone;
    this.providerColor = this.customerConfig.color;
  },
  methods: {
    buttonClick() {
      const { domain, byodPath } = this.customerConfig;
      const rootLink = domain ? `${domain.match(/^http/) ? '' : 'https://'}${domain}${byodPath}` : '/';
      window.sessionStorage.clear();
      window.open(`${rootLink}#/signup/${this.program.programName}`, '_self');
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/styles/styles.scss';
.success-circle {
  font-size: 100px;
  text-align: center;
}

button.final-button:hover {
  opacity: 0.8;
}
button.final-button:focus {
  opacity: 0.8;
  outline: 2px solid #185abc;
}
</style>
