/* eslint import/no-cycle: 0 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import * as configHelper from './lib/configHelper';
import storage from './services/storage';

Vue.use(VueI18n);

const CustConfig = window.cConfig;
const customer = configHelper.getCustomer(CustConfig);
const multipleLanguages = customer.commonSettings.find(({ key }) => key === 'byodMultipleLanguages') || null;
let defaultLanguage;

const selectedLanguage = storage.get('selectedLanguage');
if (multipleLanguages && selectedLanguage) {
  defaultLanguage = selectedLanguage;
} else if (multipleLanguages && navigator.language.includes('es')) {
  defaultLanguage = 'es';
} else {
  defaultLanguage = 'en';
}
const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
};
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  // load custom validation messages for programQuestions
  // we expect a validationErrorMessage object on a question that looks like this:
  //  validationErrorMessage: {
  //    'en': {
  //      'is': 'We really need you to have wifi. Try answering yes instead!',
  //    }
  //  }
  // Where "en" is the locale, and "is" the validation rule being applied.
  // vee-validate is smart, and can display different error messages depending on
  // which validation rule is failing.
  // See: https://vee-validate.logaretm.com/v2/guide/rules.html#included
  // For the list of the rules vee-validate can handle
  const questions = [];
  if (CustConfig.programs) {
    CustConfig.programs.forEach((program) => {
      if (program.programQuestions) {
        program.programQuestions.forEach((question) => {
          if (question.validationErrorMessage) {
            questions.push(question);
          }
        });
      }
    });
  }

  // Load custom validation from commonQuestions.
  //
  // TODO: Additional work needs to be done to support custom validation on the
  // firstName, lastName, email and phone fields. Currently the validation rules
  // for those fields are defined in /src/components/Signup/questions-step1.js
  // and we'd need to add logic to either merge the validation rules or use byod-config
  // as the source of truth.
  if (CustConfig.commonQuestions) {
    CustConfig.commonQuestions.forEach((question) => {
      if (question.validationErrorMessage) {
        questions.push(question);
      }
    });
  }

  for (let i = 0; i < questions.length; i += 1) {
    const question = questions[i];
    const validationLocales = Object.keys(question.validationErrorMessage);
    validationLocales.forEach((locale) => {
      if (!messages[locale].validation) {
        messages[locale].validation = { custom: {} };
      }
      let keyTokens;
      if (question.key) {
        keyTokens = question.key.split('.');
      } else {
        keyTokens = question.model.split('.');
      }
      if (keyTokens.length === 1) {
        messages[locale].validation.custom[keyTokens[0]] = question.validationErrorMessage[locale];
      } else if (keyTokens.length === 2) {
        // handle converting keys like "tags.wifi enabled" into nested object needed for i18n locales
        if (messages[locale].validation.custom[keyTokens[0]] === undefined) {
          messages[locale].validation.custom[keyTokens[0]] = {};
        }
        messages[locale].validation.custom[keyTokens[0]][keyTokens[1]] = question.validationErrorMessage[locale];
      }
    });
  }
  return messages;
}

export default new VueI18n({
  locale: defaultLanguage || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
  numberFormats,
});
