export default {
  calculateRpnExpression(rpnExpression = '') {
    const exp = rpnExpression.split(' ').map(i => { return Number(i) || Number(i) === 0 ? Number(i) : i; });
    if (exp.length < 3) return null;
    function calculate(expArr) {
      const calc = {
        '+': (a, b) => parseFloat(a) + parseFloat(b),
        '-': (a, b) => parseFloat(a) - parseFloat(b),
        '*': (a, b) => parseFloat(a) * parseFloat(b),
        '/': (a, b) => parseFloat(a) / parseFloat(b),
        '^': (a, b) => parseFloat(a) ** parseFloat(b),
      };

      const stack = [];

      expArr.forEach(op => {
        stack.push(
          calc[op]
            ? calc[op](...stack.splice(-2))
            : op,
        );
      });

      return stack;
    }
    const [result, ...rest] = calculate(exp);
    if (rest.length) return null;
    return result;
  },
  replaceTemplateVariable(string, variableName, replacement) {
    const regex = new RegExp(`\\{\\s*${variableName}\\s*\\}`, 'g');
    return string.replace(regex, replacement);
  },
};
