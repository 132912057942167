<template>
  <question-wrapper
    ref="questionWrapper"
    :active="active"
    :required="required"
    :hideRequiredAsterisk="hideRequiredAsterisk"
    :label="safeQuestion.model && (safeQuestion.model.includes('tags') || safeQuestion.model.includes('deviceTags')) ? safeQuestion.label : this.$i18n.t(safeQuestion.label)"
    :labelInfo="this.$i18n.t(safeQuestion.labelInfo)"
    :sublabel="safeQuestion.sublabel"
    :fieldError="showFieldErrors ? safeQuestion.type === 'multi' ? getMultiErrors(subQuestions) : fieldErrors[name] : ''"
    :showNext="safeQuestion.showNext && !lastQuestion"
    :hideError="safeQuestion.hideError"
    :readonly="readonly"
    :isValid="isValid"
    :showLabel="safeQuestion.showLabel"
    :stepNumber="stepNumber"
    :number="question.number"
    :aria-live="question.ariaLive"
    :role="question.role"
    :labelFor="labelFor"
    :questionType="safeQuestion.type"
    @done="handleDone(true)"
  >
    <component
      ref="question"
      v-if="safeQuestion.type !== 'multi'"
      :is="getQuestionByType(safeQuestion.type)"
      :question="question"
      :label="safeQuestion.model && (safeQuestion.model.includes('tags') || safeQuestion.model.includes('deviceTags')) ? safeQuestion.label : this.$i18n.t(safeQuestion.label)"
      :name="name"
      :value="value"
      :stepValues="stepValues"
      :parentRefs="parentRefs"
      :readonly="readonly"
      :region="{ localization, isMultiRegional }"
      :active="active"
      :options="options"
      :id="componentLabelFor"
      v-validate="safeQuestion.validation"
      :data-vv-name="name"
      :data-vv-as="translateVvAs"
      @input="handleInput"
      @reset="handleReset"
      @focus="handleFocus"
      @blur="handleBlur"
      @done="handleDone"
      @keyPressEnter="handleEnterPress"
      :class="{ invalid: showFieldErrors && fieldErrors[name] }"
    />

    <question-multi
      ref="question"
      :active="active"
      @focus="handleFocus"
      @blur="handleBlur"
      v-if="safeQuestion.type === 'multi'"
    >
      <sub-question-wrapper
        v-for="(subq) in subQuestions"
        :key="subq.model"
        :active="active"
        :readonly="readonly"
        :label="subq.label"
        :class="{ invalid: showFieldErrors && fieldErrors[subq.name]}"
      >
        <component
          :is="getQuestionByType(subq.type)"
          :question="subq"
          :name="subq.name"
          :id="labelFor"
          :value="getSubValue(subq.model)"
          :stepValues="stepValues"
          :readonly="readonly"
          :region="{ localization, isMultiRegional }"
          :fieldError="showFieldErrors ? fieldErrors[subq.name] : ''"
          :active="getSubActive(subq, subQuestions) ? active : false"
          v-validate.continues="subq.validation"
          :data-vv-name="subq.name"
          @input="value => handleSubInput(subq.model, value)"
          @keyPressEnter="handleSubQuestionEnterPress(subQuestions)"
        />
      </sub-question-wrapper>
    </question-multi>
  </question-wrapper>
</template>

<script>
import _ from 'lodash';
import getQuestionByType, { QuestionWrapper, SubQuestionWrapper, QuestionMulti } from './index';
import scrollToElement from '../../lib/scrollToElement';
import defaultTheme from '../../styles/defaultTheme';

const scrollPercent = Number(process.env.SCROLL_PERCENT) || 20;

const questionDefaults = {
  hideError: false,
};

export default {
  inheritAttrs: true,
  name: 'question',
  inject: {
    program: 'program',
    customer: 'customer',
    theme: {
      default: defaultTheme,
    },
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideRequiredAsterisk: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    retry: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    stepValues: {
      required: true,
    },
    lastQuestion: Boolean,
    parentRefs: {
      type: Object,
    },
    stepNumber: {
      required: true,
    },
  },
  data() {
    return {
      localization: this.customer.localization,
      subQValue: {},
      startValidation: false,
    };
  },
  mounted() {
    /**
     * vee-validate only does validation on blur/update. In cases where
     * question is mounted with a value already set (mainly in testing),
     * validation will never trigger, so the parent step will think that
     * the answer is invalid. Doing a validation here if a value is passed
     * ensures that question.isValid will be set on mount.
     */
    if (this.question && this.question.validation && this.value) {
      this.validateMe();
    }
  },
  watch: {
    errors: {
      handler(errors) {
        this.$emit('validation');
      },
      deep: true,
    },
    active(newActive) {
      const logQuestionView = (question) => {
        let label = question.name || question.model;
        // will be logged elsewhere
        if (label === 'modal') {
          return null;
        }
        if (label === 'device') {
          label += `-${question.deviceType}`;
        }
        const { fullPath } = this.$route;
        const { stepNumber } = this;
        // don't use the quesiton number becasue multi questions don't have a number.
        return this.$gtag.pageview(`${fullPath}/step/${stepNumber}/question/${this.question.number}-${label}`);
      };
      if (newActive) {
        setTimeout(() => {
          if (this.question.type === 'multi') {
            return this.question.questions.forEach(logQuestionView);
          }
          return logQuestionView(this.question);
        }, 100);
      }

      if (newActive && this.$refs.question && this.$refs.question.focus) {
        this.$refs.question.focus();
      }

      if (newActive && this.value && !this.$refs.question.visited && this.$refs.question.focus) {
        this.$refs.question.visited = true;
        this.$refs.question.focus();
        this.handleInput(this.value);
      }
    },
    value: {
      handler(value) {
        if (this.safeQuestion.type !== 'multi') {
          this.subQValue = value;
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Returns the question component based on type
     */
    getQuestionByType(type) {
      return getQuestionByType(type);
    },
    /**
     * Get the name of a question, defaulting to the question model if none provided
     */
    getQuestionName(question) {
      return question.key || question.name || question.model;
    },
    /**
     * Handle 'done' click
     */
    async handleDone(startValidating) {
      if (startValidating) this.startValidation = true;
      if ((this.question.type === 'tstat-select' && !this.value) || this.question.type === 'multi') {
        this.validateMe();
      }
      const { valid } = await this.$validator.verify(this.value, this.question.validation || '');
      if (valid) {
        this.$emit('done');
      }
    },
    /**
     * Handle focus event
     */
    handleFocus() {
      if (this.question.type !== 'select' && this.question.type !== 'tstat-select') {
        this.$emit('activate');
      }
    },
    /**
     * Handle reset event
     */
    handleReset() {
      this.$emit('reset');
    },

    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
    },

    /**
     * Handle input from the main question
     */
    async handleInput(value) {
      if (this.question.name === 'First Name' && value.length < 2) {
        const { programSettings = [] } = this.program;
        const preformChecklist = programSettings.find(setting => setting.key === 'showPreformChecklist') || null;
        if (preformChecklist && preformChecklist.value === true) {
          this.$root.$emit('modal.show', 'checklist');
        }
      }
      this.$emit('input', value);
      const { valid } = await this.$validator.verify(value, this.question.validation || '');
      if (valid && this.question.advanceOnValue) {
        this.handleDone();
      }
      if (this.question.type === 'select' || this.question.type === 'tstat-select') {
        this.$emit('activate');
      }
    },
    /**
     * Handle Enter keystroke
     * Advances to the next question if `question.advanceOnEnter` is set to true
     */
    async handleEnterPress() {
      const { valid } = await this.$validator.verify(this.value, this.question.validation || '');
      if (valid && this.question.advanceOnEnter) {
        setTimeout(() => {
          const label = `step-${this.stepNumber}-question-${this.question.number}-${this.question.name || this.question.model}`;
          this.$gtag.event('next_enter', {
            event_category: 'engagement',
            event_label: label,
            value: (this.stepNumber * 100) + this.question.number,
          });
          return null;
        }, 100);
        this.handleDone();
      }
    },

    handleSubQuestionEnterPress(list) {
      if (this.getMultiErrors(list)) {
        return;
      }
      this.handleDone();
    },
    /**
     * Get the value of a subquestion from the question's 'value' prop
     */
    getSubValue(model) {
      return _.get(this.value, model, undefined);
    },
    getSubActive(q, list) {
      return list.indexOf(q) === 0;
    },
    getMultiErrors(list) {
      const fields = list.map(({ model }) => model);
      const errorFields = this.errors.items.map(({ field }) => field);
      if (!this.safeQuestion.hideCombinedError && errorFields.filter(ff => fields.indexOf(ff) !== -1).length) {
        return this.safeQuestion.errorMessage || 'There is an error.';
      }
      return null;
    },
    /**
     * Handle the input of a sub-question (for multi-type questions)
     */
    handleSubInput(model, value) {
      this.subQValue = _.set({ ...this.subQValue }, model, value);
      this.$emit('input', this.subQValue);
    },
    /**
     * Validate all inputs in this question
     */
    async validateMe() {
      const isValid = await this.$validator.validateAll();
    },
    /**
     * Scroll window to top of this question
     */
    async scrollToMe() {
      const el = this.$refs.questionWrapper;
      await scrollToElement(el, { percentFromTop: scrollPercent });
      return null;
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  asyncComputed: {
    async isValueValid() {
      const { valid } = await this.$validator.verify(this.value, this.question.validation || '');
      return valid;
    },
  },
  computed: {
    labelFor() {
      if (this.question.type === 'multi' && this.question.questions.length > 0) {
        // Make the label apply to the first question
        return `${this.question.questions[0].model}-question`;
      }
      return `${this.question.model}-question`;
    },
    componentLabelFor() {
      if (this.question.type === 'multi' && this.question.questions.length > 0) {
        // Make the label apply to the first question
        return `${this.question.questions[0].model}-question`;
      }
      if (this.question.model === 'tstatSelector') {
        return 'tstatSelector-container';
      }
      return `${this.question.model}-question`;
    },
    name() {
      return this.getQuestionName(this.question);
    },
    isValid: {
      // getter
      get() {
        if (!this.retry) return true;
        return Object.keys(this.fields).every(field => ({ ...this.fields[field] }.valid));
      },
      // setter
      set() {
        if (this && this.fields) {
          this.$validator.fields.items.forEach(field => field.reset());
        }
      },
    },
    isMultiRegional() {
      // for future implementations, Ex. Allow users to swap Phone# region, Ect.
      return this.customer.multiRegional || false;
    },
    fieldErrors() {
      return Object.entries(this.errors.collect()).reduce((acc, [name, errors]) => ({ ...acc, [name]: errors[0] }), {});
    },
    showFieldErrors() {
      return (this.startValidation || (!this.startValidation && this.retry));
    },
    safeQuestion() {
      const customValue = this.checkCustomLabel;
      if (customValue) {
        return { ...questionDefaults, ...this.question, label: customValue };
      }
      return { ...questionDefaults, ...this.question };
    },
    /**
     * Guarantees that there is a name property for the question
     */
    subQuestions() {
      if (this.question.type !== 'multi' || !this.question.questions) return [];

      return this.question.questions.map(q => ({ ...q, name: this.getQuestionName(q) }));
    },

    translateVvAs() {
      const translationKey = this.camelize(this.name);
      if (this.$i18n.te(translationKey)) {
        return this.$i18n.t(translationKey);
      }
      return this.name;
    },
    options() {
      return this.safeQuestion.options;
    },
    checkCustomLabel() {
      if (this.question.model === 'teslaev' && this.question.type === 'oauth') {
        const customLabelSetting = this.program.programSettings.find(setting => setting.key === 'teslaPreviewText');
        if (customLabelSetting) {
          return customLabelSetting.text;
        }
      }
      return null;
    },
  },
  components: { QuestionWrapper, SubQuestionWrapper, QuestionMulti },
};
</script>

<style lang="scss">
.multi-question {
  padding-left: 20px;
  label {
    font-size: 15px;
    // padding-left: 15px;
    text-indent: 0;
    margin-bottom: 0;
  }
}

div.invalid input, div.invalid input:focus, input.invalid, input:focus.invalid {
  border: 1px solid #CE3C31;
}

small.text-danger--custom {
  font-size: 12px;
  background-color: transparent;
  color: #CE4031;
}
</style>
