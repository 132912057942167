<template>
  <div>
    <label :for="id" v-html="localized.label" />
    <input
      class="form-control"
      type="text"
      :style="{ color: theme.primaryColor }"
      :name="name"
      :id="id"
      :readonly="readonly"
      :data-vv-name="name"
      v-validate="validation"
      :value="displayValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      :placeholder="localized.placeholder"
      ref="input"
      aria-label="Street Address Line 1"
      @keyup.down="handlePressDown"
      @keyup.up="handlePressUp"
      @keyup.enter="handleAddressEnter"
      @keydown.tab="closeSuggestions"
      @keyup.esc="closeSuggestions"
      @keyup.delete="handleAddressKeyup"
      @keypress="handleAddressKeyup"
      autocomplete="handled-by-other"
    />
    <small v-if="fieldError" class="form-text text-danger--custom">{{fieldError}}</small>
    <ul class="addressSuggestions" :size="suggestionSize" v-if="showSuggestions" v-click-outside="closeSuggestions">
      <li
        v-for="(suggestion, idx) in suggestions"
        :class="{highlight: idx === activeSuggestionIndex}"
        :key="suggestion.address.address_id"
        ref="addressSuggestions"
        v-on:click="setAddressClick(suggestion)"
      >
        <div class="addressCandidates">
          <div>{{ suggestion.text }}</div>
          <div v-if="suggestion.entries > 1" class="additionalEntries">
            {{ ` + ${suggestion.entries}` }}
            <VpIcon icon="chevron-right" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import AddressApiFactory from '../../services/api/address';
import defaultTheme from '../../styles/defaultTheme';
import { clickOutside } from '../../utilities/directives';

export default {
  name: 'question-address-street',
  directives: {
    clickOutside,
  },
  props: {
    active: Boolean,
    fieldError: String,
    name: String,
    id: String,
    region: Object,
    readonly: Boolean,
    value: String,
    minLength: {
      type: Number,
      default: 5,
    },
    suggestionSize: {
      type: Number,
      default: 6,
    },
    validation: String,
  },
  inject: {
    theme: { default: defaultTheme },
    customer: 'customer',
  },
  data() {
    return {
      suggestions: [],
      showSuggestions: false,
      updatedOtherFields: false,
      activeSuggestionIndex: -1,
      handleAddressKeyup: debounce(this.autocomplete, 1000),
    };
  },
  mounted() {
    if (this.active) {
      this.focus();
    }
    const { localization = 'US' } = this.region;
    this.addressApi = AddressApiFactory({ localization });
  },
  watch: {
    /**
     * If this question becomes active, focus the input
     */
    active(newActive, oldActive) {
      if (newActive && !oldActive) this.focus();
    },
  },
  methods: {
    // TODO: remove this if not used.
    async validateMe() {
      await this.$validator.validateAll();
    },
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    closeSuggestions() {
      this.showSuggestions = false;
      this.activeSuggestionIndex = -1;
      this.$refs.input.focus();
    },
    async setAddressClick(addressSuggestion) {
      const suggestion = this.suggestions.find(({ text }) => text === addressSuggestion.text);
      return this.handleEntry({ text: suggestion.text, address: suggestion.address, entries: suggestion.entries });
    },
    async handleAddressEnter() {
      if (this.showSuggestions && this.activeSuggestionIndex >= 0) {
        const { text, address, entries } = this.suggestions[this.activeSuggestionIndex];
        return this.handleEntry({ text, address, entries });
      }
      if (this.updatedOtherFields) {
        this.$emit('keyPressEnter');
      }
      const selection = this.selectedSuggestion;
      if (selection) {
        return this.handleEntry({ text: selection.text, address: selection.address, entries: selection.entries });
      }
      return null;
    },
    setAddressEnter() {
      this.arrowSelect = true;
      this.showSuggestions = false;
    },
    handlePressUp() {
      if (this.activeSuggestionIndex >= 0) {
        this.activeSuggestionIndex -= 1;
      }
    },
    handlePressDown() {
      if (!this.showSuggestions && this.suggestions.length) {
        this.showSuggestions = true;
        this.activeSuggestionIndex = 0;
      } else if (this.activeSuggestionIndex < this.suggestions.length - 1) {
        this.activeSuggestionIndex += 1;
      }
    },
    fuzzyParseAddress(selectedAddress, enteredText) {
      if (!selectedAddress) {
        return enteredText;
      }
      const [address, addressCityState] = selectedAddress.split(',');
      if (!addressCityState) {
        return { address };
      }
      const cityStateParts = addressCityState.split(' ').filter(i => i);
      const state = cityStateParts.pop();
      const city = cityStateParts.join(' ');
      return { address, city, state };
    },
    async handleEntry({ text, address = null, entries = null }) {
      const addressResponse = await this.addressApi.validate(text, address, entries);
      if (addressResponse.length > 1) {
        this.suggestions = addressResponse;
        this.showSuggestions = true;
        this.activeSuggestionIndex = -1;
        return;
      }
      this.closeSuggestions();
      const partial = (addressResponse.address) ? addressResponse : this.fuzzyParseAddress(text, address);
      if (!this.updatedOtherFields || partial.address) {
        const { city, state, zipcode } = partial;
        this.$root.$emit('address.streetExtra', (partial.address2) ? partial.address2 : '');
        this.$root.$emit('address.city', city || '');
        this.$root.$emit('address.state', state || '');
        this.$root.$emit('address.zipcode', zipcode || '');
      }
      this.updatedOtherFields = true;
      this.$emit('input', partial.address || text);
    },
    async autocomplete(evt) {
      if (this.value.length < this.minLength || evt.code === 'Enter') return;

      this.closeSuggestions();

      this.suggestions = await this.addressApi.autocomplete(this.value, this.suggestionSize);
      this.showSuggestions = Boolean(this.suggestions.length);
      this.activeSuggestionIndex = -1;
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  computed: {
    selectedSuggestion() {
      return (this.activeSuggestionIndex >= 0) ? this.suggestions[this.activeSuggestionIndex] : null;
    },
    displayValue() {
      return this.selectedSuggestion ? this.selectedSuggestion.text : this.value;
    },
    localized() {
      if (this.$i18n.locale === 'es') {
        return {
          placeholder: 'Dirección postal, línea 1',
          label: 'Dirección postal, línea 1',
        };
      }
      return {
        placeholder: 'Street Address 1',
        label: 'Street Address Line 1',
      };
    },
  },
};
</script>

<style scoped>
ul.addressSuggestions {
  height: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e8e8e8;
  overflow-y: scroll;
  position: absolute;
  z-index: 100;
  margin-top: 0px;
  left: 15px;
  right: 15px;
  border-radius: 6px;
  border-style: none;
}
.addressSuggestions li {
  display: block;
  font-size: 1rem;
  margin: 0;
  padding: 4px 10px;
}
.addressSuggestions li:hover, .addressSuggestions .highlight {
  background-color: #efefef;
  cursor: default;
}
.addressCandidates {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
}
  .additionalEntries {
    font-weight: bold;
    color: #016699;
  }

.address-details {
  width: 100%;
  overflow: auto;
}

.address-helper {
  color: #AFAFAF;
  font-size: 16px;
}

label {
 color:#4F4F4F;
 font-size:16px !important;
 margin-bottom:-10px !important;
 margin-left:10px;
 user-select: none;
}
</style>
