<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div v-html="safetyTerms" id="safety-terms"></div>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import vp from '../services/api/vp';

export default {
  name: 'terms',
  props: ['customerConfig'],
  data() {
    return {
      safetyTerms: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.byodProgram = this.customerConfig.programs.find(program => program.programName === `${this.$route.params.programId}`);
    vp.getTerms(this.byodProgram.termId)
      .then((text) => {
        this.safetyTerms = marked(text);
      });
  },
};

</script>

<style lang="scss">
@import '../styles/styles.scss';

#safety-terms {
  height: 600px;
  overflow-y: scroll;
  padding: 5%;
  margin: 20px 10px 10px 10px;
  border: solid 1px #e8e8e8;
}
#safety-terms ul {
  list-style-type: disc;
  padding-inline-start: 0px;
}
#safety-terms li {
  display: list-item;
  color: #717273;
}
#safety-terms ul ul{
  list-style-type: circle;
}
</style>
