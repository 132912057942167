<template>
  <div id="app" class="container">
    <VpSvgSprite />
    <nav-bar :selectedLanguage="selectedLanguage" :customerConfig="customerConfig" @updateLanguage="toggleLanguage"></nav-bar>
    <router-view></router-view>
    <footer-bar :customerConfig="customerConfig" v-if="customerConfig.byodFooter && customerConfig.byodFooter.included" ></footer-bar>
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue';
import FooterBar from './components/FooterBar.vue';
import storage from './services/storage';

const CustConfig = window.cConfig;

export default {
  name: 'app',
  data() {
    return {
      customerConfig: {},
      accountId: null,
      selectedLanguage: null,
      selectedProgram: null,
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.customerConfig = CustConfig;
  },
  components: {
    NavBar,
    FooterBar,
  },
  methods: {
    toggleLanguage(value) {
      this.selectedLanguage = value;
    },
  },
  watch: {
    $route(to, from) {
      const loadingHouseData = from.name === null;
      const { programId } = to.params;

      storage.set('selectedLanguage', this.selectedLanguage);
      if (programId !== undefined && !loadingHouseData) {
        storage.set('selectedProgram', programId);
        window.location.reload();
      }
    },
    language: {
      immediate: true,
      handler(newVal) {
        this.selectedLanguage = newVal;
      },
    },
  },
};
</script>

<style lang="scss">

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
</style>
