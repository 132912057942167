<template>
  <div v-if="value">
    <transition name="modal">
      <div class="modal-mask">
        <div ref="focusTrapStart" tabindex="0"></div>
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div
              class="modal-content"
              role="dialog"
              aria-labelledby="modal-title"
              aria-modal="true"
            >
              <div class="modal-header">
                <h5
                  id="modal-title"
                  v-html="title"
                  v-bind:style="{ color: providerColor }"
                ></h5>
                <button
                  v-if="showCloseButton"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="close dialog"
                  ref="modalCloseIcon"
                >
                  <i aria-hidden="true" @click="close" @keydown="closeByKeydown($event);" class="fa fa-times"></i>
                </button>
              </div>
              <div class="modal-body" ref="modalBody">
                <h5
                  id="modal-title-mobile"
                  v-html="title"
                  v-bind:style="{ color: providerColor }"
                ></h5>
                <slot />
              </div>
              <div ref="focusTrapEnd" tabindex="0"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: ['title', 'value', 'customerConfig', 'showCloseButton'],
  data() {
    return {
      providerColor: '',
      yAxis: 0,
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.$parent.$parent.userValues.step3 = { device: '', deviceType: '' };
      this.$parent.$parent.$refs.step3.scrollToMe();
    },

    closeByKeydown(evt) {
      if ((evt.key === 'Enter') || (evt.key === 'Space') || (evt.key === ' ')) {
        this.$emit('input', false);
        this.$parent.$parent.userValues.step3 = { device: '', deviceType: '' };
        this.$parent.$parent.$refs.step3.scrollToMe();
      }
    },
  },
  mounted() {
    if (this.customerConfig && this.customerConfig.color) {
      this.providerColor = this.customerConfig.color;
    }
  },
  updated() {
    if (this.value) {
      let focusable = [];
      this.$refs.focusTrapStart.addEventListener('focusin', () => {
        if (this.showCloseButton) {
          this.$refs.modalCloseIcon.focus();
        } else {
          focusable = this.$refs.modalBody.querySelectorAll('button, select, [href], input, [tabindex="0"], [tabindex="-1"]');
          if (focusable.length) {
            focusable[0].focus();
          }
        }
      });

      this.$refs.focusTrapEnd.addEventListener('focusin', () => {
        if (this.showCloseButton) {
          this.$refs.modalCloseIcon.focus();
        } else {
          focusable = this.$refs.modalBody.querySelectorAll('button, select, [href], input, [tabindex="0"], [tabindex="-1"]');
          if (focusable.length) {
            focusable[0].focus();
          }
        }
      });
      // Focus on first focusable element within body and buttons
      [this.$refs.modalBody].forEach((ref) => {
        focusable = focusable.concat(
          Array
            .from(ref.querySelectorAll('button, select, [href], input, [tabindex="0"], [tabindex="-1"]')) // Find elements
            .filter((el) => {
              if (el.x) { return false; } // remove svgs
              return true;
            }),
        );
      });
      if (focusable.length) {
        focusable[0].focus();
      } else {
        this.$refs.modalCloseIcon.focus();
      }
    }
  },
};
</script>

<style lang="scss">
@import '../../styles/mixins.scss';
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  overflow-y: auto;
  @include Mobile-Only {
    background-color: #ffffff;
  }
  @include Tablet {
    // New Design is padded from the top, not centered
    vertical-align: baseline;
  }
}

.modal-dialog {
  height: 100%;

  @include Mobile-Only {
    margin: 0;
  }

  @include Not-Mobile {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    max-width: 681px;
    margin: 1.75rem auto;
  }
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  @include Mobile-Only {
    margin: 0;
    height: 100%;
    border-radius: 0px;
    border: none;
  }
  @include Not-Mobile {
    max-height: 85vh;
    overflow-y: auto;
  }
}

.modal-header {
  min-height: 74px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px #0000000f;

  h5#modal-title {
    padding-left: 55px;
    font-size: 24px;
    line-height: 28px;
    padding-top: 4px;
  }

  @include Mobile-Only {
    height: 40px;
    background: #ffffff;
    box-shadow: none;
    border: none;

    h5#modal-title {
      display: none;
    }
  }
}

.modal-body {
  margin: 23px 75px 23px 75px;
  padding: 0;
  background-color: #ffffff;
  height: 100%;
  @include Mobile-Only {
    width: 100%;
  }
  @include Mobile-Only {
    padding-top: 4rem;
  }

  h5#modal-title-mobile {
    display: none;
  }

  p {
    margin: 16px 0 16px 0;
    font-size: 16px;
  }

  @include Mobile-Only {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    h5#modal-title-mobile {
      display: block;
      padding-left: 0;
      font-size: 24px;
      line-height: 28px;
      padding-top: 4px;
      padding-bottom: 8px;
      text-align: center;
    }

    p {
      text-align: center;
      margin: 32px;
    }
  }

  .row {
    width: 100%;
  }

  button {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 16px;
    min-width: 50%;
    margin: 32px 15px 16px 15px;

    @include Mobile-Only {
      width: 100%;
    }
  }

  a {
    font-size: 16px;
    text-decoration: underline;
  }
}

.modal-header .close {
  color: #afafaf;
  z-index: 100000;
  padding: 2rem;
  @include Not-Mobile {
    margin: 0;
    padding: 0;
  }

  &:hover {
    box-shadow: none;
  }
}
</style>
