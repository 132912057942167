/* eslint no-param-reassign: 0 */
/* eslint import/no-cycle: 0 */
/**
 * @todo This should be in the byod-config repo
 */
import _ from 'lodash';
import { defaultCustomer, defaultProgram, defaultAccount } from './defaults';
import i18n from '../../i18n';

export const getCustomer = (config) => {
  const customer = Object.assign({}, defaultCustomer, config);
  delete customer.programs;
  return customer;
};

export const getProgram = (config, name = null) => {
  let toCopy;
  if (i18n.locale === 'es') {
    const spanishPrograms = config.programs.filter(program => program.programName.includes('-es'));
    if (spanishPrograms && spanishPrograms.length) {
      toCopy = config.programs.find(({ programName }) => programName === name) || spanishPrograms[0];
    } else {
      toCopy = config.programs.find(({ programName }) => programName === name) || config.programs[0];
    }
  } else {
    toCopy = config.programs.find(({ programName }) => programName === name) || config.programs[0];
    if (toCopy && toCopy.programName.split('-').pop() === 'es') {
      i18n.locale = 'es';
    }
  }
  const program = Object.assign({}, defaultProgram, toCopy);
  // TODO: I may need to go through the device list as well with a deep copy
  program.programQuestions = []
    .concat(defaultProgram.programQuestions.map((item) => {
      const itemToCopy = toCopy.programQuestions.find(({ model }) => model === item.model);
      if (itemToCopy) {
        return Object.assign({}, item, itemToCopy);
      }
      return Object.assign({}, item);
    }))
    .concat(toCopy.programQuestions.filter(({ model }) => defaultProgram.programQuestions.find(item => item.model === model) == null)
      .map(item => Object.assign({}, item)));

  program.deviceTypes = Object.keys(program.devices || {});

  return program;
};

export function getInvalidMessages(customer, program) {
  const customerInvalids = (customer.commonQuestions || []).reduce((obj, { model, invalidMessage }) => {
    if (invalidMessage) {
      obj[model] = invalidMessage;
    }
    return obj;
  }, {});

  const programInvalids = program.programQuestions.reduce((obj, { model, invalidMessage }) => {
    if (invalidMessage) {
      obj[model] = invalidMessage;
    }
    return obj;
  }, {});
  return Object.assign({}, customerInvalids, programInvalids);
}
export function getExternalAnalyticsId(config, path) {
  if (!config.externalAnalytics) return false;
  return _.get(config, `externalAnalytics.${path}`, false);
}
