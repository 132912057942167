<template>
  <div aria-live="assertive" class="container">
    <div class="row justify-content-center text-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div v-bind:style="{color: providerColor}" class="success-circle">
          <i class="fa fa-check-circle"></i>
        </div>
        <h4>{{ successHeader || $t('byodSuccessPageHeadline') }}</h4>
        <p class="text-center">
          {{ successIntroText || $t('byodSuccessPageIntroText') }}
          <span v-if="!repeat">{{ successTextIfNoRepeat || $t('byodSuccessPageIntroNoRepeat', { utilityName: this.providerName }) }}</span>
        </p>
        <div class="row justify-content-center">
          <div class="col-lg-6" v-if="multiDevice">
            <button
              v-bind:style="{color: providerColor, borderColor: providerColor}"
              class="final-button outline-button question-button"
              v-on:click="enrollAnother()"
            >
              Enroll Another Device
            </button>
          </div>
          <div class="col-lg-6">
            <a :href="mainUrl" tabindex="-1">
              <button
                v-bind:style="{color: providerColor, borderColor: providerColor}"
                class="final-button outline-button question-button"
                v-on:click="buttonClick()"
              >
                {{ successBackToWebsiteLabel || $t('byodBackButton') }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultTheme from '../styles/defaultTheme';
import vp from '../services/api/vp';
import storage from '../services/storage';

export default {
  name: 'success',
  props: ['customerConfig', 'program', 'repeat', 'selectedLanguage'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      providerColor: '',
      providerName: '',
      successHeader: '',
      successIntroText: '',
      successTextIfNoRepeat: '',
      successBackToWebsiteLabel: '',
      mainUrl: '',
      multiDevice: false,
      first: true,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    buttonClick() {
      const { url } = ((this.customerConfig.commonSettings || []).find(({ key }) => key === 'byodMain') || { url: this.customerConfig.byodPath || 'https://www.virtual-peaker.com' });
      window.open(url, '_self');
    },
    enrollAnother() {
      storage.set('repeat', true);
      this.$router.push(`/signup/${this.program.programName}/add`);
    },
  },
  mounted() {
    const { locale } = this.$i18n;
    this.providerColor = this.customerConfig.color;
    this.providerName = this.customerConfig.name;
    this.mainUrl = ((this.customerConfig.commonSettings || []).find(({ key }) => key === 'byodMain') || { url: this.customerConfig.byodPath || 'https://www.virtual-peaker.com' }).url;
    if ((this.customerConfig.commonSettings || []).find(({ key }) => key === 'multiDevice')) {
      this.multiDevice = (this.customerConfig.commonSettings || []).find(({ key }) => key === 'multiDevice').value;
    }
    if ((this.program.programSettings || []).find(({ key }) => key === 'multiDevice')) {
      this.multiDevice = (this.program.programSettings || []).find(({ key }) => key === 'multiDevice').value;
    }
    this.successHeader = (this.program.programSettings.find(({ key }) => key === 'successHeader') || this.customerConfig.commonSettings.find(({ key }) => key === 'successHeader') || { text: null }).text;
    this.successIntroText = (this.program.programSettings.find(({ key }) => key === 'successIntroText') || this.customerConfig.commonSettings.find(({ key }) => key === 'successIntroText') || { text: null }).text;
    this.successTextIfNoRepeat = (this.program.programSettings.find(({ key }) => key === 'successTextIfNoRepeat') || this.customerConfig.commonSettings.find(({ key }) => key === 'successTextIfNoRepeat') || { text: null }).text;
    this.successBackToWebsiteLabel = (this.program.programSettings.find(({ key }) => key === 'successBackToWebsiteLabel') || this.customerConfig.commonSettings.find(({ key }) => key === 'successBackToWebsiteLabel') || { text: null }).text;
    vp.complete();
  },
};

</script>

<style lang="scss" scoped>
@import '../styles/styles.scss';
.success-circle {
  font-size: 100px;
  text-align: center;
}

button.question-button {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 16px;

  &:focus {
    outline: 2px solid #185abc;
  }
}
</style>
