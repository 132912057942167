import Device from '../Device';
import vp from '../../../services/api/vp';
import { battery } from './Common';

const questions = [{
  type: 'text',
  label: 'step3SunvergeLabel',
  labelInfo: 'step3SunvergeSublabel',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: false,
  model: 'uid',
  name: 'uid',
  validation: 'required',
}].concat(battery);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'sunverge',
    deviceType: 'battery',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Sunverge = new Device('sunverge', { mfg: 'sunverge', questions, addDevice });

export default Sunverge;
