/* eslint no-underscore-dangle: 0 */
class Device {
  // registry = {};
  static register(key, instance) {
    Device.registry[key] = instance;
  }

  static get(key) {
    const device = Device.registry[key];
    if (!device) {
      throw new Error(`Unknown device key '${key}'`);
    }

    return device;
  }

  static commonAddDeviceParameters(context) {
    const { account = {}, program = {}, customer = {} } = context;

    return {
      houseId: account.houseId,
      program: program.programName,
      providerId: customer.id,
    };
  }

  constructor(key, settings) {
    // if (typeof settings.addDevice !== 'function') {
    //   throw new Error(`[${this.constructor.name}] requires an 'addDevice' callback function`);
    // }
    if (!Array.isArray(settings.questions)) {
      throw new Error(`[${this.constructor.name}] requires a 'questions' array`);
    }

    this.key = key;
    this.mfg = settings.mfg;
    this.questions = settings.questions;
    this._addDevice = settings.addDevice;
    this._getApiRequest = settings.getApiRequest;

    Device.register(key, this);
  }

  getQuestions(customer, program) {
    this.questions.map((question) => {
      const questionName = question.type !== 'multi' ? question.name : question.questions[0].name;

      // merge the customer and programs questions
      const { commonQuestions = [] } = customer;
      const { programQuestions = [] } = program;
      const mergedQuestions = programQuestions.map((q) => {
        const common = (commonQuestions || []).find(({ model }) => model === q.model);
        if (common) {
          return Object.assign({}, common, q);
        }
        return q;
      })
        .concat((commonQuestions || []).filter(({ model }) => (programQuestions || []).find(q => q.model === model) == null));

      const selectedQuestion = mergedQuestions.find(item => item.model === questionName);
      if (question.type === 'tile-select') {
        return this.questions;
      }
      if (question.type === 'multi') {
        return this.questions;
      }
      if (question.type === 'modal' || question.type === 'paring-code') {
        return Object.assign(question, { included: true });
      }
      if (question.type === 'eguana' || question.type === 'duracell') {
        return this.questions;
      }
      return Object.assign(question, { included: true }, selectedQuestion);
    });
    return this.questions;
  }

  getApiRequest(values, context) {
    const { account, program, customer } = context;

    return (typeof this._getApiRequest === 'function')
      ? this._getApiRequest(values, context)
      : {
        ...account,
        ...program,
        ...customer,
        ...values,
      };
  }

  addDevice(...args) {
    return this._addDevice(...args);
  }

  hasAddDevice() {
    return this._addDevice != null;
  }
}
Device.registry = {};

export default Device;
