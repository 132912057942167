<template>
  <div class="question-radio">
    <vp-radio-buttons
        id="question-radio"
        ref="input"
        v-model="selected"
        :options="options"
        @input="selected => $emit('input', selected)"
      />
  </div>
</template>

<script>
export default {
  name: 'question-radio',
  props: {
    options: Array,
    value: String,
  },
  data() {
    return {
      selected: this.options[0].value,
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },
  methods: {
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
  },
};
</script>

<style scoped>
  .question-radio {
    margin-top: 20px;
  }
</style>
