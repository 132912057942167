import Device from '../Device';

const questions = [
  {
    type: 'modal',
    name: 'modal',
    label: 'step3EcobeeLabel',
    model: 'modalShow',
    modalName: 'ecobee',
    show: true,
    active: true,
    showNext: false,
    required: true,
  },
];

const Ecobee = new Device('ecobee', { mfg: 'ecobee', questions, addDevice: null });

export default Ecobee;
