import _ from 'lodash';

import { getQuestions } from '../Device';

export default function getStep3Questions(customer, program) {
  const { deviceTypes = [], devices = {} } = program;
  return [
    {
      type: 'device-type',
      deviceTypeKeys: deviceTypes,
      label: 'step3DeviceType',
      name: 'deviceType',
      model: 'deviceType',
      validation: 'required',
      ariaLive: 'polite',
      advanceOnEnter: true,
      advanceOnValue: true,
      hideError: true,
      show: true,
      included: deviceTypes.length > 1,
      showNext: false,
      update: (values, initialValues) => ({
        supportedDeviceTypes: deviceTypes,
      }),
    },
    {
      type: 'device',
      label: 'step3Device',
      name: 'device',
      model: 'device',
      validation: 'required',
      ariaLive: 'polite',
      advanceOnEnter: true,
      advanceOnValue: true,
      hideError: true,
      included: deviceTypes.length > 0,
      showNext: false,
      show: (values) => {
        if (deviceTypes.length === 1) {
          return true;
        }
        return Boolean(_.get(values, 'deviceType'));
      },
      update: (values, initialValues) => {
        if (deviceTypes.length === 1) {
          const deviceType = deviceTypes[0];
          return {
            deviceType,
            supportedDevices: devices[deviceType] || [],
          };
        }
        return {
          deviceType: _.get(values, 'deviceType'),
          supportedDevices: devices[_.get(values, 'deviceType')] || [],
        };
      },
    },
    {
      type: 'group',
      questions: (values) => {
        const device = _.get(values, 'device');
        let type = _.get(values, 'deviceType');
        if (!type) {
          [type] = deviceTypes;
        }
        if (device && type && devices[type].includes(device)) {
          return getQuestions(device, customer, program);
        }
        return [{
          name: 'blank',
          model: 'blank',
          show: false,
          included: true,
        }];
      },
    },
  ];
}
