export default function getStep2Questions() {
  return [{
    type: 'terms',
    label: 'step2Terms',
    model: 'acceptConditions',
    validation: 'required:true',
    ariaLive: 'polite',
    included: true,
    required: true,
    show: true,
  }];
}
