<template>
  <input
    class="form-control"
    type="text"
    :style="{ color: theme.primaryColor }"
    :name="name"
    :readonly="readonly"
    :value="value"
    @focus="handleFocus"
    @blur="handleBlur"
    @keyup.enter="handleEnterPress"
    @input="handleInput"
    ref="input"
  />
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-text',
  props: {
    name: String,
    readonly: Boolean,
    value: String,
    active: Boolean,
  },
  inject: {
    theme: { default: defaultTheme },
  },
  mounted() {
    if (this.active) {
      this.focus();
    }
  },
  watch: {
    /**
     * If this question becomes active, focus the imput
     */
    active(newActive, oldActive) {
      if (newActive && !oldActive) this.focus();
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
};
</script>
