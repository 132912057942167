import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [
  {
    type: 'modal',
    name: 'modal',
    label: 'step3EnelxLabel',
    model: 'modalShow',
    modalName: 'enelx',
    show: true,
    active: true,
    showNext: false,
    required: true,
  },
];

const EnelX = new Device('enelx', { mfg: 'enelx', questions, addDevice: null });

export default EnelX;
