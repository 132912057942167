<template>
  <div class="swiper-container">
    <div class="swiper-wrapper chargePoint-tiles">
      <div class="swiper-slide swiper-slide-active chargePoint-tiles--tile">
        <div class="chargePoint-tiles--tile--icon">
          <i class="icon-main fa fa-mobile-alt" v-bind:style="{...iconMainStyling}"></i>
          <i
            class="icon-sub fa fa-arrow-down"
            v-bind:style="{...iconSubStyling}"
          ></i>
        </div>
        <p class="chargePoint-tiles--tile--title">{{ desc[0].title }}</p>
        <p class="chargePoint-tiles--tile--desc">{{ desc[0].body }}</p>
      </div>
      <div class="swiper-slide chargePoint-tiles--tile">
        <div class="chargePoint-tiles--tile--icon">
          <i class="icon-main far fa-copy" v-bind:style="{...iconMainStyling}"></i>
          <i
            class="icon-sub fa fa-plus"
            v-bind:style="{...iconSubStyling}"
            ></i>
        </div>
        <p class="chargePoint-tiles--tile--title">{{ desc[1].title }}</p>
        <p class="chargePoint-tiles--tile--desc">{{ desc[1].body }}</p>
      </div>
      <div class="swiper-slide chargePoint-tiles--tile">
        <div class="chargePoint-tiles--tile--icon">
          <i class="icon-main far fa-envelope-open" v-bind:style="{...iconMainStyling}"></i>
          <i
            class="icon-sub fa fa-check"
            v-bind:style="{...iconSubStyling}"
          ></i>
        </div>
        <p class="chargePoint-tiles--tile--title">{{ desc[2].title }}</p>
        <p class="chargePoint-tiles--tile--desc">{{ desc[2].body }}</p>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<style lang="scss">
@import '../../styles/mixins.scss';

.swiper-container {
  width: 100%;
}
.swiper-pagination {
  position: relative;
  margin-top: 1rem;

  @include Mobile-Only--small {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include Not-Mobile {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-bullet {
    background-color: transparent;
    border: 1px solid;
    box-sizing: border-box;
    margin: 4px;
  }
}

.chargePoint-tiles {
  width: auto;
  margin-bottom: 1rem;

  .chargePoint-tiles--tile {
    background-color: $WHITE;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: auto;

    @include Mobile-Only {
      border-radius: 10px;
      min-height: 180px;
    }

    .chargePoint-tiles--tile--desc {
      text-align: center;
      font-size: 12px;
      font-style: normal;
      color: #4f4f4f;
      padding: 0 23px;
      line-height: 18px;

      @include Mobile-Only {
        font-size: 13px;
      }
      @include Mobile-Only--no-height {
        margin: 1rem 0;
      }
    }
    .chargePoint-tiles--tile--title {
      margin-top: 20px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #4f4f4f;
    }

    .chargePoint-tiles--tile--icon {
      display: block;
      height: 4rem;

      .icon-main {
        position: relative;
        left: 35%;
        top: 20px;
        color: #a6b1c3;
        font-size: 3rem; //45px
        @include InternetExplorer {
          position: absolute;
          left: 40%;
          @include Mobile-Only {
            left: 44%;
          }
        }
        @include Mobile-Only {
          top: 18px;
          left: 40%;
        }
      }

      .icon-sub {
        border: .35rem solid;
        border-radius: 50%;
        position: relative;
        font-size: .75rem;
        color: white;
        top: 25px;
        left: 25%;
        @include Mobile-Only {
          border-width: .45rem;
          top: 21px;
          left: 33%;
        }
        @include Desktop {
          left: 23%;
        }

        @include InternetExplorer {
          position: absolute;
          top: 3rem;
          left: 50%;
          @include Tablet {
            left: 55%;
          }
        }
        &.fa-arrow-down {
          top: 25px;
          left: 28%;
          @include Mobile-Only {
            top: 24px;
            left: 35%;
          }
          @include InternetExplorer {
            top: 3rem;
            left: 48%;
            @include Tablet {
              left: 52%;
            }
          }
        }
        &.fa-check {
          left: 22%;
          @include Mobile-Only {
            left: 31%;
            top: 25px;
          }
          @include InternetExplorer {
            top: 3rem;
            left: 52%;
            @include Tablet {
              left: 58%;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import defaultTheme from '../../styles/defaultTheme';

const viewLarge = '(min-width: 768px)';
const viewSmall = '(min-width: 577px) and (max-width: 767px)';
const viewMobile = '(max-width: 576px)';
Swiper.use([Pagination]);

export default {
  name: 'Swiper',
  props: ['desc'],
  inject: {
    theme: {
      default: defaultTheme,
    },
  },
  data() {
    return {
      iconSubStyling: { borderColor: defaultTheme.primaryColor, background: defaultTheme.primaryColor },
      iconMainStyling: { color: defaultTheme.primaryColor, opacity: 0.5 },
      currentView: '',
    };
  },
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      initialSlide: 0,
      spaceBetween: 30,
      slidesPerView: 1.6,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        577: {
          centeredSlides: false,
          slidesPerView: 2,
        },
        768: {
          centeredSlides: false,
          slidesPerView: 3,
          watchOverflow: true,
        },
      },
    });
    this.togglePaginationColor();
    mySwiper.on('slideChange', this.togglePaginationColor);
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (window.matchMedia(viewLarge).matches && this.currentView !== viewLarge) {
        this.currentView = viewLarge;
      } else if (window.matchMedia(viewSmall).matches && this.currentView !== viewSmall) {
        this.currentView = viewSmall;
      } else if (window.matchMedia(viewMobile).matches && this.currentView !== viewMobile) {
        this.currentView = viewMobile;
      }
    },
    async togglePaginationColor(list) {
      const elements = [...document.getElementsByClassName('swiper-pagination-bullet')];
      elements.forEach((el, index) => {
        if (el.classList.contains('swiper-pagination-bullet-active')) {
          elements[index].style.backgroundColor = defaultTheme.primaryColor;
        } else {
          elements[index].style.backgroundColor = 'transparent';
        }
        elements[index].style.borderColor = defaultTheme.primaryColor;
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    [...document.getElementsByClassName('swiper-pagination-bullet')].forEach(element => element.removeEventListener('click', this.togglePaginationColor));
  },
  computed: {},
};
</script>
