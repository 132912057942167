import './DeviceQuestionSets/Aquanta';
import './DeviceQuestionSets/ChargePoint';
import './DeviceQuestionSets/Ecobee';
import './DeviceQuestionSets/Flo';
import './DeviceQuestionSets/Eradio';
import './DeviceQuestionSets/Emporia';
import './DeviceQuestionSets/Enphase';
import './DeviceQuestionSets/EvoCharge';
import './DeviceQuestionSets/GE';
import './DeviceQuestionSets/Flair';
import './DeviceQuestionSets/Sonnen';
import './DeviceQuestionSets/SolarEdge';
import './DeviceQuestionSets/Pika';
import './DeviceQuestionSets/Sunverge';
import './DeviceQuestionSets/Honeywell';
import './DeviceQuestionSets/HoneywellAcc';
import './DeviceQuestionSets/Nest';
import './DeviceQuestionSets/EnelX';
import './DeviceQuestionSets/Sensibo';
import './DeviceQuestionSets/Juicenet';
import './DeviceQuestionSets/Tesla';
import './DeviceQuestionSets/Eaton';
import './DeviceQuestionSets/Smartcar';
import './DeviceQuestionSets/TrickleStar';
import './DeviceQuestionSets/Stash';
import './DeviceQuestionSets/Sensi';
import './DeviceQuestionSets/Mysa';
import './DeviceQuestionSets/Wallbox';

import './DeviceQuestionSets/Eguana';
import './DeviceQuestionSets/Duracell';

import './DeviceQuestionSets/AOSmith';
import './DeviceQuestionSets/American';
import './DeviceQuestionSets/Reliance';
import './DeviceQuestionSets/State';
import './DeviceQuestionSets/Lochinvar';
import './DeviceQuestionSets/USCraftmaster';

import './DeviceQuestionSets/Rheem';
import './DeviceQuestionSets/Ruud';

import './DeviceQuestionSets/VPEvse';
import './DeviceQuestionSets/VPBattery';
import './DeviceQuestionSets/VPTesla';
import './DeviceQuestionSets/VPRivian';
import './DeviceQuestionSets/VPHyundai';

import './DeviceQuestionSets/FranklinWH';
import './DeviceQuestionSets/TeslaEv';

import Device from './Device';

export default Device;

export function getQuestions(key, customer, program) {
  return Device.get(key).getQuestions(customer, program);
}

export function getApiRequest(key, values, context) {
  return Device.get(key).getApiRequest(values, context);
}
