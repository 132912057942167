import { render, staticRenderFns } from "./QuestionSim.vue?vue&type=template&id=47c1d46f&"
import script from "./QuestionSim.vue?vue&type=script&lang=js&"
export * from "./QuestionSim.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports