import Device from '../Device';
import vp from '../../../services/api/vp';
import { battery } from './Common';

const questions = [{
  type: 'text',
  label: 'step3PikaGeneracLabel',
  labelInfo: 'step3PikaGeneracSublabel',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: false,
  model: 'serialNumber',
  name: 'serialNumber',
  validation: 'required',
}].concat(battery);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'pika',
    deviceType: 'battery',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Pika = new Device('pika', { mfg: 'pika', questions, addDevice });
export default Pika;
