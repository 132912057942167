<template>
<div>
  <label v-html="this.$i18n.t(label)" />
  <slot />
  <small v-if="fieldError" class="form-text text-danger">{{fieldError}}</small>
</div>
</template>

<script>
export default {
  name: 'sub-question-wrapper',
  props: {
    active: Boolean,
    label: String,
    fieldError: String,
  },
};
</script>
