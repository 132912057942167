import _ from 'lodash';
import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  model: 'sensibo',
  type: 'oauth',
  name: 'sensibo',
  label: 'step3OauthDeviceSensibo',
  active: true,
  showNext: false,
  included: true,
  update: values => ({
    additionalFields: {
      type: 'sensibo',
      tags: JSON.stringify(_.get(values, 'tags') || {}),
    },
  }),
}];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Sensibo = new Device('sensibo', { mfg: 'sensibo', questions, addDevice });

export default Sensibo;
