import _ from 'lodash';
import Device from '../Device';
import { hwh } from './Common';

const questions = hwh.concat([{
  model: 'tricklestar',
  type: 'oauth',
  name: 'tricklestar',
  label: 'Clicking the button below will redirect you to the TrickleStar login page. After you log in with the credentials you previously used to create an account with TrickleStar, we can automatically add your device.',
  showNext: false,
  required: true,
  advanceOnEnter: true,
  advanceOnValue: true,
  update: values => ({
    additionalFields: {
      type: 'gravity',
      mfg: 'tricklestar',
      tags: JSON.stringify(_.get(values, 'tags') || {}),
    },
  }),
}]);

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const TrickleStar = new Device('tricklestar', { mfg: 'tricklestar', questions, addDevice });

export default TrickleStar;
