import QuestionText from './QuestionText.vue';
import QuestionSelect from './QuestionSelect.vue';
import QuestionRadio from './QuestionRadio.vue';
import QuestionCaptcha from './QuestionCaptcha.vue';
import QuestionTermsAndConditions from './QuestionTermsAndConditions.vue';
import QuestionDeviceType from './QuestionDeviceType.vue';
import QuestionDevice from './QuestionDevice.vue';
import QuestionDeviceTerms from './QuestionDeviceTerms.vue';
import QuestionMixingValve from './QuestionMixingValve.vue';
import QuestionLabel from './QuestionLabel.vue';
import QuestionMulti from './QuestionMulti.vue';
import QuestionPairingCode from './QuestionPairingCode.vue';
import QuestionGravityPairingCode from './QuestionGravityPairingCode.vue';
import QuestionAoSmithPairingCode from './QuestionAoSmithPairingCode.vue';
import QuestionOauth from './QuestionOauth.vue';
import QuestionAddressStreet from './QuestionAddressStreet.vue';
import QuestionAddressStreetExtra from './QuestionAddressStreetExtra.vue';
import QuestionAddressCity from './QuestionAddressCity.vue';
import QuestionAddressState from './QuestionAddressState.vue';
import QuestionAddressZipcode from './QuestionAddressZipcode.vue';
import QuestionPhone from './QuestionPhone.vue';
import TileSelect from './TileSelector.vue';
import QuestionTstatSelect from './QuestionTstatSelect.vue';
import QuestionModal from './QuestionModal.vue';
import QuestionSim from './QuestionSim.vue';
import QuestionFranklinWHPairingCode from './QuestionFranklinWHPairingCode.vue';
import QuestionButton from './QuestionButton.vue';

export { default as QuestionWrapper } from './QuestionWrapper.vue';
export { default as SubQuestionWrapper } from './SubQuestionWrapper.vue';
export { default as QuestionMulti } from './QuestionMulti.vue';

export default function selectQuestionComponent(type) {
  switch (type) {
    case 'address-street':
      return QuestionAddressStreet;
    case 'sim':
      return QuestionSim;
    case 'address-street-extra':
      return QuestionAddressStreetExtra;
    case 'address-city':
      return QuestionAddressCity;
    case 'address-state':
      return QuestionAddressState;
    case 'address-zipcode':
      return QuestionAddressZipcode;
    case 'captcha':
      return QuestionCaptcha;
    case 'terms':
      return QuestionTermsAndConditions;
    case 'device-type':
      return QuestionDeviceType;
    case 'device':
      return QuestionDevice;
    case 'device-terms':
      return QuestionDeviceTerms;
    case 'mixing-valve':
      return QuestionMixingValve;
    case 'label':
      return QuestionLabel;
    case 'pairing-code':
      return QuestionPairingCode;
    case 'franklinwh-pairing-code':
      return QuestionFranklinWHPairingCode;
    case 'gravity-pairing-code':
      return QuestionGravityPairingCode;
    case 'ao-smith-pairing-code':
      return QuestionAoSmithPairingCode;
    case 'oauth':
      return QuestionOauth;
    case 'multi':
      return QuestionMulti;
    case 'radio':
      return QuestionRadio;
    case 'select':
      return QuestionSelect;
    case 'tile-select':
      return TileSelect;
    case 'phone':
      return QuestionPhone;
    case 'tstat-select':
      return QuestionTstatSelect;
    case 'modal':
      return QuestionModal;
    case 'button-clicked':
      return QuestionButton;
    case 'text':
    default:
      return QuestionText;
  }
}
