<template>
  <Modal v-model="passThroughValue" :title="this.$i18n.t('modalEcobee.headline')" :customerConfig="customerConfig" :showCloseButton="showCloseButton" class="ecobee-modal">
    <div tabindex="-1">
      <p style="margin-top: 16px">
        {{ $t('modalEcobee.introduction') }}
      </p>
      <div class="row ecobee-tiles">
        <div class="col-md col-sm-12 ecobee-tiles--tile text-center">
          <div
            class="ecobee-tiles--tile--icon"
            style="position: relative; top: 15px;"
          >
          <img class="icon-main" id="device" src="../../assets/icons/mobile.svg" alt="">
          <div class="icon-sub">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle v-bind:style="{ fill: theme.primaryColor }" id="Ellipse 6" cx="16.98" cy="16.98" r="16.98" fill="#016699"/>
                <image x="7" y="6" href="../../assets/icons/house-icon.svg" />
              </svg>
            </div>
          </div>
          <p class="ecobee-tiles--tile--desc">
            {{ $t('modalEcobee.step1') }}
          </p>
        </div>
        <div class="col-md col-sm-12 ecobee-tiles--tile text-center">
          <div
            class="ecobee-tiles--tile--icon"
            style="position: relative; top: 15px;"
          >
            <img class="icon-main" src="../../assets/icons/house.svg" alt="">
            <div class="icon-sub">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle v-bind:style="{ fill: theme.primaryColor }" id="Ellipse 6" cx="16.98" cy="16.98" r="16.98" fill="#016699"/>
                <image x="10" y="10" href="../../assets/icons/cursor-icon.svg" />
              </svg>
            </div>
          </div>
          <p class="ecobee-tiles--tile--desc">
            {{ $t('modalEcobee.step2') }}
          </p>
        </div>
        <div class="col-md col-sm-12 ecobee-tiles--tile text-center">
          <div
            class="ecobee-tiles--tile--icon"
            style="position: relative; top: 15px;"
          >
            <img class="icon-main" id="file" src="../../assets/icons/file.svg" alt="">
            <div class="icon-sub">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle v-bind:style="{ fill: theme.primaryColor }" id="Ellipse 6" cx="16.98" cy="16.98" r="16.98" fill="#016699"/>
                <image x="10" y="5" href="../../assets/icons/lightning-bolt.svg" />
              </svg>
            </div>
          </div>
          <p class="ecobee-tiles--tile--desc">
            {{ $t('modalEcobee.step3') }}
          </p>
        </div>
      </div>
      <div class="row completion-instructions">
        <div class="col">
          <p>
            {{ $t('modalEcobee.ending') }}
          </p>
          <button
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            ref="oppenRhrUrl"
            v-on:click="saveAndRedirect();"
          >
          {{ $t('modalEcobee.button') }}
          </button>
        </div>
      </div>
      <p class="text-center">
        {{ $t('modalEcobee.supportEmail') }}
        <a
          v-bind:style="{ color: theme.primaryColor }"
          v-bind:href="'mailto:' + supportEmail"
        >
          {{ supportEmail }}
        </a>
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'modal-ecobee',
  props: ['value', 'opts', 'customerConfig'],
  inject: {
    theme: { default: defaultTheme },
  },
  data() {
    return {
      title: 'Ecobee Thermostat User',
      supportEmail: 'support@virtual-peaker.com',
      passThroughValue: false,
      showCloseButton: true,
    };
  },
  watch: {
    value() {
      this.passThroughValue = this.value;
    },
    passThroughValue() {
      this.$emit('input', this.passThroughValue);
    },
  },
  mounted() {
    this.supportEmail = this.customerConfig.supportEmail;
    this.passThroughValue = this.value;
  },
  methods: {
    saveAndRedirect() {
      this.$router.push({ path: '/success', params: { type: 'ecobee' } });
    },
  },
  components: { Modal },
};

</script>

<style lang="scss">

.ecobee-modal {

  p {
    margin: 9px 0 9px 0;
  }

  @media (max-width: 576px) {
    p {
      margin: 9px 16px;
    }
  }

  .row.completion-instructions {
    text-align: center;
    width: auto;

    @media (max-width: 576px) {
      padding: 0 24px;

      p {
        @media (max-width: 576px) {
          display: none;
        }
      }

    }

    .col {
      button {
        @media (max-width: 576px) {
          margin: 8px 0;
          width: 100%;
        }
      }
    }

  }

  .row.ecobee-tiles {
    padding: 0 5px;
    width: auto;

    div.ecobee-tiles--tile {
      background-color: white;
      margin: 10px;
      padding: 10px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      @media (max-width: 576px) {
        margin: 4px 32px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }

      p.ecobee-tiles--tile--desc {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        color: #4F4F4F;
        padding-right: 7px;
        padding-left: 7px;

        @media (max-width: 576px) {
          margin-top: 5px;
          margin-left: 80px;
          margin-right: 5px;
          text-align: left;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .ecobee-tiles--tile--icon {
        display: inline-block;
        position: relative;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 576px) {
          display: block;
          float: left;

          #device {
            margin-left: 10px;
          }
          #file {
            margin-left: 10px;
          }
        }

        .icon-main {
          color: #A6B1C3;
          display: inline-block;
        }

        #device {
            width: 40px;
          }

        .icon-sub {
          position: relative;
          display: block;
          right: -30px;
          top: -20px;
        }
      }
    }
  }
}
</style>
