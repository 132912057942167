import _ from 'lodash';
import Device from '../Device';

const questions = [{
  model: 'stash',
  type: 'oauth',
  name: 'stash',
  label: 'Clicking the button below will redirect you to the Stash login page. After you log in with the credentials you previously used to create an account with Stash, we can automatically add your device.',
  showNext: false,
  required: true,
  advanceOnEnter: true,
  advanceOnValue: true,
  update: values => ({
    additionalFields: {
      type: 'gravity',
      mfg: 'stash',
      tags: JSON.stringify(_.get(values, 'tags') || {}),
    },
  }),
}];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Stash = new Device('stash', { mfg: 'stash', questions, addDevice });

export default Stash;
