<template>
  <div id="valve-question">
    <p class="explanation">{{ $t('step3MixingValveSubquestion.text') }}<a :href="termsURL" :style="{ color: theme.primaryColor }" target='_blank'>{{ $t('step3MixingValveSubquestion.link') }}</a>.</p>
    <vp-checkbox
      :value="value"
      class="option"
      :styles="{ size: 16, color: theme.primaryColor }"
      @input="checked => $emit('input', checked)"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template #label>
        {{ $t('step3MixingValveCheckbox.text') }} <a :href="termsLink" :style="{color: theme.primaryColor}" target='_blank'>{{ $t('step3MixingValveCheckbox.link') }}</a>.
      </template>
    </vp-checkbox>
  </div>
</template>

<style lang="scss">
@import '../../styles/styles.scss';

#valve-question {
  font-size: 1em;
  .explanation {
    font-size: 0.8em;
    margin-bottom: 8px;
  }
  label {
    margin-bottom: 24px;
  }
  a {
    font-size: 1em;
  }
}
</style>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-text',
  props: {
    name: String,
    readonly: Boolean,
    value: Boolean,
  },
  inject: {
    program: 'program',
    customer: 'customer',
    theme: { default: defaultTheme },
  },
  data() {
    let providerTermsLink;
    let providerTermsURL;
    // merge the customer and programs questions
    const { commonQuestions = [] } = this.customer;
    const { programQuestions = [] } = this.program;
    const mergedQuestions = programQuestions.map((q) => {
      const common = commonQuestions.find(({ model }) => model === q.model);
      if (common) {
        return Object.assign({}, common, q);
      }
      return q;
    })
      .concat(commonQuestions.filter(({ model }) => programQuestions.find(q => q.model === model) == null));

    const settings = mergedQuestions.find(({ model }) => model === 'mixingValve');
    if (settings && settings.termsLink) {
      providerTermsLink = settings.termsLink;
    } else {
      providerTermsLink = `/#/signup/${this.program.programName}/terms`;
    }
    if (settings && settings.url) {
      providerTermsURL = settings.url;
    } else {
      providerTermsURL = 'http://support.virtualpeaker.io';
    }
    return {
      termsLink: providerTermsLink,
      termsURL: providerTermsURL,
    };
  },
  methods: {
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  mounted() {
    this.$emit('input', false);
    this.programName = this.$route.params.programId;
  },
};
</script>
