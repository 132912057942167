<template>
  <div class="tile-selector">
    <div
      v-if="tip"
      class="row justify-content-center tile-selector--tip"
    >
      <div
        class="col-3 col-sm-3 tile-selector--tip--title"
        :style="{ backgroundColor: theme.primaryColor }"
      >
        <div>
          <i class="fa fa-exclamation-circle fa-inverse"></i> <br/>
          {{ $t('step3HoneywellPage.tip.title') }}
        </div>
      </div>
      <div
        class="col-9 col-sm-9 tile-selector--tip--body"
        :style="{ borderColor: theme.primaryColor }"
      >
        <p>
          {{ $t('step3HoneywellPage.tip.text') }}
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-lg-6 device-tile-col"
        v-for="(item, idx) in normalizedItems"
        :key="idx"
      >
        <div
          tabindex="0"
          :id="`tile_question-${question.number}-value-${item.value}`"
          v-on:click="handleSelect(item.value)"
          v-on:keyup.enter="handleSelect(item.value)"
          :class="{'device-tile': true, 'tile-selected': item.value === value}"
          :style="{color: theme.primaryColor, borderColor: item.value === value ? theme.primaryColor : '#d6dadd'}"
        >
          <div class="device-tile-content">
            <i
              v-if="item.icon"
              class="fa device-type-icon"
              v-bind:class="item.icon"
              aria-hidden="true"
            />

            <svg
              v-else-if="item.svgSrc"
              :alt="item.alt"
              :style="{fill: theme.primaryColor}">
            <use :xlink:href="`${item.svgSrc}#${item.svgId}`"></use>
            </svg>

            <img
              v-else-if="item.imgSrc"
              :src="item.imgSrc"
              :alt="item.alt" />

            <h4 v-if="item.displayTitle">{{ item.displayTitle }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 42px">
      <p
        v-if="help"
        class="tile-selector--help"
      >
        <a :href="helpLink" target="_blank" :style="{ color: theme.primaryColor }">{{ $t('step3HoneywellPage.helpLink') }}</a>
      </p>
    </div>
    <div class="row"
    v-for="(item, idx) in normalizedItems"
        :key="idx">
      <p
        v-if="item.disclaimer"
        class="tile-selector--disclaimer"
      >{{ item.disclaimer }}</p>
    </div>
  </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'tile-selector',
  props: {
    question: {
      type: Object,
      validator: prop => prop.items && prop.items.every(e => String(e) === '[object Object]' && e.value != null),
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readonly: Boolean,
  },
  inject: {
    theme: { default: defaultTheme },
    customer: 'customer',
    program: 'program',
  },
  data() {
    return {
      helpLink: (this.customer.helpLink) || (this.customer.commonSettings.find(({ key }) => key === 'byodHelp') || { url: 'https://support.virtualpeaker.io' }).url,
    };
  },
  computed: {
    tip() {
      return (this.question.tip ? this.question.tip : false);
    },
    help() {
      return (this.question.help ? this.question.help : false);
    },
    disclaimer() {
      return (this.question.disclaimer ? this.question.disclaimer : false);
    },
    normalizedItems() {
      if (!this.question.items) return [];

      return this.question.items.map(i => ({
        ...i,
        displayTitle: this.getDisplayTitle(i),
      }));
    },
  },
  methods: {
    getDisplayTitle(item) {
      if (item.name) return item.name;
      if (!item.icon && !item.imgSrc && !item.svgSrc) return item.value;
      return null;
    },
    handleSelect(value) {
      if (!this.readonly) {
        setTimeout(() => {
          const label = `${this.question.name || this.question.model}_${value}`;
          this.$gtag.event('select_tile', {
            event_category: 'engagement',
            event_label: label,
            value: this.question.number,
          });
        }, 100);
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/styles.scss';

.tile-selector {
  .row {
    padding-left: 10px;
  }

  .tile-selector--tip {
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
    @media (max-width: 576px) {
      margin-right: 0;
    }

    .tile-selector--tip--title {
      background-color: $charcoal-grey-text;
      text-transform: uppercase;
      color: white;
      border-radius: 6px 0 0 6px;
      position: relative;

      div {
        font-size: 14px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .fa {
          font-size: 30px;
          color: white;
        }
      }
    }

    .tile-selector--tip--body {
      border-radius: 0 6px 6px 0;
      border: 2px solid $charcoal-grey-text;

      p {
        margin: 18px 20px 18px 33px;
        font-size: 16px;
        color: #4F4F4F;
      }

      @media (max-width: 576px) {
        p {
          margin: 9px 14px 9px 13px;
          font-size: 13px;
        }
      }

    }
  }
  .tile-selector--help {
    margin-top: 8px;
    text-align: center;
    @media (max-width: 576px) {
      margin-right: 32px;
    }
    a {
      text-decoration: underline;
    }
  }
  .tile-selector--disclaimer {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    color: #AFAFAF;
  }
}

.device-tile-col{
  padding: 15px;

  @media (max-width: 576px) {
    width: 100%;
    padding: 0;
  }

}
.device-tile{
  width: 263.4px;
  height: 263.6px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 #dae0e3;
  border-style: solid;
  border-width: 1px;
  border-color: #d6dadd;
  margin: 10px auto 10px auto;
  display: table-cell;
  vertical-align: middle;
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: unset;
    min-height: 130px;
    padding: 0;
    margin: 4px auto 4px auto;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #4F4F4F;
  }
}
.device-tile-content {
  width: 263.4px;
  text-align: center;
  margin:auto;
  height: 100%;
  display: table-cell;
  @media (max-width: 576px) {
    height: unset;
    margin-top: 16px;
    margin-bottom: 10px;
  }
}
.device-tile-content img {
  width: 80%;
  max-height: 240px;
  @media (max-width: 576px) {
    width: unset;
    max-width: 80%;
    max-height: 100px;
  }
}

.device-tile:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.device-type-icon {
  font-size: 3em !important;
}
.tile-selected {
  border-width: 2px;
}
.device-tile-content {
  svg {
    width: 48px;
    height: 48px;
    @media (min-width: 768px) {
      width: 54.5px;
      height: 54.5px;
    }
    @media (min-width: 992px) {
      width: 60px;
      height: 60px;
    }
  }
}

</style>
