<template>
  <div class="question-device-terms">
    <vp-checkbox
      id="acceptTerms"
      :value="value"
      class="option"
      :labelFirst="true"
      :styles="{ size: 16, color: theme.primaryColor }"
      :disabled="disableCheckbox"
      @input="checked => $emit('input', checked)"
    >
      <template #label>
        {{ $t(`deviceTerms`)}}:
        <a v-if="termsLink.length" :href="termsLink" target="_blank" rel="noreferrer">{{ termsLink }}</a>
        <span v-else>Terms & Conditions Unavailable</span>
      </template>
    </vp-checkbox>
  </div>
</template>

<script>
import vp from '../../services/api/vp';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-device-terms',
  props: {
    stepValues: Object,
    value: {},
    readonly: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: String,
  },
  data() {
    return {
      selected: null,
      termsLink: '',
    };
  },
  computed: {
    mfg() {
      return this.stepValues.device;
    },
    disableCheckbox() {
      return (this.readonly || !this.termsLink.length);
    },
  },
  inject: {
    provider: 'customer',
    theme: { default: defaultTheme },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue === true) {
        const timeStampTagName = `${this.mfg}_terms_timestamp`;
        const acceptedTime = new Date().toISOString();
        this.updateTag(timeStampTagName, acceptedTime);
      } else {
        delete this.stepValues.tags.acceptedTermsTimestamp;
      }
    },
  },
  methods: {
    updateTag(key, value) {
      if (this.stepValues.tags) {
        this.stepValues.tags[key] = value;
      } else {
        this.stepValues.tags = { [key]: value };
      }
    },
  },
  async mounted() {
    try {
      this.termsLink = await vp.getDeviceTerms(this.provider.id, this.mfg);
    } catch (err) {
      this.termsLink = '';
    }
  },
};
</script>

<style scoped>
</style>
