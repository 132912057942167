import Device from '../Device';

const questions = [
  {
    model: 'honeywellAcc',
    type: 'oauth',
    name: 'honeywellAcc',
    label: 'step3OauthDeviceAmazon',
    additionalFields: {
      type: 'honeywellAcc',
    },
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const HoneywellAcc = new Device('honeywellAcc', { mfg: 'honeywellAcc', questions, addDevice });

export default HoneywellAcc;
