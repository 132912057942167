import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  model: 'vprivian',
  type: 'sim',
  name: 'vprivian',
}];

const addDevice = async (values, context) => {
  const deviceData = {
    ...values,
    mfg: 'vp-sim',
    deviceType: 'ev',
    kind: 'ev',
    type: 'RIVIAN',
  };
  return vp.addDevice(deviceData);
};

const VPRivian = new Device('vprivian', { mfg: 'vp-sim', questions, addDevice });

export default VPRivian;
