<template>
  <step-wrapper
    ref="stepContainer"
    :active="active"
    :number="number"
    :title="title"
    :introduction="introduction"
    :locked="locked"
    :allQuestionsValid="allQuestionsValid"
    :error="error"
    :onLast="onLast"
    :showNextStep="showNextStep"
    :nextStepText="nextStepText"
    @next="handleNextClick"
    class='step-questions'
    :retry="retry"
  >
    <question
      v-for="(q, idx) in normalizedQuestions"
      ref="questions"
      :index="idx"
      :parentRefs="$refs"
      :key="q.model"
      :type="q.type"
      :required="q.required"
      :hideRequiredAsterisk="q.hideRequiredAsterisk"
      :sublabel="q.sublabel"
      :stepNumber="number"
      :question="q"
      :active="idx === activeQuestionIndex"
      :value="getQuestionValue(q.model)"
      :stepValues="currentValues"
      :readonly="locked"
      :retry="retry"
      v-show="showQuestion[idx]"
      :lastQuestion="idx === lastQuestionIndex"
      @input="value => handleInput(q.model, value)"
      @reset="handleReset"
      @validation="handleQuestionValidation"
      @activate="handleQuestionActivate(idx)"
      @done="handleQuestionDone(idx)"
    />
  </step-wrapper>
</template>

<script>
import _ from 'lodash';
import Question from '../Question/Question.vue';
import StepWrapper from './StepWrapper.vue';
import scrollToElement from '../../lib/scrollToElement';

const scrollPercent = Number(process.env.SCROLL_PERCENT) || 20;

export default {
  name: 'step',
  props: {
    /** Indicates if this is the final step in the list. If so, don't show the Next Step */
    showNextStep: {
      type: Boolean,
      required: false,
      default: true,
    },
    nextStepText: {
      type: String,
      required: false,
      default: 'Next Step',
    },
    /** The array of questions to render.  See docs for more info */
    questions: {
      type: Array,
      required: true,
    },
    /**
     * Must be an object
     */
    value: {
      required: true,
      validator(value) {
        return String(value) === '[object Object]';
      },
    },
    /** Where to start numbering questions */
    numberingStart: {
      type: Number,
      default: 1,
    },
    /** The Step number to display */
    number: Number,
    /** The Step title to display */
    title: String,
    /** The Step introduction text to display */
    introduction: String,
    /**
     * There is no logic in this component to ensure that only a single Step
     * can be active at a time.  It is the responsibility of the parent component
     * to ensure that.
     */
    active: {
      type: Boolean,
      default: true,
    },
    /**
     * Affects the rendering of the wrapper (namely the 'next' button) and sets
     * the `readonly` prop of questions.  It is the responsibility of the question
     * component to implement readonly functionality
     */
    locked: {
      type: Boolean,
      default: false,
    },
    error: String,
  },
  inject: ['initialValues', 'program', 'customer'],
  data() {
    return {
      /** Index of the question considered active */
      activeQuestionIndex: null,
      /** Indicates when all questions are valid */
      allQuestionsValid: false,
      /** How far the step is offset from its parent container top */
      stepOffsetTop: 0,
      /** The index of the question considered "in position". Updated by handleScroll */
      inPositionQuestionIndex: -1,
      /** Semaphore for controlling enabling/disabling handleScroll */
      autoScrollInProgress: false, // Used to distinguish between user driven and programmatic scrolling
      /** Indicates whether Next Step button has been clicked once already to retry and fix input errors */
      retry: false,
    };
  },

  beforeMount() {
    Object.keys(this.initialValues || {}).forEach((key) => {
      const q = this.questions.find(({ model }) => model === key);
      if (q) {
        this.value[key] = this.initialValues[key];
      }

      if (key === 'devices') {
        const { devices } = this.initialValues;
        const deviceType = this.questions.find(({ model }) => model === 'deviceType');
        const device = this.questions.find(({ model }) => model === 'device');
        if (deviceType && device) {
          const d = devices.pop();
          this.value.deviceType = Object.keys(this.program.devices).find(k => this.program.devices[k].indexOf(d) !== -1);
          this.value.device = d;
        }
      }
    });
  },

  mounted() {
    this.checkValidity();

    this.stepOffsetTop = this.$refs.stepContainer.$el.offsetTop;

    this.$nextTick(() => {
      const activeIndex = Number(window.sessionStorage.getItem('activeQuestionIndex')) || null;
      if (activeIndex) {
        this.activateQuestion(activeIndex);
        this.scrollToQuestion(activeIndex);
      } else if (!activeIndex && this.activeState) {
        this.activate();
      }
    });

    this.$nextTick(() => {
      if (this.$route.meta.initialValues && this.number !== 3) {
        return;
      }
      const values = JSON.parse(window.sessionStorage.getItem('questionValues')) || null;
      if (values) {
        this.$emit('input', values);
      } else if (!values && !this.value) {
        this.$emit('input', {});
      }
    });
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route(to, from) {
      const multipleLanguages = this.customer.commonSettings.find(({ key }) => key === 'byodMultipleLanguages');
      if (multipleLanguages) {
        window.sessionStorage.setItem('questionValues', JSON.stringify(this.currentValues));
        window.sessionStorage.setItem('activeQuestionIndex', this.activeQuestionIndex);
        window.sessionStorage.setItem('currentStep', this.number);
      }
    },
  },
  methods: {
    /**
     * This is the scrollspy which activates questions as they scroll in to position.
     */
    handleScroll() {
      if (!this.active || this.autoScrollInProgress) return;

      /** Fun Future Refactor: pass these in as a prop */
      const isVisibleOffset = window.innerHeight * 0.4;
      const inPositionOffset = window.innerHeight * (scrollPercent / 100);

      const rect = this.$refs.stepContainer.$el.getBoundingClientRect();
      const inPositionLine = inPositionOffset + window.pageYOffset;
      const allInPos = this.$refs.questions
        .sort((a, b) => a.$attrs.index - b.$attrs.index)
        /** Which questions are above the "inPosition" line */
        .map((q) => {
          const ans = q.$el.offsetTop < (inPositionLine + isVisibleOffset) && (q.$el.offsetTop + q.$el.offsetHeight) > inPositionLine;
          return ans;
        })
        /** Make a list of the indices of those questions above the "inPosition" line */
        .reduce((acc, isInPos, idx) => isInPos ? acc.concat(idx) : acc, []);
      const inPositionQuestionIndex = allInPos.length ? Math.min(...allInPos) : -1;

      if (
        inPositionQuestionIndex >= 0
        && inPositionQuestionIndex !== this.inPositionQuestionIndex
        // &&
        // this.isQuestionVisible(inPositionQuestionIndex, isVisibleOffset)
      ) {
        this.inPositionQuestionIndex = inPositionQuestionIndex;
        this.activateQuestion(inPositionQuestionIndex);
      }
    },
    /**
     * This only tests if a question is below the top of the view window and does
     * not care if the question has scrolled below the bottom of the window.
     * An element is not considered visible until it's top position is below `isVisibleOffset`
     * from the top of the window.
     */
    isQuestionVisible(questionIndex, isVisibleOffset = 0) {
      return this.$refs.questions[questionIndex].$el.getBoundingClientRect().top >= isVisibleOffset;
    },
    /**
     * Extracts the question value from the step value
     *
     * If the question has a `model` property, get the property
     * from 'currentValues'.  If not, return the entire currentValues
     * object. (this is useful for multi questions)
     */
    getQuestionValue(model) {
      const savedQuestionValues = JSON.parse(window.sessionStorage.getItem('questionValues')) || null;
      const [prefixString, suffixString] = model.split('.');

      if (savedQuestionValues && Object.keys(savedQuestionValues).includes(prefixString) && Object.keys(savedQuestionValues[prefixString]).includes(suffixString)) {
        return model ? _.get(savedQuestionValues, model, undefined) : this.savedQuestionValues;
      }
      return model ? _.get(this.currentValues, model, undefined) : this.currentValues;
    },
    /**
     * If a path is returned, set the value to the path on the currentValues.
     * Otherwise, if the value is an object, merge the object with currentValues.
     * Otherwise, throw and error.
     */
    handleInput(path, value) {
      if (path === 'device') {
        this.activateQuestion(0);
      }
      let newValueObj;
      if (path) {
        newValueObj = _.set({ ...this.currentValues }, path, value);
      } else if (_.isPlainObject(value)) {
        newValueObj = { ...this.currentValues, ...value };
      } else {
        throw new Error(`unsupported value type '${typeof value}' without path`);
      }

      this.$emit('input', newValueObj);
    },
    /**
     * Fires when the user clicks 'next'
     */
    async handleNextClick() {
      this.retry = true;
      this.$nextTick(async () => {
        this.$refs.questions.forEach((q) => {
          q.validateMe();
        });
        this.checkValidity();
        if (this.allQuestionsValid) {
          this.$emit('next');
          return;
        }
        const index = this.$refs.questions.findIndex((q) => !q.isValid);
        if (index !== -1) {
          await this.scrollToQuestion(index);
        } else {
          await this.scrollToMe();
        }
      });
    },
    /**
     * Handle reset event
     */
    handleReset() {
      this.$emit('reset');
      this.$refs.questions.forEach((q, index) => {
        if (this.$refs.questions[index].name !== 'deviceType') {
          this.$refs.questions[index].isValid = false;
        }
      });
    },
    /**
     * Handler for question validation event
     */
    handleQuestionValidation() {
      if (!this.retry) return;
      this.checkValidity();
    },
    /**
     * Iteratively checks the `isValid` property of each rendered question
     * and sets the internal `allQuestionsValid` flag as appropriate.
     * `allQuestionsValid` is used to conditionally render 'next' button
     */
    checkValidity() {
      if (!this.$refs.questions || !this.$refs.questions.length) {
        this.allQuestionsValid = true;
      } else {
        this.allQuestionsValid = this.$refs.questions.every((q) => {
          // Excludes Modal validation for visibility
          if (['pairingCode', 'gravityPairingCode', 'aoSmithPairingCode', 'franklinWHPairingCode'].includes(q.name)) {
            return true;
          }
          return q.isValid;
        });
      }
    },
    /**
     * Sets the step to an active state
     */
    activate() {
      this.scrollToMe();
      this.activateQuestion(0);
    },
    /**
     * Sets the given question to an active state
     */
    activateQuestion(questionIndex) {
      if (!this.normalizedQuestions || !this.normalizedQuestions.length) return;
      this.activeQuestionIndex = questionIndex;
    },
    /**
     * Handles the 'active' event from a quesiton
     * Some questions will request to become active (such as when they gain focus)
     */
    handleQuestionActivate(questionIndex) {
      if (questionIndex !== this.activeQuestionIndex) {
        this.activateQuestion(questionIndex);
        this.scrollToQuestion(questionIndex);
      }
    },
    /**
     * Handles 'done' event from a question
     */
    handleQuestionDone(questionIndex) {
      if (questionIndex !== this.lastQuestionIndex) {
        const nextQuestion = this.normalizedQuestions.find(({ show }, idx) => (idx > questionIndex && show));
        let nextIndex = questionIndex;
        if (nextQuestion) {
          nextIndex = this.normalizedQuestions.indexOf(nextQuestion);
        }
        if (nextIndex - questionIndex > 1 || nextIndex === questionIndex) {
          for (let i = questionIndex - 1; i >= 0; i -= 1) {
            const question = this.$refs.questions[i];
            if (question && question.required && !question.startValidation) {
              nextIndex = i;
              question.handleDone(true);
              break;
            }
          }
        }
        this.handleQuestionActivate(nextIndex);
      }
    },
    /**
     * Scrolls the window to the top of the given question
     */
    async scrollToQuestion(questionIndex) {
      this.autoScrollInProgress = true;
      try {
        const question = (this.$refs.questions.length <= questionIndex)
          ? this.$refs.questions[this.$refs.questions.length - 1]
          : this.$refs.questions.find((item) => {
            return this.normalizedQuestions[questionIndex].number === item.question.number;
          });
        await question.scrollToMe();
      } finally {
        this.autoScrollInProgress = false;
      }
    },
    /**
     * Scrolls the window to the top of this step
     */
    async scrollToMe() {
      this.autoScrollInProgress = true;
      try {
        const el = this.$refs.stepContainer;
        await scrollToElement(el, { offsetFromTop: 110 });
      } finally {
        this.autoScrollInProgress = false;
      }
    },
    /**
     * Resolves a question's `show` property and determines if a questions should be
     * rendered based on those results
     */
    getQuestionShowState(q) {
      if (q.show == null) return true;
      if (typeof q.show === 'function') return q.show(this.currentValues, this);
      return q.show;
    },
    getQuestionIncludedState(q) {
      if (typeof q.included === 'function') return q.included(this.currentValues, this);
      return q.included;
    },
    getQuestionCalculatedLabel(q) {
      if (typeof q.label === 'function') return q.label(this.currentValues, this);
      return q.label;
    },
    getQuestionOptionsUpdate(q) {
      if (typeof q.options === 'function') return q.options(this.currentValues, this);
      return q.options;
    },
    /**
     * Resolves a questions' `update` property and merges the results in to the
     * question properties
     */
    getQuestionUpdates(q) {
      const updates = (typeof q.update === 'function')
        ? q.update(this.currentValues, this.initialValues)
        : {};
      return (String(updates) === '[object Object]') ? updates : {};
    },
    /**
     * For 'group' type questions, resolves the `questions` property
     */
    explodeQuestion(q) {
      if (q.type !== 'group') return q;
      if (typeof q.questions === 'function') return q.questions(this.currentValues);
      if (Array.isArray(q.questions)) return q.questions;
      return [];
    },
  },
  computed: {
    onLast() {
      return this.lastQuestionIndex === this.activeQuestionIndex;
    },
    showQuestion() {
      return this.normalizedQuestions
        .map(q => q.show)
        .reduce((prev, next) => {
          if (prev.length === 0) return [next];
          const last = prev.slice(-1)[0];
          return prev.concat(last && next);
        }, []);
    },
    /**
     * A list of question objects based on the `questions` property, normalized for rendering
     * - Adds 'group' questions to the list of questions
     * - resolves all calculated fields
     * - Applies defaults
     * - removes hidden questions
     * - Applies auto-numbering
     */
    normalizedQuestions() {
      let currentNumber = this.numberingStart;

      const questions = (this.questions || [])
        .reduce((acc, q) => acc.concat(this.explodeQuestion(q)), [])
        .filter(q => this.getQuestionIncludedState(q))
        .map((q, idx) => {
          const show = this.getQuestionShowState(q);
          const updates = this.getQuestionUpdates(q);
          const label = this.getQuestionCalculatedLabel(q);
          const options = this.getQuestionOptionsUpdate(q);
          const active = (typeof q.active === 'boolean') ? q.active : idx === this.activeQuestionIndex;
          const showNext = (typeof q.showNext === 'boolean') ? q.showNext : true;
          const showLabel = q.type === 'label' ? false : q.showLabel;

          let { number } = q;
          if (typeof number !== 'number' && !q.disableNumbering) {
            number = currentNumber;
            currentNumber += 1;
          }

          return {
            ...q,
            ...updates,
            label,
            options,
            show,
            active,
            showLabel,
            showNext,
            number,
          };
        });
      return questions;
    },
    /**
     * Number of questions that were auto-numbered during rendering
     */
    numberedQuestionCount() {
      return (this.normalizedQuestions || [])
        .filter(q => this.getQuestionShowState(q))
        .filter(q => !q.disableNumbering)
        .length;
    },
    /**
     * Index of last question actually rendered
     */
    lastQuestionIndex() {
      // const lastShown = this.normalizedQuestions.reduce((step, next, k) => next.show ? k : step, 0);
      return this.normalizedQuestions.length - 1;
      // (this.normalizedQuestions || []).length - 1;
    },
    /**
     * Number of last question actually rendered
     */
    lastQuestionNumber() {
      return this.numberingStart + this.numberedQuestionCount + (-1);
    },
    /**
     * Is the step active and has questions?
     */
    activeState() {
      return this.active && this.questions && this.questions.length;
    },
    /**
     * Current set of values.
     * current element
     */
    currentValues() {
      return this.value;
    },
  },
  components: { StepWrapper, Question },
};
</script>
