import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  type: 'pairing-code',
  name: 'pairingCode',
  model: 'pairingCode',
  label: 'step3ChargepointPairingCode',
  show: (values, parent) => Boolean(parent.allQuestionsValid),
  active: true,
  mfg: 'chargepoint',
  validation: 'required',
}];

const addDevice = async (values, context) => null;

const chargePoint = new Device('chargepoint', { mfg: 'chargepoint', questions, addDevice });

export default chargePoint;
