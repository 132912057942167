import Device from '../Device';

const questions = [
  {
    model: 'eaton',
    type: 'oauth',
    name: 'eaton',
    label: 'step3OauthDeviceEaton',
    additionalFields: {
      type: 'eaton',
    },
  },
];

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const Eaton = new Device('eaton', { mfg: 'eaton', questions, addDevice });

export default Eaton;
