import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [
  {
    type: 'multi',
    label: 'step3JuicenetLabel',
    show: true,
    active: true,
    showNext: false,
    required: true,
    advanceOnEnter: true,
    advanceOnValue: true,
    included: true,
    questions: [
      {
        type: 'text',
        label: 'step3JuicenetDeviceId',
        model: 'juicenetDeviceId',
        name: 'serialNumber',
        advanceOnEnter: true,
        validation: 'required',
      },
      {
        type: 'text',
        label: 'step3JuicenetPin',
        model: 'pin',
        advanceOnEnter: true,
        validation: 'required',
      },
    ],
  },
];

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'juicenet',
    deviceType: 'evse',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Juicenet = new Device('juicenet', { mfg: 'juicenet', questions, addDevice });

export default Juicenet;
