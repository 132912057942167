// @ts-check
/*
  DefaultValidationMessageOverride.js

  Contains logic meant for overriding default VeeValidate validation messages.

  Not to be confused with custom validation logic, which is defined in a separate file.

  Designed for use with VeeValidate v2.

  - References:
    https://vee-validate.logaretm.com/v2/guide/messages.html#overwriting-messages
*/

/**
 * An interpretive reimplementation of a function used internally in VeeValidate default validation message definitions.
 * Tweaked to play nice with our eslint setup and the ts-check flag.
 *
 * https://github.com/logaretm/vee-validate/blob/6ffca35b92297eff85390475ce6fb0aacb224da0/locale/utils.js#L6-L12
 *
 * @param {number} size
 * @returns
 */
const formatFileSize = (size) => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const threshold = 1024;
  size = Number(size) * threshold; // eslint-disable-line no-param-reassign
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));
  return `${Number((size / Math.pow(threshold, i)).toFixed(2)) * 1} ${units[i]}`; // eslint-disable-line no-restricted-properties, prefer-exponentiation-operator
};

const DEFAULT_VALIDATION_MESSAGE_OVERRIDES_BY_LANGUAGE = {
  /* eslint-disable quotes */
  en: {
    // Originals: https://github.com/logaretm/vee-validate/blob/879b96d5478b9292c15ecf489315c88b29377c88/locale/en.js#L4-L44
    _default: (field) => `This value is not valid`,
    after: (field, [target, inclusion]) => `This value must be after ${inclusion ? 'or equal to ' : ''}${target}`,
    alpha: (field) => `This field may only contain alphabetic characters`,
    alpha_dash: (field) => `This field may contain alpha-numeric characters as well as dashes and underscores`,
    alpha_num: (field) => `This field may only contain alpha-numeric characters`,
    alpha_spaces: (field) => `This field may only contain alphabetic characters as well as spaces`,
    before: (field, [target, inclusion]) => `This value must be before ${inclusion ? 'or equal to ' : ''}${target}`,
    between: (field, [min, max]) => `This field must be between ${min} and ${max}`,
    confirmed: (field) => `The confirmation does not match`,
    credit_card: (field) => `This field is invalid`,
    date_between: (field, [min, max]) => `This value must be between ${min} and ${max}`,
    date_format: (field, [format]) => `This value must be in the format ${format}`,
    decimal: (field, [decimals = '*'] = []) => `This field must be numeric and may contain${!decimals || decimals === '*' ? '' : ' ' + decimals} decimal points`, // eslint-disable-line prefer-template
    digits: (field, [length]) => `This field must be numeric and contains exactly ${length} digits`,
    dimensions: (field, [width, height]) => `This field must be ${width} pixels by ${height} pixels`,
    email: (field) => `This field must be a valid email`,
    excluded: (field) => `This field must be a valid value`,
    ext: (field) => `This field must be a valid file`,
    image: (field) => `This field must be an image`,
    included: (field) => `This field must be a valid value`,
    integer: (field) => `This field must be an integer`,
    ip: (field) => `This field must be a valid ip address`,
    ip_or_fqdn: (field) => `This field must be a valid ip address or FQDN`,
    length: (field, [length, max]) => {
      if (max) {
        return `This value's length must be between ${length} and ${max}`;
      }

      return `This value's length must be ${length}`;
    },
    max: (field, [length]) => `This field may not be greater than ${length} characters`,
    max_value: (field, [max]) => `This field must be ${max} or less`,
    mimes: (field) => `This field must have a valid file type`,
    min: (field, [length]) => `This field must be at least ${length} characters`,
    min_value: (field, [min]) => `This field must be ${min} or more`,
    numeric: (field) => `This field may only contain numeric characters`,
    regex: (field) => `This field format is invalid`,
    required: (field) => `This field is required`,
    required_if: (field, [target]) => `This field is required when the ${target} field has this value`,
    size: (field, [size]) => `This value's size must be less than ${formatFileSize(size)}`,
    url: (field) => `This field is not a valid URL`,
  },
  es: {
    // Originals: https://github.com/logaretm/vee-validate/blob/879b96d5478b9292c15ecf489315c88b29377c88/locale/es.js#L4-L42
    _default: (field) => `El campo no es válido`,
    after: (field, [target, inclusion]) => `El campo debe ser posterior ${inclusion ? 'o igual ' : ''}a ${target}`,
    alpha: (field) => `El campo solo debe contener letras`,
    alpha_dash: (field) => `El campo solo debe contener letras, números y guiones`,
    alpha_num: (field) => `El campo solo debe contener letras y números`,
    alpha_spaces: (field) => `El campo solo debe contener letras y espacios`,
    before: (field, [target, inclusion]) => `El campo debe ser anterior ${inclusion ? 'o igual ' : ''}a ${target}`,
    between: (field, [min, max]) => `El campo debe estar entre ${min} y ${max}`,
    confirmed: (field) => `El campo no coincide`,
    credit_card: (field) => `El campo es inválido`,
    date_between: (field, [min, max]) => `El campo debe estar entre ${min} y ${max}`,
    date_format: (field, [format]) => `El campo debe tener un formato ${format}`,
    decimal: (field, [decimals = '*'] = []) => `El campo debe ser numérico y contener${!decimals || decimals === '*' ? '' : ' ' + decimals} puntos decimales`, // eslint-disable-line prefer-template
    digits: (field, [length]) => `El campo debe ser numérico y contener exactamente ${length} dígitos`,
    dimensions: (field, [width, height]) => `El campo debe ser de ${width} píxeles por ${height} píxeles`,
    email: (field) => `El campo debe ser un correo electrónico válido`,
    excluded: (field) => `El campo debe ser un valor válido`,
    ext: (field) => `El campo debe ser un archivo válido`,
    image: (field) => `El campo debe ser una imagen`,
    included: (field) => `El campo debe ser un valor válido`,
    integer: (field) => `El campo debe ser un entero`,
    ip: (field) => `El campo debe ser una dirección ip válida`,
    length: (field, [length, max]) => {
      if (max) {
        return `El largo del campo debe estar entre ${length} y ${max}`;
      }

      return `El largo del campo debe ser ${length}`;
    },
    max: (field, [length]) => `El campo no debe ser mayor a ${length} caracteres`,
    max_value: (field, [max]) => `El campo debe de ser ${max} o menor`,
    mimes: (field) => `El campo debe ser un tipo de archivo válido`,
    min: (field, [length]) => `El campo debe tener al menos ${length} caracteres`,
    min_value: (field, [min]) => `El campo debe ser ${min} o superior`,
    numeric: (field) => `El campo debe contener solo caracteres numéricos`,
    regex: (field) => `El formato del campo no es válido`,
    required: (field) => `El campo es obligatorio`,
    size: (field, [size]) => `El campo debe ser menor a ${formatFileSize(size)}`,
    url: (field) => `El campo no es una URL válida`,
  },
  /* eslint-enable quotes */
};

/**
 * A factory that returns a function that can override default vee-validate validation messages
 * @param {string} language
 */
function OverrideDefaultValidationMessageFactory(language = '') {
  let targetOverrides;

  if (language && language in DEFAULT_VALIDATION_MESSAGE_OVERRIDES_BY_LANGUAGE) {
    targetOverrides = DEFAULT_VALIDATION_MESSAGE_OVERRIDES_BY_LANGUAGE[language];
  } else {
    console.warn(`Could not find default validation message overrides for '${language}'; ignoring...`);
  }

  /**
   * A function that overrides default validation message functions with preferred ones
   * @param {DefaultValidationMessageObject} defaultMessages
   */
  return (defaultMessages) => {
    /*
      Anti-conditions
    */
    if (!targetOverrides) {
      return defaultMessages;
    }

    if (
      !defaultMessages
      || defaultMessages == null
      || typeof defaultMessages !== 'object'
    ) {
      console.warn('Invalid defaultMessages object; ignoring...');
      return defaultMessages;
    }

    if (
      !('messages' in defaultMessages)
      || !defaultMessages.messages
    ) {
      console.warn('Unexpected shape for defaultMessages object; ignoring...');
      return defaultMessages;
    }

    /*
      Perform the override
    */
    const overridenMessages = { ...defaultMessages };

    Object.keys(targetOverrides).forEach((messageKey) => {
      if (messageKey in overridenMessages.messages) {
        delete overridenMessages.messages[messageKey];
      }
      overridenMessages.messages[messageKey] = targetOverrides[messageKey];
    });

    return overridenMessages;
  };
}

export {
  OverrideDefaultValidationMessageFactory,
};

/**
 * The default validation message object as imported from VeeValidate
 * @typedef {Object} DefaultValidationMessageObject
 * @property {Object} attributes
 * @property {Object} messages
 * @property {string} name
 */
