<template>
  <div>
    <label for="address-street2" v-html="localized.label" />
    <input
      id="address-street2"
      class="form-control address-details__extra"
      type="text"
      :style="{ color: theme.primaryColor }"
      :name="name"
      :readonly="readonly"
      :value="displayValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup.enter="handleEnterPress"
      @input="handleInput"
      ref="input"
      v-validate="validation"
      aria-label="Street Address Line 2"
      :data-vv-name="name"
      :placeholder="localized.placeholder"
    />
  </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-address-street-extra',
  props: {
    active: Boolean,
    name: String,
    region: Object,
    readonly: Boolean,
    value: String,
    validation: String,
  },
  inject: {
    theme: { default: defaultTheme },
  },
  data: () => ({ extra: '' }),
  mounted() {
    if (this.active) this.focus();

    this.$root.$on('address.streetExtra', value => this.$emit('input', value));

    this.$root.$on('address.show', (show) => {
      this.show = show;
    });
  },
  watch: {
    /**
     * If this question becomes active, focus the input
     */
    active(newActive, oldActive) {
      if (newActive && !oldActive) this.focus();
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleEnterPress(...args) {
      this.$emit('keyPressEnter', ...args);
    },
    handleInput(evt) {
      this.$emit('input', evt.target.value);
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  computed: {
    displayValue() {
      return this.value;
    },
    localized() {
      if (this.$i18n.locale === 'es') {
        return {
          placeholder: 'Dirección postal, línea 2',
          label: 'Dirección postal, línea 2 (opcional)',
        };
      }
      return {
        placeholder: 'Street Address 2',
        label: 'Street Address Line 2. This field is optional',
      };
    },
  },
};
</script>

<style scoped>
.address-details__extra {
  width: 100%;
  overflow: auto;
}
.address-helper {
  color: #4F4F4F;
  font-size: 16px;
}

label {
 color:#4F4F4F;
 font-size:16px !important;
 margin-bottom:-10px !important;
 margin-left:10px;
 user-select: none;
}
</style>
