<template>
    <div>
      <button
        :style="{backgroundColor: theme.primaryColor}"
        @click="handleClick"
        class="question-button"
        tabindex="-1"
        :disabled="!isValid"
        :id="`button_next-question_step-${stepNumber}-question-${questionNumber}`"
      >{{ $t('byodNextButton') }}</button>
      <span
        class="next-question-text"
        :style="{color: theme.primaryColor}"
        v-show="isValid"
      >
        {{ $t('byodEnter') }}
      </span>
    </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-jumper',
  props: ['isValid', 'questionNumber', 'stepNumber'],
  inject: {
    theme: { default: defaultTheme },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.next-question-text {
    display: inline-block;
    font-size: 14px;
}
</style>
