<template>
  <div class="multi-question">
    <slot />
    <small v-if="fieldError && !hideError" class="form-text text-danger">{{fieldError}}</small>
  </div>
</template>

<script>
export default {
  name: 'question-multi',
  props: {
    fieldError: String,
    hideError: Boolean,
  },
};
</script>

<style lang="scss">
.multi-question {
  overflow: auto;
  padding-left: 20px;

  label {
    font-size: 15px;
    text-indent: 0;
    margin-bottom: 0;
  }
}
</style>
