<template>
<div class="col-lg-7 question-block" :style="bodyCSS">
  <div class="question">
    <label v-if="showLabel" v-html="label" :for="labelFor"/><span v-if="required && !hideRequiredAsterisk"> *</span>
    <p v-if="labelInfo" v-html="labelInfo" class="labelInfo"></p>
    <slot />
    <p v-if="sublabel" v-html="sublabel" class="sublabel" :style="{visibility: sublabelVisibility}"></p>
    <small v-if="fieldError && !hideError" class="form-text text-danger" v-html="fieldError"></small>
    <div v-if="!fieldError && showNext" class="next-question-block col-lg-12" :style="{visibility: jumperVisibility}">
      <question-jumper :stepNumber="stepNumber" :questionNumber="number" :isValid="isValid" @click="handleDone" />
    </div>
  </div>
</div>
</template>

<script>
import QuestionJumper from './QuestionJumper.vue';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-wrapper',
  props: {
    active: Boolean,
    required: Boolean,
    hideRequiredAsterisk: Boolean,
    number: Number,
    stepNumber: Number,
    label: String,
    labelInfo: String,
    sublabel: String,
    fieldError: String,
    showNext: Boolean,
    isValid: Boolean,
    hideError: Boolean,
    readonly: Boolean,
    labelFor: String,
    showLabel: {
      type: Boolean,
      default: true,
    },
    questionType: String,
  },
  inject: {
    theme: { default: defaultTheme },
  },
  methods: {
    handleDone(...args) {
      this.$emit('done', ...args);
    },
  },
  computed: {
    bodyCSS() {
      return { '--provider-color': this.theme.primaryColor };
    },
    sublabelVisibility() {
      if (this.questionType === 'phone') {
        return 'visible'; // TODO: @aallehoff dedicated twilio privacy policy feature
      }
      return this.active ? 'visible' : 'hidden';
    },
    jumperVisibility() {
      return (this.active && this.showNext && !this.readonly) ? 'visible' : 'hidden';
    },
  },
  components: { QuestionJumper },
};
</script>

<style lang="scss">
@import '../../styles/styles.scss';
a {
  color: var(--provider-color);
  text-decoration: underline;
}
a:hover, a:focus {
  color: var(--provider-color);
  text-decoration: underline;
  opacity: 0.8,
}

label {
  display: inline;
}

.label {
  text-align: left;
  font-size: 1.25em;
  font-color: $charcoal-grey-text;
}
.labelInfo {
  color: #AFAFAF;
  font-size: 16px;
  a {
    color: #016699;
    text-decoration: underline;
  }
}
.sublabel {
  font-size: 14px;
  margin-bottom: -14px;
}
.question-block{
  margin-top: 32px;
  margin-bottom: 32px;
}

.question {
  float: left;
  font-size: 1.25em;
  font-color: $charcoal-grey-text;
  width: 100%;
}
.question input {
  padding-left: 0px;
}
.question div{
  font-size: 1rem;
  padding-left: 0px;
}
.question-number {
    float: left;
    font-size: 1.25em;
    height: 100%;
    width: 10%;
}
.text-danger {
  font-size: 14px;
  padding: 8px 16px;
  width: auto;
  background-color: #f8d7da;
  color: #721c24 !important;
  border-color: #f5c6cb;
  margin-bottom: 15px;
  display: inline-block;
  border-radius: .25rem;
  margin-top: 48px;
}

.next-question-block {
  margin-top: 48px;
  padding-bottom: 21px;
}

ul.show-bullets {
  list-style-type: disc;
  padding-inline-start: 40px;
}
ul.show-bullets li {
  display: list-item;
}

</style>
