import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  model: 'vptesla',
  type: 'sim',
  name: 'vptesla',
}];

const addDevice = async (values, context) => {
  const deviceData = {
    ...values,
    mfg: 'vp-sim',
    deviceType: 'ev',
    kind: 'ev',
    type: 'TESLA',
  };
  return vp.addDevice(deviceData);
};

const VPTesla = new Device('vptesla', { mfg: 'vp-sim', questions, addDevice });

export default VPTesla;
