import _ from 'lodash';
import i18n from '../../i18n';

export default function getStep1Questions(program) {
  const { programSettings = [] } = program;
  const preformChecklist = programSettings.find(setting => setting.key === 'showPreformChecklist') || null;
  return [{
    label: 'step1FirstName',
    model: 'firstName',
    name: 'First Name',
    validation: 'required',
    required: true,
    show: true,
    included: true,
    advanceOnEnter: !(preformChecklist && preformChecklist.value),
  },
  {
    label: 'step1LastName',
    model: 'lastName',
    name: 'Last Name',
    advanceOnEnter: true,
    validation: 'required',
    required: true,
    show: true,
    included: true,
    update: values => ({
      label: i18n.t('step1LastNameUpdate', { firstName: values.firstName ? values.firstName : '' }),
    }),
  },
  {
    label: 'step1EmailAddress',
    model: 'email',
    advanceOnEnter: true,
    validation: 'required|email',
    required: true,
    show: true,
    included: true,
  },
  {
    type: 'phone',
    label: 'step1PhoneNumber',
    model: 'phone',
    show: true,
    validation: 'phoneNumberLeadingDigits|phoneNumber',
    advanceOnEnter: true,
  },
  {
    type: 'captcha',
    label: 'step1Captcha',
    model: 'captchaResponse',
    validation: 'required',
    role: 'alert',
    required: true,
    showNext: false,
    included: true,
    show: (values) => {
      const email = _.get(values, 'email') || '';
      const [name = '', domain = '', ...rest] = email.split('@');
      const dot = domain.indexOf('.');
      return rest.length === 0 && name.length > 0 && domain.length > 3 && dot < (domain.length - 2);
    },
  },
  {
    type: 'tstat-select',
    label: 'step1TstatSelect',
    model: 'tstatSelector',
    required: true,
    ariaLive: 'polite',
    validRegex: /.*honeywell|.*sensibo|.*sensi|.*mysa|.*other|.*no/,
    show: values => Boolean(_.get(values, 'captchaResponse')),
    advanceOnEnter: true,
    included: false,
  },
  {
    type: 'multi',
    label: 'step1AddressLabel',
    model: 'address',
    validation: 'required',
    ariaLive: 'polite',
    required: true,
    hideCombinedError: true,
    show: (values, step) => {
      const tstatSelector = step.questions.find(({ model }) => model === 'tstatSelector');
      if (tstatSelector.included) {
        return Boolean(_.get(values, 'captchaResponse') && _.get(values, 'tstatSelector') && _.get(values, 'tstatSelector') !== 'nest' && _.get(values, 'tstatSelector') !== 'ecobee');
      }
      return Boolean(_.get(values, 'captchaResponse'));
    },
    questions: [{
      type: 'address-street',
      model: 'address',
      validation: 'address',
      ariaLive: 'polite',
      required: true,
      advanceOnEnter: true,
      advanceOnValue: true,
      active: true,
    }, {
      type: 'address-street-extra',
      model: 'address2',
      ariaLive: 'polite',
      required: false,
      advanceOnEnter: true,
      advanceOnValue: true,
    }, {
      type: 'address-city',
      model: 'city',
      ariaLive: 'polite',
      validation: 'city|cityAllowlist',
      required: true,
      advanceOnEnter: true,
      advanceOnValue: true,
    }, {
      type: 'address-state',
      model: 'state',
      ariaLive: 'polite',
      validation: 'alpha|state|stateAllowlist',
      advanceOnEnter: true,
      advanceOnValue: true,
    }, {
      type: 'address-zipcode',
      model: 'zipcode',
      ariaLive: 'polite',
      validation: 'postalCode|postalCodeAllowList',
      advanceOnEnter: true,
      advanceOnValue: true,
    }],
  },
  {
    label: 'step1AccountNumber',
    model: 'accountNumber',
    advanceOnEnter: true,
    ariaLive: 'polite',
    validRegex: null,
    required: false,
    show: (values, step) => {
      const tstatSelector = step.questions.find(({ model }) => model === 'tstatSelector');
      if (tstatSelector.included) {
        return Boolean(_.get(values, 'captchaResponse') && _.get(values, 'tstatSelector') === 'other');
      }
      return Boolean(_.get(values, 'captchaResponse'));
    },
  }];
}
