import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  model: 'vpevse',
  type: 'sim',
  name: 'vpevse',
}];

const addDevice = async (values, context) => {
  const deviceData = {
    ...values,
    mfg: 'vp-sim',
    deviceType: 'evse',
    kind: 'evse',
    type: null,
  };
  return vp.addDevice(deviceData);
};

const VPEvse = new Device('vpevse', { mfg: 'vp-sim', questions, addDevice });

export default VPEvse;
