export const battery = [];

export const hwh = [{
  type: 'mixing-valve',
  label: 'step3MixingValveQuestion',
  model: 'deviceData.mixingValve',
  role: 'status',
  name: 'mixingValve',
  required: true,
  showNext: true,
  advanceOnEnter: true,
}];

export default { hwh, battery };
