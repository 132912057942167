import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [{
  name: 'duracell',
  type: 'duracell',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: true,
}];

async function fetchPairingCode() {
  const data = await vp.getPairingCode({
    mfg: 'eguana',
  });
  return data.value;
}

const addDevice = async (values, context) => {
  const pairingCode = await fetchPairingCode();
  const deviceData = {
    ...values,
    mfg: 'eguana',
    subBrand: 'duracell',
    deviceType: 'battery',
    shellDevice: true,
    pairingCode,
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Duracell = new Device('duracell', { mfg: 'eguana', questions, addDevice });

export default Duracell;
