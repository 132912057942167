import Device from '../Device';
import vp from '../../../services/api/vp';
import { battery } from './Common';

const questions = [{
  type: 'text',
  label: 'step3SolaredgeLabel',
  labelInfo: 'step3SolaredgeSublabel',
  active: true,
  showNext: true,
  advanceOnEnter: true,
  advanceOnValue: false,
  model: 'inverterSerialNumber',
  name: 'inverterSerialNumber',
  validation: 'required',
}].concat(battery);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'solaredge',
    deviceType: 'battery',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const SolarEdge = new Device('solaredge', { mfg: 'solaredge', questions, addDevice });

export default SolarEdge;
