import Device from '../Device';
import vp from '../../../services/api/vp';
import { hwh } from './Common';

const questions = hwh.concat([
  {
    type: 'text',
    label: 'step3EradioLabel',
    advanceOnEnter: true,
    advanceOnValue: false,
    name: 'serialNumber',
    model: 'serialNumber',
    validation: 'required',
  },
]);

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'eradio',
    deviceType: 'hwh',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Eradio = new Device('eradio', { mfg: 'eradio', questions, addDevice });

export default Eradio;
