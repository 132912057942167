<template>
  <Modal v-model="passThroughValue" :title="this.$i18n.t('modalChecklist.headline')" :customerConfig="customerConfig" :showCloseButton="showCloseButton" class="checklist-modal">
    <div tabindex="-1">
      <p>
        {{ $t('modalChecklist.introduction') }}
      </p>
      <checklist-swiper :desc="desc"/>
      <section class="row confirm-checklist">
        <div class="col">
          <button
            v-show="checklistViewed"
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            ref="closeChecklist"
            @click="confirm();"
            @keydown="confirmByKeydown($event);"
            aria-label="close dialog"
          >
          {{ $t('modalChecklist.button') }}

          </button>
        </div>
      </section>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import ChecklistSwiper from './ChecklistSwiper.vue';
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'modal-checklist',
  components: { Modal, ChecklistSwiper },
  props: ['value', 'customerConfig', 'checklistViewed'],
  inject: {
    program: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    const { programSettings = [] } = this.program;
    const preformChecklist = programSettings.find(setting => setting.key === 'showPreformChecklist');
    const desc = preformChecklist ? preformChecklist.text : [];

    return {
      passThroughValue: false,
      showCloseButton: false,
      desc,
    };
  },
  watch: {
    value() {
      this.passThroughValue = this.value;
    },
    passThroughValue() {
      this.$emit('input', this.passThroughValue);
    },
  },
  mounted() {
    this.passThroughValue = this.value;
  },
  methods: {
    confirm() {
      this.$emit('input', false);
    },
    confirmByKeydown(evt) {
      if ((evt.key === 'Enter') || (evt.key === 'Space') || (evt.key === ' ')) {
        this.$emit('input', false);
      }
    },
  },
};

</script>

<style lang="scss">

.checklist-modal {

  p {
    font-size: 14px;
    margin: 9px 0 9px 0;
  }

  @media (max-width: 576px) {
    p {
      margin: 9px 38px 20px 38px;
      font-size: 14px;
    }
  }

  .row.confirm-checklist {
    text-align: center;
    width: auto;

    @media (max-width: 576px) {
      padding: 0 24px;

      p {
        @media (max-width: 576px) {
          display: none;
        }
      }

    }

    .col {
      button {
        min-width: 35%;

        @media (max-width: 576px) {
          margin: 8px 0;
          width: 100%;
        }
        &:focus {
          outline: 2px solid #000;
        }
      }

    }

  }
}
</style>
