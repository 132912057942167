export default function houseDataToInitialValues(house) {
  const {
    roles = [], address, address2, city, state, zipcode, customReportingDataValues, phone,
  } = house;
  const role = roles.find((el) => el.role === 'house');

  if (!role) {
    throw new Error('houseDataToInitialValues - failed to find house role');
  }

  const { account } = role;
  const {
    firstName,
    lastName,
    phoneNumbers = [],
    email,
  } = account;
  const [phoneObj = null] = phoneNumbers;
  const phoneNumber = phoneObj && phoneObj.phone ? phoneObj.phone : phone;
  const tags = customReportingDataValues.map((el) => {
    const { customReportingDataKey } = el;
    return ['numberValue', 'stringValue', 'dateValue', 'booleanValue'].reduce((prev, curr) => {
      return el[curr] ? { [`tags.${customReportingDataKey.name}`]: el[curr] } : prev;
    }, null);
  });
  return {
    firstName,
    lastName,
    email,
    phone: phoneNumber,
    address: {
      address,
      address2,
      city,
      state,
      zipcode,
    },
    captchaResponse: true,
    acceptConditions: true,
    ...tags.reduce((acc, curr) => ({ ...acc, ...curr })),
  };
}
