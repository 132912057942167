// const getTop = () => Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
const NOMINAL_STEPS = 20;
const NOMINAL_TIME = 200;
const MIN_STEP_SIZE = 2;
export default function scrollToElement(comp, options = {}) {
  const el = comp.$el || comp;
  const pageTop = window.pageYOffset;
  const elementTop = el.getBoundingClientRect().top + pageTop;

  const { offsetFromTop = 0, percentFromTop = null } = options;
  let scrollToValue = elementTop - offsetFromTop;
  if (percentFromTop != null) {
    scrollToValue = elementTop - (window.innerHeight * (percentFromTop / 100));
  }

  // make sure the page is long enough
  const margin = window.innerHeight - offsetFromTop;
  document.getElementById('app').style.marginBottom = `${margin}px`;

  let scrollValue = pageTop;
  const maxStepSize = (scrollToValue - pageTop) / (0.75 * NOMINAL_STEPS);
  const minStepSize = maxStepSize / (2 ** (NOMINAL_STEPS / 4));
  let stepSize = 0;
  let state = 'RAMP_UP';
  let rampDistance = 0;
  const sign = maxStepSize > 0 ? 1 : -1;

  if ((minStepSize && maxStepSize) === 0) {
    /*
      Return early if there is no reason to attempt to scroll.

      This prevents calling functions that depend on the returned promise from hanging indefinitely.
    */
    return Promise.resolve();
  }

  let done = false;

  const promise = new Promise((resolve, reject) => {
    const listen = (event) => {
      if (done) {
        window.removeEventListener('scroll', listen);
        resolve(); // Note: will not resolve unless the window scrolls
      }
    };
    window.addEventListener('scroll', listen);
  });

  const stepper = setInterval(() => {
    switch (state) {
      case 'RAMP_UP':
        stepSize = stepSize === 0 ? minStepSize : stepSize * 2;
        if (Math.abs(stepSize) >= Math.abs(maxStepSize)) {
          stepSize = maxStepSize;
          rampDistance = (scrollValue + stepSize) - pageTop;
          state = 'STEADY';
        }
        break;
      case 'RAMP_DOWN':
        stepSize /= 2;
        if ((sign * (scrollValue + stepSize)) >= (sign * scrollToValue) || Math.abs(stepSize) < Math.abs(MIN_STEP_SIZE)) {
          stepSize = scrollToValue - scrollValue;
          clearInterval(stepper);
          done = true;
        }
        break;
      case 'STEADY':
      default:
        stepSize = maxStepSize;
        if ((sign * (scrollValue + stepSize)) >= (sign * (scrollToValue - rampDistance))) {
          state = 'RAMP_DOWN';
        }
        break;
    }
    scrollValue += stepSize;
    window.scrollTo(0, scrollValue);
    // window.scroll({
    //   top: scrollValue,
    //   left: 0,
    // });
  }, (NOMINAL_TIME / (0.7 * NOMINAL_STEPS)));

  return promise;
}
