<template>
  <main>
    <button
      :style="{backgroundColor: theme.primaryColor}"
      @click="showModal"
      class="question-button"
    >
    {{ $t('modalFranklinWHPairing.startPairingButton') }}
    </button>
    <Modal v-model="passThroughValue" :title="title" :customerConfig="customer" :showCloseButton="showCloseButton" class="franklinWH-modal">
      <p class="intro">
        {{ $t('modalFranklinWHPairing.modalIntroduction') }}
      </p>
      <div id="pairingSteps">
        <div id="pairingStepsTitle">{{ $t('modalFranklinWHPairing.stepsTitle') }}</div>
        <div id="pairingStepsContainer">
          <ol id="pairingStepsChecklist">
            <li> {{ $t('modalFranklinWHPairing.step1') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step2') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step3') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step4') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step5') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step6') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step7') }}</li>
            <li> {{ $t('modalFranklinWHPairing.step8') }}</li>
          </ol>
          <span id="pairingDetailedInstructions"><a target="_blank" href="https://support.virtual-peaker.com/knowledge/franklin-byod-pairing-code-flow-instructions">{{ $t('modalFranklinWHPairing.helpCenter') }}</a> </span>
          <p id="pairingMoreInfo">{{ $t('modalFranklinWHPairing.moreInfo') }}</p>
        </div>
      </div>
      <hr />
      <section class="row completion-instructions">
        <div class="col-md col-6">
          <p
            id="pairing-code__code"
            class="pairing-code__code"
            ref="franklinwhPairingCode"
            v-bind:style="{ color: theme.primaryColor }"
          >
            {{ pairingCode }}
          </p>
        </div>
        <div class="col-md col-6">
          <button
            ref="copyToClipboard"
            v-bind:style="{
              color: theme.primaryColor,
              borderColor: theme.primaryColor
            }"
            class="pairing-code__button"
            id="copy-pairing-code"
            v-clipboard:copy="pairingCode"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
          >
            {{ pairingBtnTxt }}
          </button>
        </div>
        <div v-if="isCreated" class="col-md col-12 franklinwh__bounce">
          <button
            ref="openConnectionsPage"
            v-bind:style="{ backgroundColor: theme.primaryColor }"
            class="question-button"
            @click="handleSubmit"
          >
          {{ $t('modalFranklinWHPairing.submitButton') }}
          </button>
        </div>
      </section>
    </Modal>
    <Modal
      v-model="isCancelled"
      :title="titleCancel"
      :showCloseButton="showCloseButton"
      class="franklinWH-modal cancel-modal"
      @input="resumeDevice"
    >
      <p>
        {{ $t('modalFranklinWHWarning.text') }}
      </p>
      <section class="row completion-instructions">
        <div class="col-5 col-md-4">
          <button
            v-bind:style="{
              color: theme.primaryColor,
              borderColor: theme.primaryColor,
            }"
            class="pairing-code__button"
            @click="resumeDevice"
          >
          {{ $t('modalFranklinWHWarning.cancelButton') }}
          </button>
        </div>
        <div class="col-5 col-md-4">
          <button
            class="question-button franklinWH-cancel"
            @click="cancelDevice"
          >
            {{ cancelBtnText }}
          </button>
        </div>
      </section>
    </Modal>
  </main>
</template>

<style lang="scss">
@import '../../styles/mixins.scss';
div[type='pairing-code'] {
  // hiding default backsplash
  & > div > span,
  & > div > label {
    @include Mobile-Only {
      font-size: 16px;
      line-height: 24px;
    }
    font-size: 24px;
    line-height: 36px;
  }
}
main[data-vv-name="pairingCode"] {
  margin-top: 2rem;
  & > .question-button {
    min-width: 200px;
    min-height: 40px;
  }
}

.franklinWH-modal {
  #modal-title,
  .modal-body p {
    text-align: left;

    @include Mobile-Only {
      text-align: center;
    }
  }

  #modal-title-mobile {
    text-align: center;
  }

  #pairingStepsChecklist li {
    list-style-type: decimal;
  }
  #pairingMoreInfo {
    font-size: 16px;
    font-style: italic;
    text-align: left;

    @include Mobile-Only {
      text-align: center;
      font-size: 14px;
    }
  }

  .modal-body > p:first-of-type {
    margin-bottom: 1rem;
    @include Mobile-Only {
      margin-top: 0.5rem;
    }
  }

  h5#modal-title {
    padding: 0 0 0 2rem;
  }

  h5#modal-title-mobile {
    padding-top: 3rem;
  }

  div.modal-dialog {
    @include Not-Mobile {
      width: 100vw;
    }
  }
  .modal-body {
    height: 100%;
    @include Mobile-Only {
      padding-top: 0;
    }
    @include Not-Mobile {
      margin: 1rem 2rem;
    }

    & > p:first-of-type {
      @include Not-Mobile {
        margin-top: 0;
      }
    }

    button {
      font-size: 13px;
      &:active {
        box-shadow: none; // Remove general btn onClick border
        line-height: inherit;
      }
    }
  }
  .modal-header {
    align-items: center;
    @include Mobile-Only {
      padding-top: 2rem;
      padding-right: 1rem;
    }
  }

  p.franklinWH-modal-footer,
  p.franklinWH-modal-footer a {
    padding-bottom: 1rem;
    font-size: 14px;
    line-height: 20px;
    @include Mobile-Only {
      font-size: 11px;
      line-height: 16px;
    }
  }

  hr {
    margin: 0 auto;
    border: 1px solid $GREY;

    @include Mobile-Only {
      width: 80%;
    }
    @include Tablet {
      margin: 0.5rem auto;
    }
     &:first-of-type {
        margin-bottom: 2rem;
        @include Mobile-Only--no-height {
          margin-bottom: 1rem;
        }
        @include Tablet {
          margin-bottom: 1rem;
        }
      }
      &:last-of-type {
        margin-top: 2rem;
        @include Mobile-Only--no-height {
          margin-top: 1rem;
        }
        @include Tablet {
          margin-top: 1rem;
        }
      }
  }

  p {
    margin: 1rem auto;
    line-height: 24px;
    @include Mobile-Only {
      max-width: 80%;
      line-height: 20px;
      &,
      a {
        font-size: 14px;
      }
    }

    @include Not-Mobile {
      margin: 0.5rem auto;
    }

    @include Mobile-Only--small {
      margin: 0.25rem auto;
    }
  }
  .franklinWH-cancel {
    background-color: #ce3c31;
  }
  #pairing-code__code {
    font-size: 32px;
    line-height: 40px;
    background: $WHITE;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 6px;
    height: 100%;
    margin: 0;
    padding-top: $GAP;
    text-align: center;
    max-width: 100%;
    @include Mobile-Only {
      padding-top: 0;
      font-size: 24px;
    }
  }

  .pairing-code__button {
    background: $WHITE;
    border: 2px solid #016699;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .pairing-code__button,
  .question-button {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    @include Mobile-Only {
      padding: 10px;
    }
  }

  .completion-instructions {
    text-align: center;
    margin: auto;

    @include Mobile-Only {
      width: 80%;
    }

    @include Desktop--tall {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    div {
      @include Mobile-Only--small {
        padding: 2px 0;
      }
      @include Mobile-Only {
        padding-right: 0;
      }
      &:first-child {
          padding-left: 0;
          padding-right: .75rem;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: .75rem;
          @include Tablet {
            padding-right: .75rem;
          }
        }

       &:nth-child(3) {
          padding-top: 1.5rem;
          padding-right: 0;
          @include Tablet {
            padding-top: 0;
            padding-left: .75rem;
          }
        }

    }

    .col button {
      @include Mobile-Only {
        margin: 8px 0;
        width: 100%;
      }
    }
  }
}
.cancel-modal {
  .modal-body {
    @include Mobile-Only {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .completion-instructions {
    padding-top: 1rem;
    margin: 1rem auto;
    justify-content: space-around;
    @include Not-Mobile {
      justify-content: center;
    }

    & > div {
      @include Mobile-Only {
        padding: 0.75rem 0;
      }
    }

    button {
      height: 40px;
      padding: 0;
    }
  }
}
</style>

<script>
import Modal from '../Modal/Modal.vue';
import defaultTheme from '../../styles/defaultTheme';
import vp from '../../services/api/vp';

export default {
  name: 'question-franklinwh-pairing-code',
  props: {
    question: Object,
    stepValues: Object,
    parentRefs: Object,
  },
  inject: {
    account: 'account',
    customer: 'customer',
    program: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    return {
      codeFetched: false,
      isMobile: false,
      isCreated: false,
      mfg: 'franklinwh',
      isCancelled: false,
      passThroughValue: false,
      showCompleteButton: false,
      cancelBtnText: this.$i18n.t('modalFranklinWHWarning.endConnectionButton'),
      pairingCode: '00000',
      pairingBtnTxt: this.$i18n.t('modalFranklinWHPairing.pairingCodeButtonMobile'),
      supportEmail: 'support@virtual-peaker.com',
      title: this.$i18n.t('modalFranklinWHPairing.headlineMobile'),
      titleCancel: this.$i18n.t('modalFranklinWHWarning.headline'),
      showCloseButton: true,
      deviceData: {},
    };
  },
  watch: {
    isReady: {
      immediate: true,
      handler(current, prev) {
        if (!prev && current) {
          this.fetchPairingCode();
        }
      },
    },
    passThroughValue() {
      if (!this.passThroughValue && this.isCreated) {
        this.isCancelled = true;
      }
    },
    isCancelled: {
      handler(current, prev) {
        if (current) this.toggleText();

        if (prev && Object.keys(this.deviceData).length && !current && !this.passThroughValue) {
          this.resumeDevice();
        }
      },
    },
  },
  computed: {
    houseId() {
      return this.account ? this.account.houseId : null;
    },
    programName() {
      return this.program ? this.program.programName : null;
    },
    providerId() {
      return this.customer ? this.customer.id : null;
    },
    missingFields() {
      const requiredFields = ['mfg', 'programName', 'providerId'];
      const missing = requiredFields.filter(fld => !this[fld]);
      return missing;
    },
    isReady() {
      return !this.missingFields.length;
    },
  },
  mounted() {
    this.toggleAddDeviceBtnDisplay('none');
    this.isMobile = window.matchMedia('(max-width: 576px)').matches;
    this.supportEmail = this.customer.supportEmail;
    this.toggleText();
  },
  updated() {
    [...document.querySelectorAll('button.close')].map(btn => btn.addEventListener('click', this.closeModal));
  },
  destroyed() {
    this.toggleAddDeviceBtnDisplay('');
    [...document.querySelectorAll('button.close')].map(btn => btn.removeEventListener('click', this.closeModal));
  },
  components: {
    Modal,
  },
  methods: {
    toggleAddDeviceBtnDisplay(set) {
      const submitBtns = [...document.getElementsByClassName('pull-right question-button')];
      const [addDeviceBtn] = submitBtns.filter(el => el.innerText.includes('Add Device'));
      if (addDeviceBtn !== undefined) {
        addDeviceBtn.style.display = set;
      }
    },
    async fetchPairingCode() {
      if (!this.isReady) return;
      const data = await vp.getPairingCode({
        mfg: this.mfg,
      });
      this.pairingCode = data.value;
      this.codeFetched = true;
    },
    async createDevice() {
      if (this.isCreated) return null;
      this.deviceData = {
        ...this.stepValues,
        pairingCode: this.pairingCode,
        mfg: this.mfg,
        houseId: this.houseId,
        program: this.programName,
        providerId: this.providerId,
      };
      try {
        const data = await vp.addDevice(this.deviceData);
        this.deviceId = data.id;
        this.deviceData.deviceId = this.deviceId;
        this.$emit('input', this.pairingCode);
        this.isCreated = true;
        this.toggleText();
      } catch (err) {
        if (this.$router) this.$router.push('/error');
      }
      return null;
    },
    async cancelDevice() {
      try {
        /* remove device from database */
        const data = vp.removeDevice(this.deviceData);
        /* remove device from component */
        this.deviceData = {};
        /* revert back to select device type */
        this.isCreated = false;
        this.isCancelled = false;
      } catch (error) {
        if (this.$router) this.$router.push('/error');
      }
      return null;
    },
    resumeDevice() {
      this.isCancelled = false;
      this.showModal();
    },
    onCopy() {
      this.pairingBtnTxt = this.isMobile ? this.$i18n.t('modalFranklinWHPairing.pairingCodeConfirmedMobile') : this.$i18n.t('modalFranklinWHPairing.pairingCodeConfirmedText');
    },
    onCopyError() {
      this.pairingBtnTxt = this.$i18n.t('modalFranklinWHPairing.pairingCodeFailureText');
    },
    toggleText() {
      if (this.isMobile) {
        this.cancelBtnText = this.$i18n.t('modalFranklinWHWarning.endConnectionButton');
        this.pairingBtnTxt = this.$i18n.t('modalFranklinWHPairing.pairingCodeButtonMobile');
        this.title = this.$i18n.t('modalFranklinWHPairing.headlineMobile');
        this.titleCancel = this.$i18n.t('modalFranklinWHWarning.headlineMobile');
      } else {
        this.cancelBtnText = this.$i18n.t('modalFranklinWHWarning.endConnectionButton');
        this.pairingBtnTxt = this.$i18n.t('modalFranklinWHPairing.pairingCodeButton');
        this.title = this.$i18n.t('modalFranklinWHPairing.headline');
        this.titleCancel = this.$i18n.t('modalFranklinWHWarning.headline');
      }
    },
    showModal() {
      this.toggleText();
      this.passThroughValue = true;
      this.createDevice();
    },
    closeModal() {
      this.passThroughValue = false;
      this.toggleText();
    },
    handleSubmit() {
      this.$router.push({ path: '/success', params: { type: 'franklinwh' } });
    },
  },
};
</script>
