<template>
  <div>
    <div v-html="terms" class="terms"></div>
    <div class="next-question-block">
      <div style="float: right; margin-right: -5px;">
        <vp-checkbox
            id="acceptTerms"
            :value="value"
            class="option"
            :labelFirst="true"
            :styles="{ size: 16, color: theme.primaryColor }"
            :disabled="readonly"
            aria-label="accept terms checkbox"
            @input="checked => $emit('input', checked)"
          >
          <template #label>
            {{ $t('step2Checkbox') }}
          </template>
        </vp-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';

import defaultTheme from '../../styles/defaultTheme';
import vp from '../../services/api/vp';

export default {
  name: 'question-terms-and-conditions',
  props: {
    value: {},
    readonly: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: String,
  },
  inject: {
    byodProgram: 'program',
    theme: { default: defaultTheme },
  },
  data() {
    return {
      terms: '',
    };
  },
  mounted() {
    vp.getTerms(this.byodProgram.termId)
      .then((text) => {
        this.terms = marked(text);
      });
  },
};
</script>

<style scoped>
.terms {
  height: 400px;
  overflow-y: scroll;
  padding: 3%;
  margin: 10px 0 10px 0;
  border: solid 1px #e8e8e8;
  width: 100%;
}
</style>
<style>
.terms ul {
  list-style-type: disc;
}
.terms ul,ol {
  padding-inline-start: 40px;
}
.terms ul ul{
  list-style-type: circle;
}
</style>
