<template>
  <div class="swiper-container">
    <div class="swiper-wrapper modal-tiles">
      <div class="swiper-slide swiper-slide-active modal-tiles--tile">
        <div class="modal-tiles--tile--icon">
          <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path v-bind:style="{ fill: theme.primaryColor }" d="M30 60C13.4078 60 0 46.5922 0 30C0 13.4078 13.4078 0 30 0C46.5922 0 60 13.4078 60 30C60 46.5922 46.5922 60 30 60ZM6.03352 29.581C6.03352 42.8212 16.7598 53.5475 30 53.5475C43.2402 53.5475 53.9665 42.8212 53.9665 29.581C53.9665 16.3408 43.2402 5.61453 30 5.61453C16.7598 5.61453 6.03352 16.3408 6.03352 29.581Z" fill="#016699"/>
          <path v-bind:style="{ fill: theme.primaryColor }" d="M21.0335 37.9609H17.095L23.7151 25.8101V26.8157H16.7598V23.8827H27.2346V26.6481L21.0335 37.9609Z"/>
          <path v-bind:style="{ fill: theme.primaryColor }" d="M28.9945 37.9609V35.1955L33.6034 30.419C34.0224 30 34.3576 29.4972 34.5252 29.162C34.7766 28.743 34.8604 28.324 34.8604 27.905C34.8604 27.4022 34.6928 27.067 34.4414 26.8994C34.19 26.648 33.771 26.5643 33.2682 26.5643C32.6816 26.5643 32.095 26.648 31.5085 26.8994C30.9219 27.1508 30.2515 27.4022 29.6649 27.8212L28.4917 25.2235C29.0783 24.7207 29.8325 24.3855 30.6705 24.1341C31.5085 23.8827 32.514 23.7151 33.4358 23.7151C34.4414 23.7151 35.3632 23.8827 36.1174 24.2179C36.8716 24.5531 37.4582 24.9721 37.8772 25.6425C38.2962 26.2291 38.4638 26.9832 38.4638 27.7374C38.4638 28.5754 38.2962 29.3296 37.961 30C37.6258 30.6704 37.123 31.4246 36.285 32.2626L32.6816 35.9497L32.1788 35.0279H39.0504V37.9609H28.9945Z"/>
          <path v-bind:style="{ fill: theme.primaryColor }" d="M43.4917 29.9162C42.9051 29.9162 42.4023 29.7486 41.8995 29.4972C41.3967 29.2458 41.0615 28.8268 40.8101 28.4078C40.5587 27.905 40.3911 27.4022 40.3911 26.8156C40.3911 26.2291 40.5587 25.7263 40.8101 25.2235C41.0615 24.7207 41.4805 24.3855 41.8995 24.1341C42.4023 23.8827 42.9051 23.7151 43.4917 23.7151C44.0783 23.7151 44.5811 23.8827 45.0839 24.1341C45.5866 24.3855 45.9218 24.7207 46.1732 25.2235C46.4246 25.7263 46.5922 26.2291 46.5922 26.8156C46.5922 27.4022 46.4246 27.905 46.1732 28.4078C45.9218 28.9106 45.5028 29.2458 45.0839 29.4972C44.6649 29.7486 44.0783 29.9162 43.4917 29.9162ZM43.4917 28.1564C43.8269 28.1564 44.1621 27.9888 44.4135 27.8212C44.6649 27.5698 44.8325 27.2346 44.8325 26.8156C44.8325 26.3967 44.6649 26.0615 44.4135 25.8939C44.1621 25.6425 43.8269 25.5587 43.4917 25.5587C43.1565 25.5587 42.8213 25.6425 42.5699 25.8939C42.3185 26.1453 42.2347 26.4805 42.2347 26.8156C42.2347 27.2346 42.3185 27.5698 42.5699 27.8212C42.8213 28.0726 43.1565 28.1564 43.4917 28.1564Z"/>
          </svg>
        </div>
        <div class="modal-tiles--tile--desc">
          <h6>{{ desc[0].title }}</h6>
          <p v-html="desc[0].body"></p>
        </div>
      </div>
      <div class="swiper-slide modal-tiles--tile">
        <div class="modal-tiles--tile--icon" id="wireless-icon">
          <svg width="44" height="29" viewBox="0 0 60 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path v-bind:style="{ fill: theme.primaryColor }" d="M60 12.6354C60 13.213 59.8556 13.7184 59.4224 14.0794L56.3177 17.2563C55.5957 17.9783 54.3682 18.0505 53.574 17.3285C40.1444 5.34296 19.8556 5.34296 6.42599 17.3285C5.63177 18.0505 4.47653 17.9783 3.75451 17.2563L0.649819 14.1516C0.144404 13.7184 0 13.213 0 12.6354C0 12.13 0.216607 11.6245 0.577617 11.2635L0.649819 11.1913C8.66426 3.97112 19.1336 0 30.0361 0C40.9386 0 51.3357 3.97112 59.4224 11.2635C59.7834 11.5523 60 12.0578 60 12.6354ZM48.8087 23.8267C48.8809 24.4043 48.6643 24.9097 48.2311 25.343L45.2708 28.3033C44.5487 29.0253 43.3935 29.0975 42.5993 28.4477C35.3069 22.3827 24.6931 22.3827 17.3285 28.4477C16.5343 29.0975 15.3791 29.0975 14.657 28.3033L11.6968 25.343C11.3357 24.9819 11.1191 24.4043 11.1191 23.8267C11.1191 23.3213 11.3357 22.8881 11.6968 22.5271C11.769 22.4549 11.769 22.4549 11.8412 22.3827C16.8953 18.1227 23.3213 15.7401 29.9639 15.7401C36.6065 15.7401 43.0325 18.0505 48.0866 22.3827C48.5199 22.8159 48.8087 23.2491 48.8087 23.8267ZM34.2238 39.3502C31.9134 41.6607 28.1588 41.6607 25.7762 39.3502C23.4657 37.0397 23.4657 33.2852 25.7762 30.9025C28.0866 28.5921 31.8412 28.5921 34.2238 30.9025C36.5343 33.2852 36.5343 37.0397 34.2238 39.3502Z"/>
          </svg>
        </div>
        <div class="modal-tiles--tile--desc">
          <h6>{{ desc[1].title }}</h6>
          <p v-html="desc[1].body"></p>
        </div>
      </div>
      <div class="swiper-slide modal-tiles--tile">
        <div class="modal-tiles--tile--icon">
          <svg width="36" height="44" viewBox="0 0 49 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
          <path v-bind:style="{ fill: theme.primaryColor }" d="M46.5834 24.7219C45.0851 23.2236 43.3825 22.5426 41.2713 22.5426H39.3644V15.3235C39.3644 13.1442 39.0238 11.1691 38.2747 9.2622C37.5255 7.35528 36.504 5.78888 35.1419 4.42679C33.7798 3.0647 32.2134 1.97503 30.3746 1.15778C28.5358 0.408627 26.4926 0 24.3133 0C22.0658 0 20.0227 0.408626 18.252 1.22588C16.4132 2.04313 14.8468 3.1328 13.5528 4.49489C12.2588 5.85698 11.2372 7.49149 10.4881 9.39841C9.73893 11.3053 9.39841 13.2804 9.39841 15.3916V22.4745H7.49149C5.44835 22.4745 3.67764 23.2236 2.17934 24.6538C0.749149 26.1521 0 27.9228 0 30.0341V52.5085C0 54.5516 0.749149 56.3224 2.17934 57.8207C3.67764 59.2509 5.44835 60 7.49149 60H41.2713C43.3144 60 45.0851 59.2509 46.5834 57.8207C48.0817 56.3224 48.7628 54.6198 48.7628 52.5085V30.0341C48.7628 27.9228 48.0136 26.1521 46.5834 24.7219ZM27.9909 40.1816C27.5823 40.7946 27.1056 41.3394 26.4926 41.6799V48.6266C26.4926 49.1714 26.2883 49.7162 25.8797 50.1249C25.4711 50.5335 24.9943 50.7378 24.3814 50.7378C23.7684 50.7378 23.2917 50.5335 22.8831 50.1249C22.4745 49.7162 22.2701 49.2395 22.2701 48.6266V41.6799C21.6572 41.3394 21.1805 40.8627 20.7719 40.1816C20.3632 39.5687 20.1589 38.8195 20.1589 38.0704C20.1589 36.9126 20.5675 35.891 21.3848 35.0738C22.202 34.2565 23.2236 33.8479 24.3814 33.8479C25.5392 33.8479 26.5607 34.2565 27.378 35.0738C28.1952 35.891 28.6039 36.9126 28.6039 38.0704C28.6039 38.8195 28.3995 39.5687 27.9909 40.1816ZM33.3031 22.4745H15.1192V15.9364C15.1192 13.0079 15.9364 10.4881 17.5709 8.44495C19.2054 6.40182 21.3848 5.31215 24.1771 5.31215C26.9012 5.31215 29.0806 6.33371 30.7832 8.37684C32.4858 10.42 33.3031 12.9398 33.3031 15.8683V22.4745Z"/>
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="48.7628" height="60" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        </div>
        <div class="modal-tiles--tile--desc">
          <h6>{{ desc[2].title }}</h6>
          <p v-html="desc[2].body"></p>
        </div>
      </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>
  </div>
</template>

<style lang="scss">
@import '../../styles/mixins.scss';

.swiper-container {
  width: 100%;
}
.swiper-pagination {
  position: relative;
  margin-top: 1rem;

  @include Mobile-Only--small {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include Not-Mobile {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-bullet {
    background-color: transparent;
    border: 1px solid;
    box-sizing: border-box;
    margin: 4px;
  }
}

.modal-tiles {
  width: auto;
  margin-bottom: 1rem;

  .modal-tiles--tile {
    background-color: $WHITE;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 300px;

    @include Mobile-Only {
      border-radius: 10px;
      min-height: 180px;
    }

    .modal-tiles--tile--desc {
      margin: 1rem 0;

      p {
      padding: 0 12px;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      color: #4f4f4f;
      line-height: 18px;

      @include Mobile-Only {
        font-size: 13px;
        margin: 9px 32px;
      }
      @include Mobile-Only--no-height {
        margin: 1rem 0;
      }
    }
      a {
        font-size: 12px;

        @include Mobile-Only {
          font-size: 13px;
        }
      }
      h6 {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        color: #4F4F4F;

      }
    }

    .modal-tiles--tile--icon {
      display: block;
      height: 4rem;
      text-align: center;
      position: relative;
      top: 28px;
      @include Mobile-Only {
        top: 18px;
      }
    }

    #wireless-icon {
      top: 36px;
      @include Mobile-Only {
        top: 25px;
      }
    }
  }
}

.swiper-slide {
  width: 181px;
}

div.col button {
  max-height: 40px;
  margin: 15px;
  padding-top: 10px;
}
</style>

<script>
import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import defaultTheme from '../../styles/defaultTheme';

const viewLarge = '(min-width: 768px)';
const viewSmall = '(min-width: 577px) and (max-width: 767px)';
const viewMobile = '(max-width: 576px)';
Swiper.use([Pagination]);

export default {
  name: 'checklist-swiper',
  props: ['desc'],
  inject: {
    theme: {
      default: defaultTheme,
    },
  },
  data() {
    return {
      iconStyling: { color: defaultTheme.primaryColor },
      providerColor: { fill: defaultTheme.primaryColor },
      currentView: '',
    };
  },
  mounted() {
    const mySwiper = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      initialSlide: 0,
      spaceBetween: 30,
      slidesPerView: 1.4,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        577: {
          centeredSlides: false,
          slidesPerView: 2,
        },
        768: {
          centeredSlides: false,
          spaceBetween: 20,
          slidesPerView: 3,
          watchOverflow: true,
        },
      },
    });
    this.togglePaginationColor();
    mySwiper.on('slideChange', this.togglePaginationColor);
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (window.matchMedia(viewLarge).matches && this.currentView !== viewLarge) {
        this.currentView = viewLarge;
      } else if (window.matchMedia(viewSmall).matches && this.currentView !== viewSmall) {
        this.currentView = viewSmall;
      } else if (window.matchMedia(viewMobile).matches && this.currentView !== viewMobile) {
        this.currentView = viewMobile;
      }
    },
    async togglePaginationColor(list) {
      const elements = [...document.getElementsByClassName('swiper-pagination-bullet')];
      elements.forEach((el, index) => {
        if (el.classList.contains('swiper-pagination-bullet-active')) {
          elements[index].style.backgroundColor = defaultTheme.primaryColor;
        } else {
          elements[index].style.backgroundColor = 'transparent';
        }
        elements[index].style.borderColor = defaultTheme.primaryColor;
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    [...document.getElementsByClassName('swiper-pagination-bullet')].forEach(element => element.removeEventListener('click', this.togglePaginationColor));
  },
  computed: {},
};
</script>
