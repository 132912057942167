import _ from 'lodash';
import Device from '../Device';
import { hwh } from './Common';

const questions = hwh.concat([{
  model: 'ge',
  type: 'oauth',
  name: 'ge',
  label: 'step3OauthDeviceGE',
  role: 'status',
  showNext: false,
  required: true,
  advanceOnEnter: true,
  advanceOnValue: true,
  update: values => ({
    additionalFields: {
      type: 'ge',
      mixingValve: _.get(values, 'mixingValve'),
      tags: JSON.stringify(_.get(values, 'tags') || {}),
    },
  }),
}]);

const addDevice = async (values, context, signup) => {
  const promise = new Promise((resolve, reject) => {
    signup.$root.$on('oauth.redirect', () => {
      resolve();
    });
  });
  signup.$root.$emit('oauth.submit', values, context);
  return promise;
};

const GE = new Device('ge', { mfg: 'ge', questions, addDevice });

export default GE;
