<template>
  <div class="address-details__zipcode">
    <label for="postalCode" v-html="localized.placeholder" />
    <input
      id="postalCode"
      class="form-control"
      type="text"
      :style="{ color: theme.primaryColor }"
      :name="name"
      :readonly="readonly"
      :value="displayValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup="handleKeyup"
      @input="handleInput"
      :maxlength="localized.postalLength"
      @keydown="handleKeydown"
      ref="input"
      v-validate="validation"
      :aria-label="localized.placeholder"
      :data-vv-name="name"
      :placeholder="localized.placeholder"
    />
    <small v-if="fieldError" class="form-text text-danger--custom">{{fieldError}}</small>
  </div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-address-zipcode',
  props: {
    active: Boolean,
    fieldError: String,
    name: String,
    readonly: Boolean,
    region: Object,
    value: String,
    validation: String,
  },
  data() {
    return {
      zipcode: '',
      cursorPosition: null,
      modifierKeyDetected: {
        alt: false,
        meta: false,
      },
    };
  },
  inject: {
    theme: { default: defaultTheme },
  },
  mounted() {
    if (this.active) {
      this.focus();
    }

    this.$root.$on('address.zipcode', (value) => {
      this.$emit('input', value);
    });

    this.$root.$on('address.show', (show) => {
      this.show = show;
    });
  },
  watch: {
    /**
     * If this question becomes active, focus the input
     */
    active(newActive, oldActive) {
      if (newActive && !oldActive) this.focus();
    },
    cursorPosition(newPosition) {
      if (this.localized.specialChar) {
        setTimeout(() => this.$refs.input.setSelectionRange(newPosition, newPosition));
      }
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleFocus(...args) {
      this.$emit('focus', ...args);
    },
    handleKeyup(...args) {
      const { key } = args[0];
      if (key === 'Enter') {
        this.$emit('keyPressEnter', ...args);
      }
      if (key === 'Meta') {
        this.modifierKeyDetected.meta = false;
      }
      if (key === 'Alt') {
        this.modifierKeyDetected.alt = false;
      }
    },
    handleKeydown(e) {
      const startPosition = this.$refs.input.selectionStart;
      if ((e.key === 'ArrowLeft') && startPosition > 0) {
        if (e.key === 'ArrowLeft' && (this.modifierKeyDetected.alt || this.modifierKeyDetected.meta)) {
          return;
        }
        this.cursorPosition = startPosition - 1;
      }
      if ((e.key === 'ArrowRight') && startPosition < this.displayValue.length) {
        if (e.key === 'ArrowRight' && (this.modifierKeyDetected.alt || this.modifierKeyDetected.meta)) {
          return;
        }
        this.cursorPosition = startPosition + 1;
      }
      if (e.key === 'Alt') {
        this.modifierKeyDetected.alt = true;
      }
      if (e.key === 'Meta') {
        this.modifierKeyDetected.meta = true;
      }
    },
    capitalizeString(string) {
      return (/[a-z]/.test(string)) ? string.toUpperCase() : string;
    },
    insertSpecialChar({ specialChar, specialCharIndex, lastChar }) {
      return (string) => string.length > specialCharIndex || (lastChar === specialChar && string.length === specialCharIndex) ? `${string.slice(0, specialCharIndex)}${specialChar}${string.slice(specialCharIndex)}` : string;
    },
    stripSpecialChar(specialChar) {
      return (string) => {
        const regex = new RegExp(specialChar, 'g');
        return string.replace(regex, '');
      };
    },
    zipcodeFormatterFactory({
      forceUpperCase = false,
      useSpecialChar = false,
    } = {}) {
      return ({
        specialChar = null,
        specialCharIndex = null,
        lastChar = '',
      } = {}) => {
        const pipe = (...fns) => (value) => fns.reduce((v, fn) => fn(v), value);
        const selectedOptions = [];
        if (forceUpperCase) {
          selectedOptions.push(this.capitalizeString);
        }
        if (useSpecialChar && Number.isFinite(specialCharIndex) && specialChar) {
          selectedOptions.push(this.stripSpecialChar(specialChar));
          selectedOptions.push(this.insertSpecialChar({ specialChar, specialCharIndex, lastChar }));
        }
        if (!selectedOptions.length) {
          selectedOptions.push((n) => n);
        }
        return (zipcode) => {
          return pipe(...selectedOptions)(zipcode);
        };
      };
    },
    handleInput(evt) {
      if (this.region.localization === 'CA') {
        const formatCanadianZipcode = this.zipcodeFormatterFactory({
          forceUpperCase: true,
          useSpecialChar: true,
        })({
          specialCharIndex: this.localized.specialChar.index,
          specialChar: this.localized.specialChar.char,
          lastChar: evt.target.value.slice(-1),
        });
        const formattedZipcode = formatCanadianZipcode(evt.target.value);
        this.detectCursorPosition({ specialCharIndex: this.localized.specialChar.index, inputType: evt.inputType });
        this.$emit('input', formattedZipcode);
      } else {
        this.$emit('input', evt.target.value);
      }
    },
    detectCursorPosition({ specialCharIndex, inputType }) {
      const inputField = this.$refs.input;
      const startPosition = inputField.selectionStart;
      if (this.cursorPosition === startPosition) {
        // if cursor position is unchanged, setSelectionRange on input so it's ready for next input, since equal values won't trigger the this.cursorPosition watcher
        setTimeout(() => this.$refs.input.setSelectionRange(startPosition, startPosition));
      } else if (inputType === 'deleteContentBackward' || inputType === 'deleteContentForward') {
        this.cursorPosition = startPosition;
      } else if (startPosition <= specialCharIndex || this.cursorPosition !== specialCharIndex) {
        this.cursorPosition = startPosition;
      } else {
        this.cursorPosition = startPosition + 1;
      }
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
  computed: {
    displayValue() {
      return this.value;
    },
    localized() {
      if (this.region.localization === 'AU') return { placeholder: 'Postcode', postalLength: 4 };
      if (this.region.localization === 'CA') return { placeholder: 'Postal code', postalLength: 7, specialChar: { index: 3, char: ' ' } };
      if (this.$i18n.locale === 'es') return { placeholder: 'Código postal', postalLength: 5 };
      return { placeholder: 'ZIP Code', postalLength: 5 };
    },
  },
};
</script>

<style scoped>
.address-details__zipcode {
  width: 35%;
  float: right;
}

label {
 color:#4F4F4F;
 font-size:16px !important;
 margin-bottom:-10px !important;
 margin-left:10px;
 user-select: none;
}
</style>
