import Device from '../Device';
import vp from '../../../services/api/vp';

const questions = [
  {
    type: 'text',
    label: 'step3FloSerialNumber',
    advanceOnEnter: true,
    advanceOnValue: false,
    name: 'serialNumber',
    model: 'serialNumber',
    validation: 'required',
  },
];

const addDevice = async (values, context) => {
  const { account } = context;

  const deviceData = {
    ...values,
    mfg: 'flo',
    deviceType: 'evse',
    ...Device.commonAddDeviceParameters(context),
  };

  return vp.addDevice(deviceData);
};

const Flo = new Device('flo', { mfg: 'flo', questions, addDevice });

export default Flo;
