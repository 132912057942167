<template>
    <div
    :name="name"
    >Click the button below to onboard your VP Sim device.</div>
</template>

<script>
import defaultTheme from '../../styles/defaultTheme';

export default {
  name: 'question-sim',
  props: {
    name: String,
  },
  inject: {
    theme: { default: defaultTheme },
  },
};
</script>
